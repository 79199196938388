import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { DashBoardCountViewModel, DashBoardGroupCountViewModel, DashBoardAnnouncementViewModel, DashBoardNoticationViewModel } from '../models/DashBoardViewModels';

@Injectable({
  providedIn: 'root'
})
export class DashBoardService {

  constructor(private api: ApiService) { }

  public GetCounterList(): Promise<DashBoardCountViewModel[]> {
    return this.api.callService("Dashboard/GetCounterList", {}).then(o => {
      return o as DashBoardCountViewModel[];
    });
  }

  public GetGroupCounterList(): Promise<DashBoardGroupCountViewModel[]> {
    return this.api.callService("Dashboard/GetGroupCounterList", {}).then(o => {
      return o as DashBoardGroupCountViewModel[];
    });
  }

  public GetAnnouncementList(): Promise<DashBoardAnnouncementViewModel> {
    return this.api.callService("Dashboard/GetAnnouncementList", {}).then(o => {
      return o as DashBoardAnnouncementViewModel;
    });
  }

  public GetNotificationList(): Promise<DashBoardNoticationViewModel> {
    return this.api.callService("Dashboard/GetNOtificationList", {}).then(o => {
      return o as DashBoardNoticationViewModel;
    });
  }
}
