import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transfer-sevk',
  templateUrl: './transfer-sevk.component.html',
  styleUrls: ['./transfer-sevk.component.css']
})
export class TransferSevkComponent implements OnInit {

  public filterData;
  constructor() { }

  ngOnInit() {
  }
  filter(value){
    this.filterData = value;
  }
}
