import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { error } from 'util';

@Component({
  selector: 'app-investigations',
  templateUrl: './investigations.component.html',
  styleUrls: ['./investigations.component.css']
})
export class InvestigationsComponent implements OnInit {
  public getTekikListUrl: string = "PersonelTetkik/GetTetkikList";
  public saveTekikUrl: string = "PersonelTetkik/SaveTetkik";
  public saveAllTekikUrl: string = "PersonelTetkik/SaveTetkik";

  public TetkikAdi: string;
  public personelIDList: any;
  public firmaID: any;

  public model: any = {
    Filter: {
      TetkikAdi: "",
      personelID: undefined
    }
  };

  public savingModel: any = {
    paketteYapildi: false,
    yapildi: true,
    medlife: true,
    tetkikTuru: "",
  };

  public showAlert: boolean = false;

  public selectedItems: any[] = [];

  public tekikList: any[];

  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService, private modalService: BsModalService) { }

  ngOnInit() {
    this.model.Filter.TetkikAdi = this.TetkikAdi;

    this.apiservice.callPostService(this.getTekikListUrl, this.model).subscribe(
      (resp: any) => {
        if (resp !== null && 'Data' in resp) {
          this.tekikList = resp.Data.List;
        }
      }, err => {
        console.log(err);
      }
    );
  }

  public onSelectItem(item: any) {
    if (this.selectedItems.includes(item.Id)) {
      let index = this.selectedItems.indexOf(item.Id);
      this.selectedItems.splice(index, 1);
    } else {
      this.selectedItems.push(item.Id);
    }
  }



  public getRowClass(item) {
    return this.selectedItems.includes(item.Id) ? 'table-info' : '';
  }
  error = [];
  public onSaveTetkik(st) {
    this.error.length = 0;
    let err = this.error;
    if (!this.savingModel.tetkikTuru)
      err.push("Tetkik Turu");
    if (this.selectedItems.length == 0)
      err.push("Tetkik Seçiniz");
    if (err.length > 0) {
      return err;
    }

    let model = Object.assign({}, this.savingModel);
    if (st == 'all') {
      model['firmaID'] = this.firmaID;
    }
    else {
      model['personelIDList'] = this.personelIDList;
    }
    
    model['tetkikIdList'] = this.selectedItems;
   
    // console.log(this.savingModel);
    this.apiservice.callPostService(this.saveTekikUrl, model).subscribe(
      () => {
        this.modalService.setDismissReason('ack-save');
        this.bsModalRef.hide();
      },
      err => {
        console.log(err);
      }
    );

  }

}
