import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-rapor-result-itemlist-modal',
  templateUrl: './rapor-result-itemlist-modal.component.html',
  styleUrls: ['./rapor-result-itemlist-modal.component.css']
})
export class RaporResultItemlistModalComponent implements OnInit {
personel:any;
itemList:any[]=[];
public onClose: Subject<any>;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
  }
  onChange(val,event) {
    if (event.target.checked) {
      this.itemList.push(val);
    }
    else {
      const index: number = this.itemList.indexOf(val);
      if (index !== -1) {
        this.itemList.splice(index, 1);
      }
    }
    console.log(this.itemList);
  }
  onSave(){
    if(this.itemList.includes(4)==false){
     return alert("Tetkik Grubunu Seçniniz")
    }
    if(this.itemList.length<2 || this.itemList.length>3)
    return alert("En az 2 en fazla 3 alan seçebilirsiniz")
    this.onClose.next(this.itemList);
    debugger;
    this.bsModalRef.hide();
  }
  onCancel() {

    this.bsModalRef.hide();
  }
}
