import { Component, OnInit, TemplateRef, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { BsModalService, defineLocale, trLocale, BsLocaleService } from 'ngx-bootstrap';
import { DataManagerService } from 'src/app/Services/dataManager/data-manager.service';
import { PersonelTetkikServices } from '../../../services/PersonelTetkikService';
import { ModalManagerService } from 'src/app/Services/modal-manager.service';
import { PermissionViewModel } from '../../../../../../shared/models/PermissionViewModel';
import { authService } from 'src/app/Services/authService';

import { PersonelExaminationsComponent } from '../personel-examinations/personel-examinations.component';
import { ConfirmationDialogComponent } from '../../../../../../shared/modals/confirmation-dialog/confirmation-dialog.component';
import { PersoneltetkikComponent } from '../../../modals/personeltetkik/personeltetkik.component';
import { DeleteMultiplepersonelComponent } from '../../../modals/delete-multiplepersonel/delete-multiplepersonel.component';
import { ExaminationsComponent } from '../../../modals/examinations/examinations.component';
import { NewpersonelComponent } from '../../../modals/newpersonel/newpersonel.component';
import { InvestigationsComponent } from '../../../modals/investigations/investigations.component';
import { SavePlByexcelComponent } from '../../../modals/save-pl-byexcel/save-pl-byexcel.component';
import { SavePlBypdfComponent } from '../../../modals/save-pl-bypdf/save-pl-bypdf.component';
import { SaveAudioComponent } from '../../../modals/save-audio/save-audio.component';
import { PacketsComponent } from '../../../modals/packets/packets.component';
import { DeleteByPersonelListModel } from '../../../models/DeleteByTetkikListModel';
import { DeleteByFirmaIDModel } from '../../../models/DeleteByFirmaIDModel';
import { PersonelSearchComponent, PersonelSearchDialogResult } from '../../../modals/personel-search/personel-search.component';
import { fromEvent } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Grid } from 'src/app/shared/models/UIControls/grid-control';
import { PersonelServices } from '../../../services/PersonelService';
import { PersonelTetkikViewModel } from '../../../models/PersonelTetkikViewModel';
import { PersonelViewFilter } from '../../../models/PersonelViewFilter';
import { IslemListInput } from '../../../models/IslemListInput';
import { PersoneltetkikmodalComponent } from '../../../modals/personeltetkikmodal/personeltetkikmodal.component';
import { PersonelTetkikModalModel } from '../../../models/persenolTetkikModalModel';
import { PersonelIDListModel } from '../../../models/PersonelIDListModel';
import { DepartmentListViewModel } from '../../../models/DepartmentListViewModel';
import { NameViewModel } from '../../../models/NameViewModel';
import { OsgbFirmaServices } from '../../../services/OsgbFirmaService';
defineLocale('tr', trLocale);

@Component({
  selector: 'app-personel-grid',
  templateUrl: './personel.component.html',
  styleUrls: ['./personel.component.css']
})
export class PersonelGridComponent implements OnInit {
  @ViewChild('filterSection', {static: false}) input: ElementRef;
  public getPersonelListUrl: string = "Personel/GetPersonelList";
  public exportpersonelUrl: string = "FileOperation/ExcelExport";
  public direction: number = 1;
  public personelKimlik:any;
  public firmaID;
  public barkodNo;
  @Input() firmaevents: EventEmitter<any>;
  islemListInput:IslemListInput=new IslemListInput();
  public personnelGrid: any = {

    selectAll: false,
    selectedItem: undefined,
    selectedItems: [],
    totalCount: 20,
    data: [],
    model: {
      Filter: {},
      State: { isUpdated: 0 },
      Paging: {
        PageNumber: 1,
        Count: 10
      },
      Sorting: {},
      Pager: [] = [10, 25, 50,500]
    },
    getRowClass: (item: any) => {
      if (this.personnelGrid.isSelected(item)) {
        return 'table-success';
      } else {
        if (this.personnelGrid.selectedItem == item)
          return 'table-info';
        return ''
      }
    },
    isSelected: (item: any) => {
      return this.personnelGrid.selectedItems.some(o => o == item);
    },
    hasSelectedItem: () => {
      return this.personnelGrid.selectedItems.length > 0;
    },
    onClearFilter: () => {
      this.personnelGrid.model.Filter = {};
      this.personnelGrid.onRefresh();
    },
    onSorting: (colName) => {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.personnelGrid.model.Sorting = { colName: colName, direction: this.direction };
      this.getpersonelList();
    },
    onDelete: () => {

      var personelIDList = this.personnelGrid.selectedItems.map(o => {
        return o.ID;
      });
      const initialState = {
        message: "Seçili personelleri silmek istediğinizden emin misiniz?"
      };
      let instance = this.modalService.show(ConfirmationDialogComponent, { initialState,ignoreBackdropClick:true });
      instance.content.onClose.subscribe(
        response => {
          if (response != "confirm")
            return;
          this.data.deletePersonelByIDList(personelIDList).subscribe(o => {
            this.personnelGrid.selectedItems.length = 0
            this.personnelGrid.onRefresh()
          })

        }
      );
    },
    onSelectAll: (event: any) => {
      if (!event.srcElement.checked) {
        this.personnelGrid.selectedItems.length = 0;
        return;
      }
      this.personnelGrid.selectedItems.length = 0;
      this.personnelGrid.data.forEach(o => {
        this.personnelGrid.onSelectItem(o);
      });
    },
    onClickItem: (item: any) => {
      this.personnelGrid.selectedItem = item;
      this.datamanager.setPersonelID(item.ID);
      this.publishPersonelChanged();
    },
    onSelectItem: (item: any) => {
      console.log(item);
      if (this.personnelGrid.isSelected(item)) {
        var ar = this.personnelGrid.selectedItems;
        var index = ar.indexOf(item);
        if (index > -1) {
          ar.splice(index, 1);
        }
        return;
      }
      this.personnelGrid.selectedItems.push(item);
      this.datamanager.setPersonelIDList(this.personnelGrid.selectedItems);
    },
    onRefresh: () => {
      this.personnelGrid.selectedItems.length=0;
      this.getpersonelList();
     
    },
    getFilter: () => {
      var filter = this.personnelGrid.model.Filter;
      if (filter.KayitTarihiRange) {
        
        filter.BasTarih = filter.KayitTarihiRange[0];
        filter.BitTarih = filter.KayitTarihiRange[1];
      
      }else{
        filter.BasTarih = null;
        filter.BitTarih = null;
      }
      console.log(this.datamanager.setBasTarih(filter.BasTarih));
      console.log(this.datamanager.setBitTarih(filter.BitTarih));
      
      filter.FirmaID = this.datamanager.FirmaId;
      for (var k in this.personnelGrid.model.State) {
        filter[k] = this.personnelGrid.model.State[k];
      }

      return {
        Filter: filter,
        Paging: this.personnelGrid.model.Paging,
        Sorting: this.personnelGrid.model.Sorting
      };
    },
    onExportExcel: () => {
      // let filter = this.personnelGrid.getFilter();
      var filter = this.islemListInput
      var f = this.personnelGrid.model.Filter;
      if (f.KayitTarihiRange) {
        filter.baslangicTarihi = f.KayitTarihiRange[0];
        filter.bitisTarihi = f.KayitTarihiRange[1];
      }
      else{
        filter.baslangicTarihi=null;
        filter.bitisTarihi=null;
      }
      this.islemListInput.osgbid=this.datamanager.getOsgbID();
      this.islemListInput.firmaid=this.datamanager.getFirmaID();
      this.personelService.ExportExcelPersonelList(this.islemListInput);
      // this.data.exportExcel(filter).subscribe(o => {
      //   if (!o)
      //     return;
      //   let fileID = o.Data;
      //   this.apiservice.fileOperation.download(fileID);
      // }, o => {
      //   console.log(o);
      // })
    },

    onOpenExaminations: () => {

      let modal = this.modalService.show(ExaminationsComponent, { class: 'modal-lg',ignoreBackdropClick:true });

      let personelIDList = this.personnelGrid.selectedItems.map(o => o.ID);
      let firmaID = this.datamanager.getFirmaID()
      var filter = this.personnelGrid.model.Filter;
      if (filter.KayitTarihiRange) {
        filter.BasTarih = filter.KayitTarihiRange[0];
        filter.BitTarih = filter.KayitTarihiRange[1];
      }
      else{
        filter.BasTarih=null;
        filter.BitTarih=null;
      }
    
      modal.content.state.init(personelIDList, firmaID,filter.BasTarih,filter.BitTarih)
      modal.content.onClose.subscribe(
        response => {
          if (response !== null && response == "save-examination") {

            //  this.personnelTekikGrid.selectedItem = undefined;
            //  this.getPersonelTekikList();
            this.firmaevents.emit('refresh-Personel-tekik');
          } // this.getpersonelList();
        }, err => {
          console.log(err);
        }
      );
    },
    onPersenolSearchModal: () => {

      let modal = this.modalService.show(PersonelSearchComponent, { class: 'modal-lg',ignoreBackdropClick:true })
      modal.content.eventClose.subscribe(o => {
        if (!o) {
          return;
        }
        let model = o as PersonelSearchDialogResult;
        console.warn("DIALOG Result Personel:", model);
      })

    },
    //mert
    openExaminationModal: () => {

      var res = new DeleteByPersonelListModel();
      let personelIDList = this.personnelGrid.selectedItems.map(o => o.ID);
      res.personelIDList = personelIDList;
this.personnelGrid
      this.tetkikService.GetTetkikGruplariList(res).then(o => {
        let data = o;
        console.log(data);
        const initialState = {
          tetkikList: data,
          personelIDList: personelIDList,
          selectedItem: this.personnelGrid.selectedItems,

        };
        
        let modal = this.modalService.show(PersoneltetkikComponent, { initialState,ignoreBackdropClick:true });
        modal.content.onClose.subscribe(
          response => {
            
            if(response.length != 0){
             
              
              // var filter=this.personnelGrid.model.Filter;
              var model=this.personnelGrid.model;
              model.Filter.PersonelIDList= this.personnelGrid.selectedItems.map(o => o.ID);
              model.Filter.TetkikGrubuList= response;
              // var model = new Grid.GridInputModel(filter);
              this.personelService.GetBarkodByPersonelID(model).then(o => {
                this.apiservice.fileOperation.download(o);
               
            });
          }
            if (response !== null && response == "close") {

              this.personnelGrid.onClickItem(this.personnelGrid.selectedItem)
            }
          }, err => {
            console.log(err);
          }
        );
      });
    },
    onBarkodTetkik: (par) => {
   
      let gd = this.personnelGrid;
      if (!gd.hasSelectedItem())
        return;
      let idList = gd.selectedItems.map(o => o.ID);
      this.data.barkodTetkik(idList, par == "Lab" ? true : false).subscribe(o => {
        if (!o || !o.Data)
          return;
        this.apiservice.fileOperation.download(o.Data);
        this.publishPersonelChanged();
      });
    },
    onDeleteAllTetkikAll: () => {
      let gd = this.personnelGrid;
      var firmaID = gd.model.Filter['FirmaId'];
      if (!firmaID)
        return;
      this.modalNotService.openConfirmation("Tüm Tetkikler Tüm personellerden silinecek? FirmaID:" + firmaID).subscribe(o => {
        if (o != "confirm") {
          return;
        }
        var m = new DeleteByFirmaIDModel();
        var filter = this.personnelGrid.model.Filter;
        if (filter.KayitTarihiRange) {
          m.BasTar = filter.KayitTarihiRange[0];
          m.BitTar = filter.KayitTarihiRange[1];
        }
        else{
          m.BasTar=null;
          m.BitTar =null;
        }
        m.firmaID = firmaID;
        this.tetkikService.DeleteByFirmaID(m).then(o => {
          gd.onRefresh();
        });
      })
    }, 
    onDeleteTetkikPersonel: () => {
      var gd = this.personnelGrid;
      if (!gd.selectedItem)
        return;
      var personelID = gd.selectedItem.ID;
      if (!personelID) return;
      this.modalNotService.openConfirmation("Tüm Tetkikler  personelden silinecek? PersonelID:" + personelID).subscribe(o => {
        if (o != "confirm") {
          return;
        }
        var m = new DeleteByPersonelListModel();
        m.personelIDList = [personelID];
        this.tetkikService.DeleteByPersonelList(m).then(o => {
          gd.onRefresh();
        });
      })
    },
  };

  data: any = {
    deletePersonelByIDList: (personelIDList: []) => {
      return this.apiservice.callPostService("Personel/DeleteSeciliPersoneller", { personelIDList: personelIDList });
    },
    exportExcel: (filter: any) => {
      return this.apiservice.callPostService("Personel/ExportExcelPersonelList", filter);
    },
    barkodTetkik: (idList: [], isLabTetkik: boolean) => {
      return this.apiservice.callPostService("PersonelTetkik/Barkod", {
        PersonelIDList: idList,
        LabTetkikiMi: isLabTetkik
      });
    },

  };
  publishPersonelChanged() {
    this.firmaevents.emit('personel-item-selected');
    this.firmaDepartmentList();
  }
  
  departmentListViewModel:DepartmentListViewModel= new DepartmentListViewModel();
  public nameViewModel: Array<NameViewModel>;
  public departmentVisible:boolean=true;
  permission: PermissionViewModel = new PermissionViewModel();
  public perTetModalModel: PersonelTetkikModalModel = new PersonelTetkikModalModel();
  public perIdListModel: PersonelIDListModel = new PersonelIDListModel();
  constructor(
    private apiservice: ApiService, 
    private modalService: BsModalService, 
    private datamanager: DataManagerService, 
    private tetkikService: PersonelTetkikServices, 
    private modalNotService: ModalManagerService, 
    private authService: authService,
    private localeService: BsLocaleService,
    public personelService:PersonelServices,
    private osgbFirmaServices:OsgbFirmaServices
    ) {
    this.authService.Ready.then(o => {
      this.permission = o;
    });
  }
  TEST() {
    //TEST
    this.openSavePersonelModal();
  }
  ngOnInit() {
    this.localeService.use('tr');
    //this.TEST();
    this.personnelGrid.model.Filter['Aktif'] = false;
  
    this.firmaevents.subscribe(
      (resp: any) => {
        if (resp == 'firma-item-selected') {
          this.personnelGrid.model.Filter['FirmaId'] = this.datamanager.getFirmaID();
          this.personnelGrid.model.Filter.DepartmentID=undefined;
          this.personnelGrid.selectedItem = undefined;
          this.personnelGrid.selectedItems = [];
          this.getpersonelList();
          this.firmaDepartmentList();
        }
        else if (resp == "osgb-item-selected") {
          console.warn("SEARCH_BUTTON_TEST:", "osgb-item-selected event triggered");
          this.personnelGrid.model.Filter['Id'] = this.datamanager.getPersonelID();
        }
      }
    );

  }
  ngAfterViewInit() {
    
    let tableDom = this.input.nativeElement;
    let items = tableDom.querySelectorAll('.filterInput');
  
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      fromEvent(item, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(2000),
          distinctUntilChanged(),
          tap((text) => {
            if(item.value.length>2)
            this.personnelGrid.onRefresh();
            else if(item.value.length==0)
            this.personnelGrid.onRefresh();
          })
        )
        .subscribe();
    }
    
  }
  onPersonelIdKart() {
    this.perIdListModel.personelIDList = [];
    this.personnelGrid.selectedItems.forEach(item => {
      this.perIdListModel.personelIDList.push(item.ID);
    })
    console.log(this.perIdListModel.personelIDList);
    this.personelService.GetPersonelBarkodBas(this.perIdListModel).then(resp => {
      this.apiservice.fileOperation.download(resp);
    })
  }
  onPersonelReport() {
    this.perIdListModel.personelIDList = [];
    this.personnelGrid.selectedItems.forEach(item => {
      this.perIdListModel.personelIDList.push(item.ID);
    })
    console.log(this.perIdListModel.personelIDList);
    this.personelService.GetUserResult(this.perIdListModel).then(resp => {
      this.apiservice.fileOperation.download(resp);
    })
  }
  public firmaDepartmentList(){
    this.departmentListViewModel.FirmaID=this.firmaID;
    this.osgbFirmaServices.GetDepartmentByFirmaID(this.departmentListViewModel).then(o=>{
     this.nameViewModel=o;
     console.log(this.nameViewModel)
     if(this.nameViewModel.length>0){
       this.departmentVisible=false
     }
     else{
      this.departmentVisible=true
     }
    })
 }
  openPersonelTetkikModal() {
    this.perTetModalModel.firmaID = null;
    let selectedIdList = [];
    this.personnelGrid.selectedItems.forEach(item => {
      selectedIdList.push(item.ID);
    });
    this.perTetModalModel.personelIDList = selectedIdList;
    const initialState = {
      perTetModalModel: this.perTetModalModel
    };

    let modal = this.modalService.show(PersoneltetkikmodalComponent, { initialState, ignoreBackdropClick: true, class: 'modal-lg' });
    modal.content.onClose.subscribe(
      response => {
        if (response.length != 0) {
        }
        if (response !== null && response == "close") {
        }
      }, err => {
        console.log(err);
      }
    );
  }
  // Personnel Grid 
  public getpersonelList() {
    var filter = this.personnelGrid.getFilter();
    this.apiservice.callPostService(this.getPersonelListUrl, filter).subscribe((resp: any) => {
      // console.log(resp); 
      if (resp !== undefined && 'Data' in resp) {
        this.personnelGrid.data = resp.Data.List;
        this.personnelGrid.totalCount = resp.Data.TotalCount;
        this.personnelGrid.onClickItem(this.personnelGrid.data[0])
      }
    }, err => {
      console.log(err);
    });
    this.firmaID = this.datamanager.getFirmaID();
  }
  onBarkodSearch(){
    var filter = this.personnelGrid.getFilter();
    // var filter = this.personnelGrid.model.Filter;
    filter.Filter.BarkodNo =this.barkodNo;
  
    this.apiservice.callPostService(this.getPersonelListUrl, filter).subscribe((resp: any) => {
      // console.log(resp); 
      if (resp !== undefined && 'Data' in resp) {
        this.personnelGrid.data = resp.Data.List;
        this.personnelGrid.totalCount = resp.Data.TotalCount;
        this.personnelGrid.onClickItem(this.personnelGrid.data[0])
    
      }
    }, err => {
      console.log(err);
    });
    this.barkodNo="";
  }
  onFilterInputChange(event) {

    //if (event.key == "Enter") {
    this.getpersonelList();
    // }

  }

  public onPersonelGridPageChanged(event) {
    this.personnelGrid.model.Paging.PageNumber = event.page;
    this.getpersonelList();
    this.personnelGrid.selectedItems.length = 0;
    // console.log(this.personnelGrid.model.Paging.PageNumber)
  }
  // modals

  public openSavePersonelModal(id?: any, updateControl?: any) {
    let osgbid = this.datamanager.getOsgbID();
    let firmaID = this.datamanager.getFirmaID();
    this.personelKimlik=this.personnelGrid.selectedItems.map(o => o.PersonelKimlikNo)

    let personelKimlikNo=this.personelKimlik[0];
    console.log(personelKimlikNo)
    // if(updateControl==1){
    //   personelKimlikNo=id
    // }
  
   
    let initialState = {
      
      OsgbId: (osgbid == undefined) ? undefined : osgbid,
      FirmaId: (firmaID == undefined) ? undefined : firmaID,
      updateControl: updateControl,
      PersonelKimlikNo: id

    };
    
    if (id !== undefined) {
      initialState['personelID'] = id;
    }
    console.log("initialstate: " + initialState);
    let item = this.modalService.show(NewpersonelComponent, { initialState,ignoreBackdropClick:true });

    item.content.onClose.subscribe(o => {
      if (o == "save-personel") {
        this.personnelGrid.onRefresh();
      }
      else if (o && o.alreadyExist) {
        console.log("UpdateControl : " + o.updateControl);
        
        this.openSavePersonelModal(o.alreadyExist, o.updateControl);
      }
      else if (o == "seri") {
        this.personnelGrid.onRefresh();
        this.openSavePersonelModal();
      }
    });
  }


  public openDeleteMultiplePersonelModal() {
    const initialState = {
      FirmaID: this.datamanager.getFirmaID(),
    };
    this.modalService.show(DeleteMultiplepersonelComponent, { initialState,ignoreBackdropClick:true });
    // console.log(this.modalRef);
    this.modalService.onHide.subscribe(
      response => {
        if (response !== null && response == "delete-multiple-personel") {
          this.personnelGrid.selectedItems = [];
          this.personnelGrid.selectedItem = undefined;
          this.getpersonelList();
        }
      }
    );
  }

  public onOpenAckModal(modalType: string) {
    const initialState = {
      TetkikAdi: modalType,
      personelIDList: this.personnelGrid.selectedItems.map(o => o.ID),
      firmaID: this.datamanager.getFirmaID()
    };
    this.modalService.show(InvestigationsComponent, { initialState,ignoreBackdropClick:true });
    this.modalService.onHide.subscribe(
      response => {
        if (response !== null && response == "ack-save") {

          //  this.personnelTekikGrid.selectedItem = undefined;
          //  this.getPersonelTekikList();

          this.firmaevents.emit('refresh-Personel-tekik');
          this.getpersonelList();
        }
      }
    );
  }

  public onOpensavePnlByExcel() {
    this.modalService.show(SavePlByexcelComponent, {ignoreBackdropClick:true});
    this.modalService.onHide.subscribe(
      response => {
        if (response !== null && response == "save-personel") {
          this.personnelGrid.selectedItem = undefined;
          this.personnelGrid.selectedItems = [];
          this.getpersonelList();
        }
      }
    );
  }

  public onOpensavePnlByPdf() {
    this.modalService.show(SavePlBypdfComponent, {ignoreBackdropClick:true});
    this.modalService.onHide.subscribe(
      response => {
        if (response !== null && response == "save-personel") {
          this.personnelGrid.selectedItem = undefined;
          this.personnelGrid.selectedItems = [];
          this.getpersonelList();
        }
      }
    );
  }

  public openSaveAudioExcel() {
    this.modalService.show(SaveAudioComponent, {ignoreBackdropClick:true});
    this.modalService.onHide.subscribe(
      response => {
        if (response !== null && response == "save-audio") {
          this.personnelGrid.selectedItem = undefined;
          this.personnelGrid.selectedItems = [];
          this.getpersonelList();
        }
      }
    );
  }


  public onOpenkPackets() {
    var filter = this.personnelGrid.model.Filter;
    if (filter.KayitTarihiRange) {
      filter.BasTarih = filter.KayitTarihiRange[0];
      filter.BitTarih = filter.KayitTarihiRange[1];
    }
    else{
      filter.BasTarih=null;
      filter.BitTarih=null;
    }
    let initialState = {
     
       personelIDList: this.personnelGrid.selectedItems.map(o => o.ID),
      // personelID: this.personnelGrid.selectedItem.ID,
      firmaID: this.datamanager.getFirmaID(),
      osgbID: this.datamanager.getOsgbID(),
      BasTarih:filter.BasTarih,
      BitTarih:filter.BitTarih
    };
    this.modalService.show(PacketsComponent, { class: 'modal-lg', initialState,ignoreBackdropClick:true });
    this.modalService.onHide.subscribe(
      response => {
        if (response !== null && response == "save-packet") {
          //  this.personnelTekikGrid.selectedItem = undefined;
          //  this.getPersonelTekikList();
          this.firmaevents.emit('refresh-Personel-tekik');
          this.getpersonelList();
          this.personnelGrid.selectedItems.length=0;
        }
      }, err => {
        console.log(err);
      }
    );
  }

  public disableAddPersonel() {
    let firmaID = this.datamanager.getFirmaID();

    if (firmaID == null) {
      return true;
    }
    else
      return false;
  }

}
