import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { authService } from 'src/app/Services/authService';
import * as moment from 'moment';
import { FormGroup, FormBuilder, NgModel } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { PersonelTetkikIslemServices } from '../../services/PersonelTetkikIslemService';
import { ExamResultEyeDialogComponent } from '../../modals/examResult/exam-result-eye-dialog/exam-result-eye-dialog.component';
import { ExamResultOdioDialogComponent } from '../../modals/examResult/exam-result-odio-dialog/exam-result-odio-dialog.component';
import { ExamResultLungsDialogComponent } from '../../modals/examResult/exam-result-lungs-dialog/exam-result-lungs-dialog.component';
import { ExamResultBloodDialogComponent } from '../../modals/examResult/exam-result-blood-dialog/exam-result-blood-dialog.component';
import { ApiService } from '../../../../../Services/api.service';
import { ConfirmationDialogWithinputComponent } from '../../../../../shared/modals/confirmation-dialog-withinput/confirmation-dialog-withinput.component';
import { ConfirmationDialogComponent } from '../../../../../shared/modals/confirmation-dialog/confirmation-dialog.component';
import { PermissionViewModel } from '../../../../../shared/models/PermissionViewModel';
import { Grid } from '../../../../../shared/models/UIControls/grid-control';
import { PersonelTetkikServices } from '../../services/PersonelTetkikService';
import { TetkikResultModel } from '../../models/TetkikResultModel';
import { ConcatSource } from 'webpack-sources';
import { SaveResultExcelComponent } from './save-result-excel/save-result-excel.component';
import { BarkodModelByTetkikIDList } from '../../models/BarkodModelByTetkikIDList';
import { TetkikResultModelByIslemID } from '../../models/TetkikResultModelByIslemID';
import { ImzalamaModel } from '../../models/ImzalamaModel';
import { SaveTopluAkcigerModel } from '../../models/SaveTopluAkcigerModel';
import { ImzalamaRedModel } from '../../models/ImzalamaRedModel';
import { TetkikResultFilter } from '../../models/TetkikResultFilter';
import { LabOnayModel } from '../../models/LabOnayModel';
import { SaveTopluLaboratuvarSonucModel } from '../../models/SaveTopluLaboratuvarSonucModel';
import { RaporResultItemlistModalComponent } from '../../modals/rapor-result-itemlist-modal/rapor-result-itemlist-modal.component';
import { ExamResultEyerefractiveDialogComponent } from '../../modals/exam-result-eyerefractive-dialog/exam-result-eyerefractive-dialog.component';
import { TetkikGrubuViewModel } from '../../../definitions/models/TetkikGurubuViewModel';
import { ddlSettings } from '../../modals/personeltetkikmodal/ddlSettings';
import { OsgbViewModel } from '../../models/OsgbViewModel';
import { OsgbFirmaServices } from '../../services/OsgbFirmaService';
import { ConfirmationDialogRejectionComponent } from 'src/app/shared/modals/confirmation-dialog-rejection/confirmation-dialog-rejection.component';
import { ExamResultFileDialogComponent } from '../../modals/examResult/exam-result-file-dialog/exam-result-file-dialog.component';
import { NameViewModel } from '../../models/NameViewModel';
import { PersonelTetkikModalModel } from '../../models/persenolTetkikModalModel';
import { PersoneltetkikmodalComponent } from '../../modals/personeltetkikmodal/personeltetkikmodal.component';
import { AutocompleteModel, AutoCompleteSettings } from 'src/app/Shared/component/input-autocomlete/input-autocomlete.component';
import { PersonelServices } from '../../services/PersonelService';
import { PersonelViewFilter } from '../../models/PersonelViewFilter';
moment.locale('tr');
@Component({
  selector: 'app-personel',
  templateUrl: './personel.component.html',
  styleUrls: ['./personel.component.css']
})
export class PersonelComponent implements OnInit {
  dizayn: any;
  tetkikImzalaButtonsVisible: boolean; //reddet, sonuç ekle ve imzala butonlarının görünürlük kontrolü için
  tetkikResult: TetkikResultModel = new TetkikResultModel();
  OsgbViewModel: OsgbViewModel = new OsgbViewModel();
  tetkikResultByIslemID: TetkikResultModelByIslemID = new TetkikResultModelByIslemID();
  tetkikImzalama: ImzalamaModel = new ImzalamaModel();
  tetkikImzalamaRed: ImzalamaRedModel = new ImzalamaRedModel();
  saveTopluAkcigerModel: SaveTopluAkcigerModel = new SaveTopluAkcigerModel();
  tarih: { start: moment.Moment, end: moment.Moment };;
  isImzalama: any;
  isLaboratuvar: any;
  ddlFrxOptions: any[] = [];
  permission: PermissionViewModel = new PermissionViewModel();
    //autocomplete
    selected:any;
  settingsPerson: AutoCompleteSettings;
  @Output() onSelectPersonInfo: EventEmitter<any> = new EventEmitter();
  autoCompleteFilter: PersonelViewFilter = new PersonelViewFilter();
    //autocomplete
  ranges: any = {
    'Bugün': [moment(), moment()],
    'Dün': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Son 7 gün': [moment().subtract(6, 'days'), moment()],
    'Son 30 gün': [moment().subtract(29, 'days'), moment()],
    'Bu Ay': [moment().startOf('month'), moment().endOf('month')],
    'Önceki Ay': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  public getTetkikGrupList: Array<TetkikGrubuViewModel>;
  public ddlSettings: ddlSettings = new ddlSettings();
  public ddlOSGBSettings;
  public ddlOSGBList: Array<OsgbViewModel>;
  public perTetModalModel: PersonelTetkikModalModel = new PersonelTetkikModalModel();
  public ddlOSGBSelectedItems = [];
  dizaynModel:any[]=[]
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    let scrolledDiv = document.getElementById('scrollDiv');
    scrolledDiv.scrollTop = 19 * this.gridPersonelTetkikIslem.dataListIx;
    let ix = this.gridPersonelTetkikIslem.dataListIx
    if (event.keyCode == 40) {
      ix++;
      if (ix == this.gridPersonelTetkikIslem.data.list.length)
        ix = 0
      let item = this.gridPersonelTetkikIslem.data.list[ix];
      console.log(ix)
      this.gridPersonelTetkikIslem.onClickItem(item);
    } else if (event.keyCode == 38) {
      ix--;
      if (ix == -1)
        ix = 0
      let item = this.gridPersonelTetkikIslem.data.list[ix];
      console.log(ix)
      this.gridPersonelTetkikIslem.onClickItem(item);
    }
  }
  constructor(private modalService: BsModalService, private fb: FormBuilder, private dataRapor: PersonelTetkikIslemServices, private authService: authService,
    private apiService: ApiService, private sonucAl: PersonelTetkikServices, private osgbFirmaServices: OsgbFirmaServices,public personelServices:PersonelServices) {
    this.authService.Ready.then(o => {
      this.permission = o;
    });
  }
  ddlYazdirOptions = [{ id: null, name: "Yazdırılma Durumu" }, { id: false, name: "Yazdırılmamış" }, { id: true, name: "Yazdırılmış" }];
  ddlImzalamaOptions = [{ id: 1, name: "Tetkik İmzala" }, { id: 4, name: "Sonuç Al" }, { id: 3, name: "Laboratuvar Onay" }, { id: 2, name: "Laboratuvar Sonuç" }];
  ddlLaboratuvarOptions = [{ id: 0, name: "Laboratuvar" }, { id: 1, name: "Lab Sonuc" }, { id: 2, name: "Lab Cihaz" }, { id: 3, name: "Dış Lab Sonuc" }, { id: 4, name: "Dış Lab Cihaz" }]
  sonucAlButtonVisible: boolean;
  sonucEkleButtonVisible: boolean;
  reddetButtonVisible: boolean;
  imzalaButtonVisible: boolean;
  labOnayButtonVisible: boolean;
  patolojikButtonVisible:boolean;



  ngOnInit() {
    this.ddlOSGBSettings = this.ddlSettings.ddlOSGBListSettings;

    this.gridPersonelTetkikIslem.pager = [10, 25, 50, 100]
    this.isImzalama = true;
    this.isLaboratuvar = false;
    this.sonucAlButtonVisible = true;
    this.sonucEkleButtonVisible = false;
    this.reddetButtonVisible = false;
    this.imzalaButtonVisible = false;
    this.patolojikButtonVisible=true;
    this.frmFilter.islem = 4;
    this.dataRapor.GetTetkikGrubuList().then(dataTetkik => {
      this.getTetkikGrupList = dataTetkik;
    })
    this.personAutoComplete();
    this.getOSGBListOptions();
    this.dataRapor.GetDizaynList().then(o => {
      this.dizaynModel = o;
      this.ddlFrxOptions =this.dizaynModel.map(o=>o.Name)

    })

  }
  openPersonelTetkikModal() {
    this.perTetModalModel.firmaID = null;
    this.perTetModalModel.personelIDList = [];
    const initialState = {
      perTetModalModel: this.perTetModalModel
    };

    let modal = this.modalService.show(PersoneltetkikmodalComponent, { initialState, ignoreBackdropClick: true, class: 'modal-xl' });
    modal.content.onClose.subscribe(
      response => {
        if (response.length != 0) {
        }
        if (response !== null && response == "close") {
        }
      }, err => {
        console.log(err);
      }
    );
  }
  // autocompleted
  personAutoComplete() {
    this.settingsPerson = new AutoCompleteSettings();

    this.settingsPerson.onDataService = o => {
      debugger; 
      this.autoCompleteFilter.OsgbIdList=[];
      this.autoCompleteFilter.PersonelAdi = o;
      this.ddlOSGBSelectedItems.forEach(item => {
        this.autoCompleteFilter.OsgbIdList.push(item.Id)
      })
      this.autoCompleteFilter.FirmaAdi=this.frmFilter.firmaAdi;
      return this.personelServices.GetAutoComplete(this.autoCompleteFilter).then(o => {

        var array = o as any[];
        var res = [];
        for (let index = 0; index < array.length; index++) {
      
          const element = array[index];
          var item = new AutocompleteModel();
          item.Name = element.PersonelAdi;
          item.id = element.ID;
          item.displayText= element.PersonelAdi+ " / " + element.FirmaAdi
          res.push(item);
        }
        return res;
      });
    };
  }
  public onChangePerson(item: any) {  
    this.onSelectPersonInfo.emit(item[item.length-1])
    this.frmFilter.PersonelID=item[0].id
   }
   // autocompleted
  getOSGBListOptions() {
    -
      this.osgbFirmaServices.GetOsgbList().then(o => {
        // this.ddlOSGBList=o;


        this.ddlOSGBList = [];
        this.ddlOSGBList = o;
        console.log(this.ddlOSGBList);
   

      })
    // this.frmFilter.osgbIDList=this.ddlOSGBSelectedItems[0].Id;
  }
  onAddFileOrResult() {
    if (!this.gridPersonelTetkikIslem.hasSelectedItem())
      return;
    let item = this.gridPersonelTetkikIslem.clickedItem;

    const initialState = {
      dialogParams: { tetkikID: item.IslemID }
    };
    if (item.TetkikID == 3) {
      const initialStateGoz = {
        dialogParams: { tetkikID: item.IslemID, sourcePage: "personel", islem: 1 }
      };
      let instance = this.modalService.show(ExamResultEyeDialogComponent, { initialState: initialStateGoz, ignoreBackdropClick: true });
      instance.content.eventClose.subscribe(o => {
        this.gridPersonelTetkikIslem.onRefresh();
        this.gridPersonelTetkikIslem.selectedItems.length = 0;
      })
    }
    else if (item.TetkikID == 11) {
      let instance = this.modalService.show(ExamResultOdioDialogComponent, { initialState, ignoreBackdropClick: true });
      instance.content.eventClose.subscribe(o => {
        this.gridPersonelTetkikIslem.onRefresh();
      })
    }
    else if (item.TetkikID == 1) {
      initialState.dialogParams.tetkikID = item.DosyaID;
      let instance = this.modalService.show(ExamResultLungsDialogComponent, { initialState, class: "modal-lg", ignoreBackdropClick: true });
      instance.content.eventClose.subscribe(o => {
        this.gridPersonelTetkikIslem.onRefresh();
      })

    }
    else if (item.TetkikID == 574) {
      const initialStateGozKiran = {
        dialogParams: { tetkikID: item.IslemID }
      };
      initialState.dialogParams.tetkikID = item.DosyaID;
      let instance = this.modalService.show(ExamResultEyerefractiveDialogComponent, { initialState: initialStateGozKiran, class: "modal-lg", ignoreBackdropClick: true });
      instance.content.eventClose.subscribe(o => {
        this.gridPersonelTetkikIslem.onRefresh();
      })

    }
    else if (this.frmFilter.islem == 2) {

      const initialStateLab = {
        dialogParams: { islemControl: 2, personelId: item.PersonelID, tetkikGrubu: item.TetkikGrubu, tetkikID: item.IslemID, laboratuvarIslem: item.LaboratuvarIslem }
      };
      let instance = this.modalService.show(ExamResultBloodDialogComponent, { initialState: initialStateLab, class: "modal-lg", ignoreBackdropClick: true });
      instance.content.eventClose.subscribe(o => {
        this.gridPersonelTetkikIslem.onRefresh();
      })
    }
    else if (this.frmFilter.islem == 3) {
      const initialState = {
        dialogParams: { islemControl: 3, tetkikID: item.IslemID }
      };

      let instance = this.modalService.show(ExamResultBloodDialogComponent, { initialState, class: "modal-lg", ignoreBackdropClick: true });
      instance.content.eventClose.subscribe(o => {
        this.gridPersonelTetkikIslem.onRefresh();
      })
    }
    else if(item.TetkikID==572 ||item.TetkikID==88){
      let instance = this.modalService.show(ExamResultFileDialogComponent, { initialState,ignoreBackdropClick:true });
      instance.content.eventClose.subscribe(o => {
        this.gridPersonelTetkikIslem.onRefresh();
      })
    }
    else if (item.TetkikID == 14 || item.TetkikID == 215 || item.TetkikID == 5) {
      this.tetkikResultByIslemID.IslemID = item.IslemID;
      this.dataRapor.ExportPdfTetkikResultByID(this.tetkikResultByIslemID).then(o => {
        this.apiService.fileOperation.download(o);

      });
    }
    else {
    }
  }
  getClass(islem) {
    islem = this.frmFilter.islem
    if (islem == 2 || islem == 3) {
      return 'col-md-8'
    }
    else return 'col-md-12'

  }
  onSaveResultExcel() {
    this.modalService.show(SaveResultExcelComponent);
    this.modalService.onHide.subscribe(o => {
      this.gridPersonelTetkikIslem.onRefresh()
    }

    );
  }
  onTopluImza(select) {
    const initialState = {
      message: "Tüm Tetkikleri imzalamak istediğinize emin misiniz?",
    };
    let modal = this.modalService.show(ConfirmationDialogComponent, { initialState, ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {

          this.gridPersonelTetkikIslem.selectedItems.forEach(item => {

            this.saveTopluAkcigerModel.DosyaIDList.push(item.DosyaID)

          });

          if (select == 1) {
            this.saveTopluAkcigerModel.ImzaDurumu = true;
            this.dataRapor.SaveTopluAkcigerSonuc(this.saveTopluAkcigerModel).then(o => {
              this.gridPersonelTetkikIslem.onRefresh();
              this.saveTopluAkcigerModel.DosyaIDList = [];
            });
            this.tetkikImzalama.DosyaIDList = [];
            this.gridPersonelTetkikIslem.selectedItems.length = 0;
          }
          else if (select == 2) {
            this.saveTopluAkcigerModel.ImzaDurumu = false;
            this.dataRapor.SaveTopluAkcigerSonuc(this.saveTopluAkcigerModel).then(o => {
              this.gridPersonelTetkikIslem.onRefresh();
              this.saveTopluAkcigerModel.DosyaIDList = [];
            });
            this.tetkikImzalama.DosyaIDList = [];
            this.gridPersonelTetkikIslem.selectedItems.length = 0;
          }
          else if (select == 2) {
            this.saveTopluAkcigerModel.DoktorID  = 3;
            this.dataRapor.SaveTopluAkcigerSonuc(this.saveTopluAkcigerModel).then(o => {
              this.gridPersonelTetkikIslem.onRefresh();
              this.saveTopluAkcigerModel.DosyaIDList = [];
            });
            this.tetkikImzalama.DosyaIDList = [];
            this.gridPersonelTetkikIslem.selectedItems.length = 0;
          }         
        }

      })

  }
  onImzala(select) {
    const initialState = {
      message: "Tetkiği imzalamak istediğinize emin misiniz?",
    };
    let modal = this.modalService.show(ConfirmationDialogComponent, { initialState, ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {

        if (response !== null && response == "confirm") {

          this.gridPersonelTetkikIslem.selectedItems.forEach(item => {
            this.tetkikImzalama.DosyaIDList.push(item.DosyaID);
          });
          console.log(this.tetkikImzalama);
          if (select == 1) {
            this.tetkikImzalama.ImzaliDosyaKullan = true;
            this.dataRapor.GetRaporImzalama(this.tetkikImzalama).then(o => {
              this.gridPersonelTetkikIslem.onRefresh();
              this.tetkikImzalama.DosyaIDList = [];
            });
            this.tetkikImzalama.DosyaIDList = [];
          }
          else if (select == 2) {
            this.tetkikImzalama.ImzaliDosyaKullan = false;
            this.dataRapor.GetRaporImzalama(this.tetkikImzalama).then(o => {
              this.gridPersonelTetkikIslem.onRefresh();
              this.tetkikImzalama.DosyaIDList = [];
            });

            this.tetkikImzalama.DosyaIDList = [];
          }
          else { }
        }
      }, err => {
        console.log(err);
      }
    );
  }
  onTetkikOnayKaldir() {
    if (!this.gridPersonelTetkikIslem.hasSelectedItem())
      return;
    var idList = this.gridPersonelTetkikIslem.selectedItems.map(o => (o as TetkikResultFilter).IslemID);
    this.tetkikResult.islemIDList = idList;
    this.sonucAl.GetTekrarOnayIste(this.tetkikResult).then(o => {
      this.gridPersonelTetkikIslem.onRefresh();
    });

  }
  onLabConfirm() {
    const labOnay = new LabOnayModel();

    var idList = this.gridPersonelTetkikIslem.selectedItems.map(o => (o as TetkikResultFilter).IslemID);
    labOnay.IDlist = idList;
    labOnay.Dizayn = this.dizayn;
    this.dataRapor.GetLabTetkikOnayla(labOnay).then((response: boolean) => {
      console.log(response)
      this.gridPersonelTetkikIslem.onValueChangege();
    }, (err) => {
      console.log('hata var');
    })


  }
  onLabAddResult() {

    const labSaveModel = new SaveTopluLaboratuvarSonucModel();

    var idList = this.gridPersonelTetkikIslem.selectedItems.map(o => (o as TetkikResultFilter).IslemID);
    labSaveModel.IslemIDList = idList;
    this.dataRapor.SaveTopluKanSonuc(labSaveModel).then((response: boolean) => {
      console.log(response)
      this.gridPersonelTetkikIslem.onRefresh();
    }, (err) => {
      console.log('hata var');
    })


  }
  // deneme(){
  //   let item = this.gridPersonelTetkikIslem.clickedItem;
  //   this.tetkikImzalamaRed.IslemID = item.IslemID;
  //   this.tetkikImzalamaRed.DosyaID = item.DosyaID;
  //   const initialState = {
  //     inputLabel: "Red Sebebi",
  //     islemId:item.IslemID,
  //     dosyaId:item.DosyaID
  //   };

  //   let modal = this.modalService.show(ConfirmationDialogRejectionComponent, { initialState, ignoreBackdropClick: true });
  // }
  onReddet() {
    //let item = this.gridPersonelTetkikIslem.clickedItem;
    const initialState = {
      inputLabel: "Red Sebebi",   
    };
    let modal = this.modalService.show(ConfirmationDialogRejectionComponent, { initialState, ignoreBackdropClick: true });
    modal.content.onClose.subscribe(response => {
      console.log(response);
      if (response.event !== null && response.event == "confirm") {
        this.gridPersonelTetkikIslem.selectedItems.forEach(item => {
          this.tetkikImzalamaRed.IslemID = item.IslemID;
          this.tetkikImzalamaRed.DosyaID = item.DosyaID;  
          this.tetkikImzalamaRed.RedSebebi = response.message;
          this.dataRapor.GetSonucRed(this.tetkikImzalamaRed).then(o => {
            this.gridPersonelTetkikIslem.onRefresh();
          });       
        });       
      }
    }, err => {
      console.log(err);
    }
    );

   
    console.log(this.tetkikImzalamaRed);
  }

  frmFilter: FrmFilter = new FrmFilter();
  gridPersonelTetkikIslem: PersonelComponent_Models.GridPersonelTetkikIslem = new PersonelComponent_Models.GridPersonelTetkikIslem(this.dataRapor, this.frmFilter, this.sonucAl, this.apiService, this.modalService);


  onFilter() {
    this.frmFilter.osgbIDList = [];
    // this.frmFilter.osgbIDList=this.ddlOSGBSelectedItems[0].Id;

    this.ddlOSGBSelectedItems.forEach(item => {
      this.frmFilter.osgbIDList.push(item.Id)
    })

    console.log(this.ddlOSGBSelectedItems[0]);
    this.gridPersonelTetkikIslem.onRefresh();
  }
  onChangeImzalama(itemName) {
    if (itemName == "1: 4") {
      this.isImzalama = true;
      this.isLaboratuvar = false;
      this.sonucAlButtonVisible = true;
      this.sonucEkleButtonVisible = false;
      this.reddetButtonVisible = false;
      this.imzalaButtonVisible = false;
      this.labOnayButtonVisible = false;
      this.patolojikButtonVisible=true;

    }
    else if (itemName == "0: 1") {
      this.isImzalama = false;
      this.isLaboratuvar = false;

      this.sonucAlButtonVisible = false;
      this.sonucEkleButtonVisible = true;
      this.reddetButtonVisible = true;
      this.imzalaButtonVisible = true;
      this.labOnayButtonVisible = false;
      this.patolojikButtonVisible=false;
    }
    else if (itemName == "2: 3") {
      this.isLaboratuvar = false;
      this.isImzalama = false;

      this.sonucAlButtonVisible = false;
      this.sonucEkleButtonVisible = false;
      this.reddetButtonVisible = false;
      this.imzalaButtonVisible = false;
      this.labOnayButtonVisible = true;
      this.patolojikButtonVisible=false;
    }
    else if (itemName == "3: 2") {
      this.isImzalama = false;
      this.isLaboratuvar = true;

      this.sonucAlButtonVisible = false;
      this.sonucEkleButtonVisible = true;
      this.reddetButtonVisible = true;
      this.imzalaButtonVisible = false;
      this.labOnayButtonVisible = false;
      this.patolojikButtonVisible=false;

    }
    this.gridPersonelTetkikIslem.onRefresh();
  }

}
export class FrmFilter {
  tarihRange: { start: moment.Moment, end: moment.Moment };
  osgbAdi: string = "";
  firmaAdi: string = "";
  tcNo: string = "";
  adiSoyadi: string = "";
  tetkikGrup: string = "";
  tetkikAdi: string = "";
  tetkikTuru: string = "";
  tarihBas: any = null;
  tarihBit: any = null;
  yazdirilanlar: boolean = null;
  laboratuvarIslem: number = 0;
  islem: number;
  reddedilenler: boolean = false;
  barkod: any;
  tetkikGrupID: any = ""
  osgbIDList: any[] = [];
  isPatolojik:boolean=false;
  PersonelID:any=null;
}

namespace PersonelComponent_Models {

  export class GridPersonelTetkikIslem extends Grid.GridControl<any> {
    public direction: number = 1;
    labParams: any;
    constructor(private dataRapor: PersonelTetkikIslemServices, private frmFilter: FrmFilter, private sonucAl: PersonelTetkikServices, private apiService: ApiService, private modalService: BsModalService) {
      super();
    }


    filter = new Grid.GridInputModel(new TetkikResultFilter());
    getFilter() {

      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;

      let item = this.filter.filter;
      var f = this.frmFilter;

      if (f.tarihRange.start == null) {
        item.BasTarih = null
        // item.BasTarih.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        item.BitTarih = null
      }
      else {
        item.BasTarih = f.tarihRange.start;
        item.BitTarih = f.tarihRange.end;
      }
      item.FirmaAdi = f.firmaAdi;
      item.OsgbAdi = f.osgbAdi;
      item.PersonelTc = f.tcNo;
      item.PersonelAdi = f.adiSoyadi;
      item.TetkikGrubu = f.tetkikGrup;
      item.TetkikGrubuID = f.tetkikGrupID
      item.Yazdirilanlar = f.yazdirilanlar;
      item.TetkikAdi = f.tetkikAdi;
      item.TetkikTuru = f.tetkikTuru;
      item.LaboratuvarIslem = f.laboratuvarIslem;
      item.Islem = f.islem;
      item.Reddedilenler = f.reddedilenler;
      item.Barkod = f.barkod;
      item.OsgbIDList = f.osgbIDList;
      item.IsPatolojik=f.isPatolojik;
      item.PersonelID=f.PersonelID;

      return this.filter;
    };
    onExportExcel() {
      const barkodModelByTetkikIDList = new BarkodModelByTetkikIDList();

      var idList = this.selectedItems.map(o => (o as TetkikResultFilter).IslemID);
      barkodModelByTetkikIDList.TetkikIDList = idList;

      this.dataRapor.Export(barkodModelByTetkikIDList).then(o => {
        if (!o)
          return;
        let fileID = o.Data;
        this.apiService.fileOperation.download(fileID);
      }, o => {
        console.log(o);
      })
    }
    onGetResult(select) {

      // if (this.selectedItems.length == 0)
      //   return;

      // PdfMi:any;

      this.filter.filter.IslemIDList = this.selectedItems.map(o => (o as TetkikResultFilter).IslemID);
      this.filter.filter.PdfMi
      if (select == 1) {
        this.filter.filter.PdfMi = true;
        this.dataRapor.RaporResult(this.filter).then(o => {
        })
      }
      if (select == 2) {
        let itemList: [] = []
        const initialState = { itemList: itemList }
        let modal = this.modalService.show(RaporResultItemlistModalComponent, { initialState, ignoreBackdropClick: true });
        modal.content.onClose.subscribe(res => {
          this.filter.filter.PdfMi = false;
          this.filter.filter.ItemList = res;
          this.dataRapor.RaporResult(this.filter).then(o => {
          })

        })
        // this.filter.filter.PdfMi=false;
        // this.dataRapor.RaporResult(this.filter).then(o => {
        // })
      }

    }
    onGetResultExcel() {


      this.filter.filter.IslemIDList = this.selectedItems.map(o => (o as TetkikResultFilter).IslemID);
      this.filter.filter.PdfMi


      this.dataRapor.ExcelExportResult(this.filter).then(o => {
      })

    }
    onExportAll() {
      var f = this.getFilter();
      this.dataRapor.GetTetkikResult(f).then(o => {

      });
    };
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    };
    onRefresh() {
      var item = this.getFilter()
      var filter = item.filter;
      console.log(item);

      this.selectedItems.length = 0;
      if (this.frmFilter.islem == 2 || this.frmFilter.islem == 3)
        this.pager = [10, 25, 50, 100, 200, 300]
      else
        this.pager = [10, 25, 50, 100]

      return this.dataRapor.getTetkikList(item).then(o => {
        this.data = o;

      })

    }
    public dataListIx: number = -1;

    // private getItemByIndex(index:number)
    // {
    //   let item= this.data.list[this.dataListIx];
    // }
    private getIndexByItem(item: any) {
      let index = this.data.list.indexOf(item);
      return index;
    }


    onClickItem(item: any) {
      this.dataListIx = this.getIndexByItem(item)
      this.clickedItem = item;
      this.labParams = this.clickedItem;
      console.log(this.labParams)


    };
    onValueChangege() {
      this.onRefresh().then(o => {

        let item = this.data.list[this.dataListIx];
        this.onClickItem(item);
      });



    }
  }

}
