import { Component, OnInit } from '@angular/core';
import { Grid } from '../../../../../../shared/models/UIControls/grid-control';
import { TetkikTanimlamaServices } from '../../../services/TetkikTanimlamaService';
import { TetkikViewFilter } from 'src/app/features/private/definitions/models/TetkikViewFilter';
import { SaveTetkikComponent } from '../../../modals/save-tetkik/save-tetkik.component';
import { BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-tetkiklist',
  templateUrl: './tetkiklist.component.html',
  styleUrls: ['./tetkiklist.component.css']
})
export class TetkiklistComponent implements OnInit {

  constructor(private tetkikService: TetkikTanimlamaServices, private modalService: BsModalService) { }

  ngOnInit() {

    this.gridTetkik.onRefresh();
  }

  tetkikFilter: TetkikFilter = new TetkikFilter();
  gridTetkik: OsgbListComponent_Models.GridOsgb = new OsgbListComponent_Models.GridOsgb(this.tetkikService, this.tetkikFilter);
  
  public onTetkikSave() {
    const initialState = {
      tetkik: undefined,
    };
    let modal = this.modalService.show(SaveTetkikComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          this.gridTetkik.onRefresh();
        }
      }, err => {
        console.log(err);
      }
    );
  }
  onFilterInputChange(event) {
    this.gridTetkik.onRefresh();
  
  }
  public onTetkikUpdate(tetkik) {
    console.log(tetkik);
    const initialState = {
      tetkik: tetkik,
    };
    let modal = this.modalService.show(SaveTetkikComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          this.gridTetkik.onRefresh();
        }
      }, err => {
        console.log(err);
      }
    );
  }
}

export class TetkikFilter {
  Id: any;
  TetkikGrubu: any;
  TetkikAdi: any;
  Ucret: any;
  OrnekTuru: any;
  DizaynAdi: any;
  GozMu: any;
  OSGBF: any;
  OSGBF2: any;
  FirmaF: any;
  Firma2: any;
  Aktif: any;
}

namespace OsgbListComponent_Models {

  export class GridOsgb extends Grid.GridControl<any> {

    public direction: number = 1;

    constructor(private tetkikService: TetkikTanimlamaServices, private tetkikFilter: TetkikFilter) {
      super();
    }

    filter = new Grid.GridInputModel(new TetkikViewFilter());

    getFilter() {
      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;

      let item = this.filter.filter;
      var o = this.tetkikFilter;

      item.Id = o.Id;
      item.TetkikGrubu = o.TetkikGrubu;
      item.TetkikAdi = o.TetkikAdi;
      item.Ucret = o.Ucret;
      item.OrnekTuru = o.OrnekTuru;
      item.DizaynAdi = o.DizaynAdi;
      item.GozMu = o.GozMu;
      item.OSGBF = o.OSGBF;
      item.OSGBF2 = o.OSGBF2;
      item.FirmaF = o.OSGBF2;
      item.Firma2 = o.OSGBF2;
      item.Aktif = o.Aktif;

      return this.filter;
    };
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    onRefresh() {
      var item = this.getFilter()
      var filter = item.filter;

      this.tetkikService.GetTetkikList2(item).then(o => {
        this.data = o;
      });
    }   

  }

}
