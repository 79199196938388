import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { Observable, Subject, fromEvent } from 'rxjs';
import { map, filter, catchError, mergeMap, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';


@Component({
  selector: 'app-examinations',
  templateUrl: './examinations.component.html',
  styleUrls: ['./examinations.component.css']
})
export class ExaminationsComponent implements OnInit {

  public onClose: Subject<any>;
  public getTekikListUrl: string = "PersonelTetkik/GetTetkikList";
  public direction: number = 1;
  public ChangeControl:any;
  public HBYSInputVisible:boolean;
  @ViewChild('filterSection', {static: false}) input: ElementRef;
  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService, private modalService: BsModalService) { }

  public state: any = {
    
    init: (personelIDList, firmaID,BasTarih,BitTarih,changeControl,TetkikID) => {
      this.savingModel.personelIDList = personelIDList;
      this.savingModel.firmaID = firmaID;
      this.savingModel.BasTarih=BasTarih;
      this.savingModel.BitTarih=BitTarih;
      this.ChangeControl=changeControl;
      this.savingModel.silinecekTetkikID=TetkikID;

      if(this.savingModel.personelIDList.length==1){
        this.HBYSInputVisible=true
      }
console.log(this.state)
    }
  }
  ngOnInit() {

    this.ui.gridExam.onRefresh();
    this.onClose = new Subject();
    this.direction=1;
  }
  ngAfterViewInit() {
    
    let tableDom = this.input.nativeElement;
    let items = tableDom.querySelectorAll('.filterInput');
  
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      fromEvent(item, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(2000),
          distinctUntilChanged(),
          tap((text) => {
            if(item.value.length>1)
            this.ui.gridExam.onRefresh();
            else if(item.value.length==0)
            this.ui.gridExam.onRefresh();
          })
        )
        .subscribe();
    }
    
  }
  public savingModel: any = {
    personelIDList: [],
    firmaID: 0,
    paketteYapildi: false,
    yapildi: true,
    medlife: true,
    tetkikTuru: "",
    tetkikIDList: [],
    BasTarih: "",   
    BitTarih:"",
    silinecekTetkikID :null,
    HbysBarkod:"",

  };

  public ui: any = {
    error: {
      data: [],
      clear: () => {
        this.ui.error.data.length = 0;
      },
      add: (msg) => {
        this.ui.error.data.push(msg);
      }
    },
    gridExam: {
      filter: {
      },
      pager: [5, 15, 25, 50],
      paging: {
        count: 5,
        pageNumber: 1
      },
      sorting: {},
      data: [],
      totalItemCount: 0,
      selectedItems: [],
      clickedItem: undefined,

      onSorting : (colName) =>{
        if (this.direction == 1) {
          this.direction = 0;
        } else {
          this.direction = 1;
        }
        
        this.ui.gridExam.sorting = { colName: colName, direction: this.direction };
        this.ui.gridExam.onRefresh();
      },
      onRefresh: () => {
        this.ui.gridExam.clickedItem = undefined;
    
        var filter = this.ui.gridExam.getFilter();
        
        this.data.getTetkikList(filter).subscribe(resp => {
          let res = resp.Data;
          this.ui.gridExam.data = res.List;
          this.ui.gridExam.totalItemCount = res.TotalCount;
        }
        )
      },
      onPageChanged: (event) => {
        this.ui.gridExam.paging.pageNumber = event.page;
        this.ui.gridExam.onRefresh();
      },
      getFilter: () => {
        return {
          pager: this.ui.gridExam.pager,
          paging: {
            pageNumber: this.ui.gridExam.paging.pageNumber,
            count: this.ui.gridExam.paging.count
            
          },
          filter: this.ui.gridExam.filter,
          sorting: this.ui.gridExam.sorting
        }
      },


      onSelectItem: (item: any) => {
        if (this.ui.gridExam.isSelected(item)) {
          var ar = this.ui.gridExam.selectedItems;
          var index = ar.indexOf(item);
          if (index > -1) {
            ar.splice(index, 1);
          }
          return;
        }
        this.ui.gridExam.selectedItems.push(item);
      },
      onClickItem: (item: any) => {
        this.ui.gridExam.selectedItem = item;     
      },
      isSelected: (item: any) => {
        return this.ui.gridExam.selectedItems.some(o => o == item);
      },
     
      getRowClass: (item: any) => {
        if (this.ui.gridExam.isSelected(item)) {
          return 'table-success';
        } else {
          if (this.ui.gridExam.selectedItem == item)
            return 'table-info';
          return ''
        }
      },
    }
  }


  public data: any = {
    getTetkikList: (filter) => {
      return this.apiservice.callPostService(this.getTekikListUrl, filter);
    }
  };

  public onSaveExamination(str) {

    this.ui.error.clear();
    let model = Object.assign({}, this.savingModel);
   
    if (!this.ui.gridExam.selectedItems) {
      this.ui.error.add("Tetkik seçiniz");
      return;
    }
    model.tetkikIDList.length = 0;

    model.tetkikIDList=this.ui.gridExam.selectedItems.map(o=>o.Id)
    if (!model.tetkikTuru) {
      this.ui.error.add("Tetkik Türü seçiniz");
      return;
    }
    if (str == "all") {
      model.personelIDList = undefined;
    }
    else {
      model.firmaID = undefined;
    }
    if(this.ChangeControl==1){
      model.silinecekTetkikID=this.savingModel.silinecekTetkikID
    }
    else
    model.silinecekTetkikID=null
    this.apiservice.callPostService("PersonelTetkik/SaveTetkik", model).subscribe(
      resp => {
   
        if (!resp) {
          return;
        }
        this.onClose.next('save-examination');
        // this.bsModalRef.hide();
      }, err => {

      }
    )
  }

}
