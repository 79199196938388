import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { EmailIcerikModel } from 'src/app/features/private/transactions/models/EmailIcerikModel';
import { SentMailFilter } from 'src/app/features/private/transactions/models/SentMailFilter';
import { PersonelTetkikIslemServices } from 'src/app/features/private/transactions/services/PersonelTetkikIslemService';

@Component({
  selector: 'app-confirmation-mail-form',
  templateUrl: './confirmation-mail-form.component.html',
  styleUrls: ['./confirmation-mail-form.component.css']
})
export class ConfirmationMailFormComponent implements OnInit {
  public islemID:any;
  public dosyaID:any;
  public mailBaslik:any;
  public mailMesaj:any;
  public osgbMail:any;
  public redsebep:any;

  mailDetay:EmailIcerikModel=new EmailIcerikModel;
  sendMailModel:SentMailFilter=new SentMailFilter
  @Input() set islemId(val: any) {
    if(val==null || val==undefined) return
    this.islemID=val;
  }
  @Input() set dosyaId(val: any) {
    if(val==null || val==undefined) return
    this.dosyaID=val;
     }
     @Input() set mesaj(val: any) {
      if(val==null || val==undefined) return
      this.redsebep=val;
       }
  constructor(private dataRapor: PersonelTetkikIslemServices) { }

  ngOnInit() {
   
    this.mailDetay.DosyaID=this.dosyaID;
    this.mailDetay.IslemID=this.islemID;
     this.dataRapor.GetEmailDetail(this.mailDetay).then(o=>{
      this.mailBaslik=o.Baslik;
      this.mailMesaj=o.Mesaj;
      this.osgbMail=o.OsgbEmail;
      debugger;
       console.log(o)
     })
  }
  sendMail(){
    this.sendMailModel.Baslik=this.mailBaslik;
    this.sendMailModel.Email=this.osgbMail;
    this.sendMailModel.EmailIcerik=this.mailMesaj + '<br/>' + this.redsebep;
    console.log(this.sendMailModel)
    debugger;
     this.dataRapor.SentMail(this.sendMailModel).then(o=>{

     });
  }

}
