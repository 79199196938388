export class OSGBUIModel {
  Id: any;
  OSGBKodu: any;
  OSGBAdi: any;
  OSGBVD: any;
  OSGBVN: any;
  OSGBAdres: any;
  OSGBIl: any;
  OSGBIlce: any;
  OSGBTel: any;
  OSGBFax: any;
  OSGBInternet: any;
  OSGBEmail: any;
  OSGB_SozBasTar: any;
  OSGB_SozBitTar: any;
  Aciklama: any;
  Aktif: any;
  LabIDList:any[];
  LabList:any[];
}
