import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '../shared/modals/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ModalManagerService {

  public modalData : any ;

  constructor(private modalService: BsModalService) { }

  public getModalData (){
    return this.modalData;
  }

  public setModalData(data : any){
     this.modalData = data ;
  }

  public openConfirmation(msg: string): Subject<any> {
    const initialState = {
      message: msg
    };
    let instance = this.modalService.show(ConfirmationDialogComponent, { initialState,ignoreBackdropClick: true });
    return instance.content.onClose;
  }
}
