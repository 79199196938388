import { Component, OnInit, ViewChild } from '@angular/core';
import { FTPGridComponent } from '../ftp-grid/ftp-grid.component';
import { ApiService } from 'src/app/Services/api.service';
import { FtpServices } from '../../../services/FtpService';
import { UploadModel } from '../../../models/UploadModel';
@Component({
  selector: 'ftp-upload-page',
  templateUrl: './ftp-upload-page.component.html',
  styleUrls: ['./ftp-upload-page.component.css'],
})
export class FTPUploadPageComponent {
  @ViewChild(FTPGridComponent, { static: false })
  public _FTPGridComponent: FTPGridComponent;

  ngOnInit(): void {

  }
  constructor(private ftpService: FtpServices, private apiService: ApiService) {

  }

  public onFileUploadComplete(e: any) {

    var res = this.apiService.solveResponseJSON(e);
    
    let fileID: string = res.Data[0];
    this.ftpService.UploadDosya({ FileID: fileID }).then(o => {
      this._FTPGridComponent.grid.onRefresh();
    });
    //this._FTPGridComponent.grid.refresh()
  }
}
