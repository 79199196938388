import { Component, OnInit, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { PermissionViewModel } from '../../models/PermissionViewModel';
import { authService } from 'src/app/Services/authService';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  public collapseSidebar: boolean = true;
  public isfirstCollapsed1:boolean=false;
  public isfirstCollapsed:boolean=false;
  public isfirstCollapsed2:boolean=false;
  public collapse:boolean=true;
  // public sidebarClass: string = 'col-md-12 h-100';

  @Input() set toggle(value: any) {
    if (value == undefined)
      return;

    this.collapse =value;
    if(this.collapse==false){
    this.isfirstCollapsed1=true;
    this.isfirstCollapsed=true;
    this.isfirstCollapsed2=true;
    }
    else{
      this.isfirstCollapsed1=false;
      this.isfirstCollapsed=false;
      this.isfirstCollapsed2=false;
    }
  
  }

  permission: PermissionViewModel = new PermissionViewModel();
  constructor(private cookieService: CookieService,private router: Router, private authService: authService) { 
    this.authService.Ready.then(o => {
      this.permission = o;
    });
  }

  ngOnInit() {
  }

  logout(){
    this.cookieService.delete('auth');
    this.router.navigate(['login']);

  }

  // public onToggleSideBar() {
  //  if (this.sidebarClass == 'col-md-2 h-100') {
  //    this.sidebarClass = 'col-md-1 h-100 p-1';
  //   //  this.maindivClass = 'col-md-11 h-100';
  //    this.collapseSidebar = true;
  //  } else {
  //    this.sidebarClass = 'col-md-2 h-100';
  //   //  this.maindivClass = 'col-md-10 h-100';
  //    this.collapseSidebar = false;
  //  }
  // }
  // public onToggleSideBar(){
  //   debugger;
  //   this.collapseSidebar=(!this.collapseSidebar)
  //   if(this.collapseSidebar==false)
  //   this.isfirstCollapsed1=false;
  // }


}
