import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-deletepl-exam',
  templateUrl: './deletepl-exam.component.html',
  styleUrls: ['./deletepl-exam.component.css']
})
export class DeleteplExamComponent implements OnInit {

  public deletetkikByIDUrl: string = "PersonelTetkik/DeleteByTetkikId";
    public tekikID : any;

  constructor(public bsModalRef: BsModalRef,private apiservice : ApiService,private modalService: BsModalService) { }

  ngOnInit() {
  }

  onDeleteTekik(){
    this.apiservice.callPostService(this.deletetkikByIDUrl,{ tetkikId : this.tekikID }).subscribe(
      resp =>{
        console.log(resp);
        this.modalService.setDismissReason('delete-personel-tekik');
        this.bsModalRef.hide();
      },err =>{
        console.log(err);
      }
    );
  }

}
