import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-delete-multiplepersonel',
  templateUrl: './delete-multiplepersonel.component.html',
  styleUrls: ['./delete-multiplepersonel.component.css']
})
export class DeleteMultiplepersonelComponent implements OnInit {
  public deletePersonelUrl : string = "Personel/DeleteToplu";

  public FirmaID : any;
  
  constructor(public bsModalRef: BsModalRef,private apiservice : ApiService,private modalService: BsModalService) { }

  ngOnInit() {
    console.log(this.FirmaID);
  }


 public ondeleteAll(){
  this.apiservice.callPostService(this.deletePersonelUrl,{ FirmaID : this.FirmaID }).subscribe(
    resp =>{
      console.log(resp);
      this.modalService.setDismissReason('delete-multiple-personel');
      this.bsModalRef.hide();
    },err =>{
      console.log(err);
    }
  );

  }

}
