import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
import { TetkikFiyatlandirmaFilter } from '../models/TetkikFiyatlandirmaFilter';
import { TetkikFiyatlandirmaViewModel } from '../models/TetkikFiyatlandirmaViewModel';

@Injectable({
  providedIn: 'root'
})
export class TetkikFiyatService {

  constructor(private api: ApiService) { }

  public GetTetkikFiyatList(model: Grid.GridInputModel<TetkikFiyatlandirmaFilter>): Promise<Grid.GridOutputModel<TetkikFiyatlandirmaViewModel>> {
    return this.api.callService("TetkikFiyat/GetTetkikFiyatList", model).then(o => {
      var res = new Grid.GridOutputModel<TetkikFiyatlandirmaViewModel>();
      res.list = o["List"]; 
      res.totalCount = o["TotalCount"];
      return res;
    });
  };
}
