export class PaketViewFilter {
  PaketId: any;
  PaketAdi: any;
  PaketUcreti: any;
  PaketAktif: any;
  PaketKaydeden: any;
  PaketKayitTarihi: any;
  PaketDegistiren: any;
  PaketDegTarihi: any;
  OsgbAdi: any;
  PaketFirmaID: any;
  PaketOsgbID: any;
}
