import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, BsLocaleService, DateFormatter } from 'ngx-bootstrap';
import { Subject, concat, from, merge, forkJoin } from 'rxjs';
import { FileSelectDirective, FileDropDirective, FileUploader } from 'ng2-file-upload';
import { ApiService } from 'src/app/Services/api.service';
import { Grid } from 'src/app/shared/models/UIControls/grid-control';
import { FtpServices } from '../../../services/FtpService';
import { FTPDosyaViewModel } from '../../../models/FTPDosyaViewModel';
import { FTPDosyaFilter } from '../../../models/FTPDosyaFilter';
import { ConfirmationDialogComponent } from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogWithinputComponent } from 'src/app/shared/modals/confirmation-dialog-withinput/confirmation-dialog-withinput.component';
import { defineLocale, trLocale } from 'ngx-bootstrap/chronos';
import { DownloadModel } from '../../../models/DownloadModel';
import { DatePipe } from '@angular/common';

defineLocale('tr', trLocale);
@Component({
  selector: 'ftp-grid',
  templateUrl: './ftp-grid.component.html',
  styleUrls: ['./ftp-grid.component.css'],

})
export class FTPGridComponent implements OnInit {
  filter: FTPDosyaFilter;
  ftpFilter: ftpFilter = new ftpFilter();
  public grid: FTPGridComponent_Models.GridFTP;
  ddlIndirmeOptions = [{ id: 0, name: "Hepsi" }, { id: 1, name: "İndirilenler" }, { id: 2, name: "İndirilmeyenler" }];
  ddlGorulmeOptions = [{ id: 0, name: "Hepsi" }, { id: 1, name: "Görünlenler" }, { id: 2, name: "Görülmeyenler" }];
  ddlOnayOptions = [{ id: 0, name: "Hepsi" }, { id: 1, name: "Onaylananlar" }, { id: 2, name: "Onaylanmayanalar" }];


  ngOnInit(): void {
    this.localeService.use('tr');
  }

  constructor(private apiService: ApiService, private _ftpService: FtpServices, private modalService: BsModalService, private localeService: BsLocaleService) {

    this.filter = new FTPDosyaFilter();
    this.grid = new FTPGridComponent_Models.GridFTP(this.apiService, this._ftpService, this.ftpFilter, this.modalService);
    this.grid.onRefresh();

  }
}

export class ftpFilter {
  id: any;
  osgbAdi: string;
  yuklemeTarihi: any;
  indirmeTarihi: any;
  gormeTarihi: any;
  onayTarihi: any;
  indirmeChecbox: any = 0;
  onayChecbox: any = 0;
  gorenChecbox: any = 0;



}
namespace FTPGridComponent_Models {

  export class GridFTP extends Grid.GridControl<FTPDosyaViewModel> {
    public downlandControl = false
    constructor(private _apiService: ApiService, private _ftpService: FtpServices, private ftpFilter: ftpFilter, private modalService: BsModalService) {
      super();

    }

    filter = new Grid.GridInputModel(new FTPDosyaFilter());
    note: any;
    pipe = new DatePipe('tr-TR');
    getFilter() {

      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;

      let item = this.filter.filter;
      item.ID = this.ftpFilter.id;
      item.OsgbAdi = this.ftpFilter.osgbAdi;
      item.YuklemeTarihi = this.ftpFilter.yuklemeTarihi;
      item.IndirmeTarihi = this.ftpFilter.indirmeTarihi;
      item.GormeTarihi = this.ftpFilter.gormeTarihi;
      item.OnayTarihi = this.ftpFilter.onayTarihi;
      item.IndirmeChecbox = this.ftpFilter.indirmeChecbox;
      item.OnayChecbox = this.ftpFilter.onayChecbox;
      item.GorenChecbox = this.ftpFilter.gorenChecbox;

      item.IndirmeTarihi = this.pipe.transform(item.IndirmeTarihi, 'yyyy-MM-dd');
      item.GormeTarihi = this.pipe.transform(item.IndirmeTarihi, 'yyyy-MM-dd');
      item.OnayTarihi = this.pipe.transform(item.IndirmeTarihi, 'yyyy-MM-dd');

      return this.filter;
    };
    onClearFilter() {
      this.ftpFilter.id = "";
      this.ftpFilter.osgbAdi = "";
      this.ftpFilter.indirmeChecbox = "";
      this.ftpFilter.gorenChecbox = "";
      this.ftpFilter.onayChecbox = "";
      this.ftpFilter.yuklemeTarihi = "";
      this.ftpFilter.gormeTarihi = "";
      this.ftpFilter.indirmeTarihi = "";
      this.ftpFilter.onayTarihi = "";

      this.onRefresh();

    }
    getRow(item: any) {

      var list = [];
      if (item.OnayTarihi !== null) {
        list.push("table-style2")
      }
      else if (item.IndirmeTarihi !== null && item.Indiren == "admin") {
        list.push("table-style3");
      }



      if (this.isSelected(item)) {
        list.push('table-success');

      } else {

        if (this.clickedItem == item)
          list.push('table-info');
      }

      return list.join(" ");
    }
    onRefresh() {

      var item = this.getFilter()
      var filter = item.filter;
      this._ftpService.GetFtpDosyaList(item).then(o => {
        this.data = o
      })
      console.log(item)
      this.selectedItems.length = 0;
    };
    public changeState(state: number) {
      if (this.selectedItems.length == 0)
        return;
      let idList = this.selectedItems.map(o => (o as FTPDosyaViewModel).ID);
      this._ftpService.FTPDosyaIslemler({ IDList: idList, Durum: state }).then(o => {
        this.onRefresh();
      })
    }
    public changeStateNote(state: number) {


      const initialState = {
        inputLabel: "Onay Notu"
      };
      // let id = this.selectedItems.map(o => (o as FTPDosyaViewModel).ID);
      let id = this.selectedItems.map(o => (o as FTPDosyaViewModel).ID);
      let modal = this.modalService.show(ConfirmationDialogWithinputComponent, { initialState,ignoreBackdropClick: true });
      modal.content.onClose.subscribe(response => {

        console.log(response);
        if (response.event !== null && response.event == "confirm") {
          this.note = response.message;
          console.log(this.note)
          this._ftpService.NotEkle({ ID: id[0], Not: this.note, Durum: state }).then(o => {
            this.onRefresh();
          })
        }
      }, err => {
        console.log(err);
      }
      );


    }
    public sendPacs() {
      var items = this.selectedItems;
      if (!items.length)
        return;
      let ids = items.map(o => (o as FTPDosyaViewModel).ID);
      //Test ID si(127313);
      this._ftpService.SendPacs(ids).then(o=>{
        alert(o);
      });
    }
    public async onDownload() {
      this.downlandControl = true
      var items = this.selectedItems
      if (!items.length)
        return;
      this.DownloadItems(items);
      this.selectedItems.length = 0;
    }

    private DownloadItems(items: any[]) {
      var arr = items.map(o => (o as FTPDosyaViewModel).ID);
      var arPromise = [];
      for (var i = 0; i < arr.length; i++) {
        let id = arr[i];
        //TODO dosya yoksa hat verdir
        var model = new DownloadModel();
        model.ID = id;
        let promise = this._ftpService.DownloadDosya(model);
        arPromise.push(promise);
      }

      var arObs = arPromise.map(o => from(o));
      forkJoin(arObs).subscribe(o => {
        this._apiService.fileOperation.downloadAll(o).then(o =>
          this.downlandControl = false);
        this.onRefresh();
      });
    }
  }
}

