import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { DownloadModel, UploadModel } from '../models/DownloadModel';
import { TetkikRaporSablonViewModel } from '../models/TetkikRaporSablonViewModel';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
@Injectable({
  providedIn: 'root'
})
export class ReportTemplateServices {

  constructor(private api: ApiService) { }
  //isExcel true geliyorsa excel false geliyorsa frx
  //isImzali true geliyorsa imzali excel false geliyorsa imzasız excel
  //tek basına frx indirilecekse isTumfrx false gönderilir ve frxId gönderilir
  //tüm frx ise isExcel false isTümfrx true gelir
  public DownloadFrxAndExcel(model: DownloadModel): Promise<any> {
    return this.api.callService("ReportTemplate/DownloadFrxAndExcel", model).then(o => {
      return this.api.fileOperation.download(o);
    });
  }

  public UploadFrxAndExcel(model: UploadModel): Promise<any> {
    return this.api.callService("ReportTemplate/UploadFrxAndExcel", model).then(o => {
      return o;
    });
  }

  public GetReportTemplateList(model:Grid.GridInputModel<DownloadModel>): Promise<Grid.GridOutputModel<TetkikRaporSablonViewModel>> {
    return this.api.callService("ReportTemplate/GetReportTemplateList", model).then(o => {
      var res = new Grid.GridOutputModel<TetkikRaporSablonViewModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
    });
  };
}
