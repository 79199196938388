export class DownloadModel {
  OsgbID: any;
  IsExcel: boolean;
  FrxID: any;
  IsImzali: boolean;
  IsTumFrx: boolean;
  LabID: any;
}

export class UploadModel {
  OsgbID: any;
  IsExcel: any;
  FrxID: any;
  IsImzali: any;
  IsTumFrx: any;
  FileID: any;
  LabID: any;
}
