import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-kullanicidetails',
  templateUrl: './kullanicidetails.component.html',
  styleUrls: ['./kullanicidetails.component.css']
})
export class KullanicidetailsComponent implements OnInit {
  _KullaniciID: any;
  @Input() set clickedKullaniciID(value: any) {
    if (value == undefined)
      return;
    this._KullaniciID = value;
  }
  constructor() { }

  ngOnInit() {
  }

}
