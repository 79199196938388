export class PersonelViewModel {
  ID: any;
  OsgbID: any;
  FirmaID: any;
  OsgbAdi: any;
  FirmaAdi: any;
  PersonelKimlikNo: any;
  TCKNo: any;
  PersonelSicilNo: any;
  PersonelAdi: any;
  PersonelSoyadi: any;
  Doktor: any;
  OdemeSekli: any;
  DogumTarihi: any;
  Cinsiyet: any;
  GonderiNo: any;
  FaturaDurumu: any;
  CepTel: any;
  Aktif: any;
  Kaydeden: any;
  KayitTarihi: any;
  Degistiren: any;
  DegTarihi: any;
  FatNak: any;
  BarkodNo: any;
  Aciklama: any;
}
