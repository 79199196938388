export class LabTetkikFilter {
  ID: any;
  TetkikID: any;
  AltTetkikKodu: any;
  AltTetkikAdi: any;
  AsagiSonuc: any;
  AralikSonuc: any;
  YukariSonuc: any;
  DegerMin: any;
  DegerMax: any;
  DegerDizi: any;
  Referans: any;
  Birimi: any;
  Aciklama: any;
  Aktif: any;
  Kaydeden: any;
  KaydedenTarihi: any;
  Degistiren: any;
  DegistirenTarihi: any;
}
