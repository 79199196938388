import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { FileSelectDirective, FileDropDirective, FileUploader } from 'ng2-file-upload';
import { NgClass, NgStyle } from '@angular/common';
import { ApiService } from 'src/app/Services/api.service';
import { environment } from 'src/app/shared/consts/environment';

const URL = 'http://e.medlifelab.com.tr:16001/api/FileOperation/Upload';

@Component({
  selector: 'ftp-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
})
export class UploadComponent implements OnInit {
  @Input() single: boolean;
  ngOnInit(): void {
    if(this.single==true){
    this.uploader.options.queueLimit=1;
  }
  }
  @Output() onFileUpload: EventEmitter<any> = new EventEmitter();


  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;

  constructor(private apiService: ApiService) {
    //https://valor-software.com/ng2-file-upload/
    //https://github.com/valor-software/ng2-file-upload
    this.uploader = new FileUploader({
      authTokenHeader: "Authorization",
      authToken: 'Bearer ' + apiService.authentication.token,
      url: environment.serverAddress + 'FileOperation/Upload',
      //disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      //formatDataFunctionIsAsync: true,
      //formatDataFunction: async (item) => {
      //  return new Promise((resolve, reject) => {
      //    resolve({
      //      name: item._file.name,
      //      length: item._file.size,
      //      contentType: item._file.type,
      //      date: new Date()
      //    });
      //  });
      //}
    });

    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.response = '';
    this.uploader.response.subscribe(res => {
      this.onFileUpload.emit(res);
      this.response = res;
    });

  }
  public fileDrop(e: any): void {

  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;

  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }


  public onConfirm(st: any) {


  }
  public onCancel(st: any) {


  }
}
