import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, ElementRef, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { PersonelTetkikIslemServices } from '../../../services/PersonelTetkikIslemService';
import { LaboratuvarViewModel } from '../../../models/LaboratuvarViewModel';
import { LaboratuvarSonucSaveFilter } from '../../../models/LaboratuvarSonucSaveFilter';
import { LabSonucSaveModel } from '../../../models/LabSonucSaveModel';

@Component({
  selector: 'app-personel-tetkik-lab-result',
  templateUrl: './personel-tetkik-lab-result.component.html',
  styleUrls: ['./personel-tetkik-lab-result.component.css']
})
export class PersonelTetkikLabResultComponent implements OnInit {
  tetkikID: any;
  _islemControl: any;
  yorum: string;
  data: LaboratuvarViewModel[];
  classinput: boolean;
  @Output() valueChange = new EventEmitter();
  @ViewChild('tetkik', { static: false }) tetkik: ElementRef;
  @ViewChildren('tetkik') parents: QueryList<any>;

  constructor(private apiservice: ApiService, private personeltetkikIslemService: PersonelTetkikIslemServices) {
  }
  yonOptions = [{ id: 0, name: "" }, { id: 1, name: ">" }, { id: 2, name: "<" }, { id: 3, name: ">=" }, { id: 4, name: "<=" }];
  ngOnInit() {


  }
  ngAfterViewInit() {
    setTimeout(() => {
      console.log("Tetkik ELmenet Focused",this.tetkik.nativeElement.focus());
    }, 100);
  }
  private _labTetkikResult; // private property _item
  public islem: any;

  // use getter setter to define the property
  get labTetkikResult(): any {
    return this._labTetkikResult;
  }
  @Input() set islemControl(val: any) {
    this.islem = val;
    console.log(this.islem);


  }
  @Input()
  set labTetkikResult(val: any) {

    this._labTetkikResult = val;
    const labSonucModel = new LaboratuvarSonucSaveFilter();
    // labSonucModel.PersonelID = this._labTetkikResult.PersonelID;
    // labSonucModel.TetkikGrubu = this._labTetkikResult.TetkikGrubu;
    labSonucModel.IslemID = this._labTetkikResult.IslemID;
    labSonucModel.LaboratuvarIslem = this._labTetkikResult.laboratuvarIslem;
    if (this.islem == 2) {
      this.personeltetkikIslemService.GetLabTetkikSonucList(labSonucModel).then((response: LaboratuvarViewModel[]) => {
        this.data = response;
        this.data.sort(function (a, b) {
          return ('' + a.LabTetkikAdi).localeCompare(b.LabTetkikAdi);         
        });
        this.ngAfterViewInit();
      })
    }

    else if (this.islem == 3) {
      this.personeltetkikIslemService.GetLabTetkikOnayList(labSonucModel).then((response: LaboratuvarViewModel[]) => {
        this.data = response;
        this.data.sort(function (a, b) {
          return ('' + a.LabTetkikAdi).localeCompare(b.LabTetkikAdi);
        });
        this.ngAfterViewInit();
      })
    }

  }
  onkeyPress($event, index) {
    this.data[index].Sonuc = $event.target.value.replace(/,/g, '.');
    if ($event.keyCode != 13)
      return;
    let items = this.parents.map(o => o.nativeElement);
    var ix = items.indexOf($event.currentTarget);
    if (ix == -1 || (ix + 1) == items.length) {
      return;
    }

    items[ix + 1].focus();

  }
  getPatolojiClass(index) {
    let item = this.data[index]
    if (item.Tip == 1) {
      if (item.Sonuc < item.DegerMin || item.Sonuc > item.DegerMax)
        return "bg-danger text-white"
    } else if (item.Tip == 2) {
      if (item.Sonuc != item.DegerYazi)
        return "bg-danger text-white"

    }
  }

  onSave() {
    const saveModel = new LabSonucSaveModel();
    saveModel.Yorum = this.yorum;
    saveModel.list = this.data;


    this.personeltetkikIslemService.SaveLabSonuc(saveModel).then((response: boolean) => {
      console.log(response)
      this.valueChange.emit();

      // this.data = [];

    }, (err) => {
      console.log('hata var');
    })



  }
}
