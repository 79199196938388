import { Component, OnInit, ViewChild } from '@angular/core';
import { PaketlistComponent } from './paketlist/paketlist.component';

@Component({
  selector: 'app-paket',
  templateUrl: './paket.component.html',
  styleUrls: ['./paket.component.css']
})
export class PaketComponent implements OnInit {

  @ViewChild(PaketlistComponent, { static: false })
  public paketListComponent: PaketlistComponent;

  constructor() { }

  ngOnInit() {
  }

}
