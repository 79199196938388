import { Component, OnInit } from '@angular/core';
import { Grid } from '../../../../../shared/models/UIControls/grid-control';
import { PaketTanimlamaServices } from '../../services/PaketTanimlamaService';
import { TetkikEkleViewFilter } from '../../models/TetkikEkleViewFilter';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { PaketTetkikSaveModel } from '../../models/PaketTetkikSaveModel';
import { PaketTetkikFilter } from '../../models/PaketTetkikFilter';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-add-tetkiktopaket',
  templateUrl: './add-tetkiktopaket.component.html',
  styleUrls: ['./add-tetkiktopaket.component.css']
})
export class AddTetkiktopaketComponent implements OnInit {

  public onClose: Subject<string>;
  public paketTetkikSaveModel: PaketTetkikSaveModel = new PaketTetkikSaveModel()
  public paketID: any;
  public totalPacketPrice :number;
   totalTetkik:any[]=[];
   clickedItem:any;
  constructor(private paketServices: PaketTanimlamaServices, public bsModalRef: BsModalRef) { }
  
   filter = new Grid.GridInputModel(new PaketTetkikListFilter());
  ngOnInit() {
  

  
    this.onClose = new Subject();
    this.gridTetkik.onRefresh();
    this.filter.filter.PaketID=this.paketID;
  
    this.paketServices.GetPaketTetkikUcretListById(this.filter.filter).then(o=>{
     console.log(o)
    
      //    for (let i = 0; i < o.length; i++) {
      //      o[i].Id=o[i].TetkikId;
      //      this.totalPacketPrice=this.totalPacketPrice+o[i].OzelUcret
      //     this.totalTetkik.push(o[i])
      //    debugger;
      // }
      //   for (let i = 0; i < o.list.length; i++) {
      //    this.totalTetkik[i].TetkikAdi=o.list[i].TetkikAdi;
      //    this.totalTetkik[i].Id=o.list[i].TetkikId;
      //    this.totalTetkik[i].Ucret=o.list[i].OzelUcret;
      //    debugger;
      // }
     this.totalTetkik = o.map((item) => {
     
          item.Id=item.TetkikId
          debugger;
       return item;
     });
   
    })
 
    // for (let i = 0; i < this.totalTetkik.length; i++) {
    //   this.totalPacketPrice=this.totalPacketPrice+this.totalTetkik[i].OzelUcret;
    //  }
  }
  tetkikFilter: TetkikPaketFilter = new TetkikPaketFilter();

  gridTetkik: TetkikListComponent_Models.GridTetkik = new TetkikListComponent_Models.GridTetkik(this.paketServices, this.tetkikFilter);
  onFilterInputChange(event) {

    this.gridTetkik.onRefresh();
  }
  onClicktotalTetkik(item:any){
    this.clickedItem = item;
  }
  onConfirm(st: any) {
    debugger;
    this.paketTetkikSaveModel.PaketId = this.paketID;
    this.paketTetkikSaveModel.TetkikIdList = this.totalTetkik.map(o=>o.Id)
    this.paketTetkikSaveModel.UcretList=this.totalTetkik.map(o=>o.OzelUcret)
    this.paketServices.SavePaketTetkik(this.paketTetkikSaveModel).then(o => {
      this.onClose.next("confirm");
      this.bsModalRef.hide();
    });
  }
  getRowClass(item: any) {
 
      if (this.clickedItem == item)
        return 'table-info';
      return ''
    
  };
  onAddTetkik(){
    //  this.totalTetkik= this.gridTetkik.selectedItems.map( (o) => {
    //    o.Ucret=0;
    //   return o
    // } )
    //  console.log(this.totalTetkik)

    this.totalTetkik.push(this.gridTetkik.clickedItem)
    console.log(this.totalTetkik)

  }
  totalPacketPriceChange(){
  
  console.log( this.totalPacketPrice / this.totalTetkik.length)
  let ortPrice:number=this.totalPacketPrice / this.totalTetkik.length
  for (let i = 0; i < this.totalTetkik.length; i++) {
   this.totalTetkik[i].OzelUcret=ortPrice;
  }
  }

  examinationPriceChange(){
    this.totalPacketPrice=0;
    for (let i = 0; i < this.totalTetkik.length; i++) {
      this.totalPacketPrice=this.totalPacketPrice+this.totalTetkik[i].OzelUcret;
     }
  }
  onDeleteTetkik(key){
   
    const index = this.totalTetkik.indexOf(key, 0);
    if (index > -1) {
      this.totalTetkik.splice(index, 1);
    }
  }
  onCancel(st: any) {
    this.onClose.next("cancel");
    this.bsModalRef.hide();
  }
}
export class TetkikPaketFilter {
  Id: any;
  TetkikGrubu: any;
  TetkikAdi: any;
  OzelUcret: any;
  OrnekTuru: any;
  DizaynAdi: any;
  GozMu: any;
}
export class PaketTetkikListFilter {
  Id: any=null;
  TetkikId: any=null;
  TetkikAdi: any=null;
  OzelUcret: any=null;
  PaketID: any=null;
}


namespace TetkikListComponent_Models {

  export class GridTetkik extends Grid.GridControl<any> {
    public direction: number = 1;
    constructor(private paketServices: PaketTanimlamaServices, private tetkikFilter: TetkikPaketFilter) {
      super();
    }
    filter = new Grid.GridInputModel(new TetkikEkleViewFilter());
    getFilter() {

      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;

      let item = this.filter.filter;
      var f = this.tetkikFilter;
      item.Id = f.Id;
      item.TetkikGrubu = f.TetkikGrubu;
      item.TetkikAdi = f.TetkikAdi;
      item.OrnekTuru = f.OrnekTuru;
      item.DizaynAdi = f.DizaynAdi;
      item.GozMu = f.GozMu;
     

      return this.filter;
    };
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    onRefresh() {
      var item = this.getFilter()
      console.log(item);
      var filter = item.filter;
      this.paketServices.GetTetkikList(item).then(o => {
        console.log(o);
        this.data = o;
      });
    }
  }

}
