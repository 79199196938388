import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class NotificationToastService {

  constructor(private toastr: ToastrService) {

  }
  public success(message: string) {
    this.toastr.success(message);
  }
  public error(message: string) {
    this.toastr.error(message);
  }
  
}
