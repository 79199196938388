import { Component, OnInit, Input } from '@angular/core';
import { PaketDetayModel } from '../../../../models/PaketDetayModel';
import { PaketTanimlamaServices } from '../../../../services/PaketTanimlamaService';

@Component({
  selector: 'app-paketdetailtab',
  templateUrl: './paketdetailtab.component.html',
  styleUrls: ['./paketdetailtab.component.css']
})
export class PaketdetailtabComponent implements OnInit {
  paketDetailModel: any;
  @Input() set paketId(value: any) {
    if (value == undefined)
      return;
    let model = new PaketDetayModel();
    model.ID = value
    this.paketServices.GetPaketByID(model).then(o => {
      console.log(o);
      this.paketDetailModel = o;
      console.log(this.paketDetailModel);
    })
  }
  constructor(private paketServices: PaketTanimlamaServices) { }

  ngOnInit() {
  }

}
