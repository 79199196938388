import { Injectable } from '@angular/core';
import { AuthenticationService } from '../shared/services/Data/AuthenticationService';
import { PermissionViewModel } from '../shared/models/PermissionViewModel';
@Injectable({
  providedIn: 'root'
})
export class authService {
  
 
  constructor(private userService: AuthenticationService) {
    
  }
  
  get Ready():  Promise<PermissionViewModel> {
    return  this.userService.getPermission();
}
  public isLoggedIn(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getPermission()
        .then(o => {
          let st = o == undefined ? false : true;
          return st;
        })
        .catch(error => {
          return false
        })
        .then(o => {
          resolve(o)
        });

    });

  }


}
