export class TetkikResultFilter {
  OsgbAdi: any;
  FirmaAdi: any;
  PersonelAdi: any;
  PersonelSicilNo: any;
  PersonelTc: any;
  PersonelDogumTarihi: any;
  Cinsiyet: any;
  TetkikGrubu: any;
  TetkikID: any;
  TetkikAdi: any;
  IslemID: any;
  PersonelID: any;
  LabTetkikMi: any;
  GozMu: any;
  Barkod: any;
  TetkikTuru: any;
  Durum: any;
  Sonuc: any;
  DosyaAdi: any;
  KayitTarihi: any;
  OnayTarihi: any;
  Onaylayan: any;
  ImzaTarihi: any;
  BasTarih: any;
  BitTarih: any;
  Islem: any;
  Yazdirilanlar: any;
  Reddedilenler: any;
  DosyaID: any;
  TetkikAdiList: any;
  DurumIDList: any;
  LaboratuvarIslem: any;
  Dislab: any;
  LabDurumu: any;
  IslemIDList: any[];
  PdfMi: any;
  ItemList: any[];
  TetkikGrubuID: any;
  OsgbIDList: any[];
  OsgbID: any;
  IsPatolojik: any;
}
