import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
import { IslemListInput } from '../models/IslemListInput';
import { IslemListResult } from '../models/IslemListResult';
@Injectable({
  providedIn: 'root'
})
export class IslemRaporServices {

  constructor(private api: ApiService) { }
  
  public getIslemList(model: Grid.GridInputModel<IslemListInput>): Promise<Grid.GridOutputModel<IslemListResult>> {

    return this.api.callService("IslemRaporu/GetIslemList", model).then(o => {
      var res = new Grid.GridOutputModel<IslemListResult>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
      //resolve(res);
    });
  };

  public exportIslemRaporExcel(model: Grid.GridInputModel<IslemListInput>): Promise<any> {

    return this.api.callService("IslemRaporu/ExportExcelIslemList", model).then(o => {
      this.api.fileOperation.download(o);
    });
  }

}
