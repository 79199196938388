import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-paketdetails',
  templateUrl: './paketdetails.component.html',
  styleUrls: ['./paketdetails.component.css']
})
export class PaketdetailsComponent implements OnInit {

  _PaketID: any;
  @Input() set clickedPaketID(value: any) {
    if (value == undefined)
      return;
    this._PaketID = value;
  }

  constructor() { }

  ngOnInit() {
  }

}
