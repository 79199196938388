import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { switchMap, catchError, map } from 'rxjs/operators';
import { DataManagerService } from 'src/app/Services/dataManager/data-manager.service';

@Component({
  selector: 'app-save-pl-byexcel',
  templateUrl: './save-pl-byexcel.component.html',
  styleUrls: ['./save-pl-byexcel.component.css']
})
export class SavePlByexcelComponent implements OnInit {

  public getDoctorsListUrl: string = "Personel/GetDoctorList";
  public savePersonelByExcelUrl: string = "Personel/SaveByExcel";
  public saveExcelFile: string = "FileOperation/Upload";
  public getExcelDataUrl: string = "Personel/GetExcelData";
  public ui: any = {
    columns: []
  };
  public model: any = {
    firmaID: undefined,
    doktor: undefined,
    odemeSekli: undefined,
    sheet: 1,
    tetkikTuru: undefined,
    dict: {
      adSoyadColumnNumber: undefined,
      TCColumnNumber: undefined,
      personelSicilNoNumber: undefined,
      dogumTarihiNumber: undefined,
      cinsiyetNumber: undefined,
      AdresNumber :undefined,
      CepNoNumber :undefined,
      PasaportNumber:undefined
    },
    excelFileID: undefined
  };

  public selectedFile: any = {
    name: "Dosya seçin"
  };

  public disableUpload: boolean = true;

  public doctorsList: any = undefined;

  public personelList: any[] = [];

  public showAlert: boolean = false;


  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService, private datamanager: DataManagerService, private modalService: BsModalService) { }

  ngOnInit() {
    this.model.firmaID = this.datamanager.getFirmaID();
    this.apiservice.callPostService(this.getDoctorsListUrl, {}).pipe(
      map(
        (resp: any) => {
          if (resp !== undefined && 'Data' in resp) {
            this.doctorsList = resp.Data;
          }
        }
      ),
      catchError(err => {
        console.log(err);
        return undefined;
      })
    ).subscribe();
  }

  public savePersonel() {
    // console.log(this.model);

    if (this.model.tetkikTuru !== undefined && this.model.dict.dogumTarihiNumber!== undefined && this.model.dict.adSoyadColumnNumber!== undefined) {
      var ar = []
      for (var key in this.personelList) {
        var value = this.personelList[key];
        if (ar.indexOf(value) != -1) {
          alert("zaten var...");
          return;
        }
        ar.push(value);
      }
      this.apiservice.callPostService(this.savePersonelByExcelUrl, this.model).subscribe(
        (resp) => {
          debugger;
          this.showAlert = false;
          this.modalService.setDismissReason('save-personel');
          this.bsModalRef.hide();
        }
      );
    } else {
      this.showAlert = true;
    }
  }

  public handleFileChoose(event) {

    this.selectedFile = event.target.files[0];
    this.disableUpload = false;
  }

  getExcelColumns = (excelData) => {

    let arr = []
    if (!excelData || !excelData[0]) {
      return arr;
    }
    let item = excelData[0];
    excelData.splice(0, 1);

    for (var k in item) {
      var colName = item[k];
      if (!colName) continue;
      arr.push(colName);
    }
    return arr;
  }

  onUploadFile() {
    this.apiservice.callFormDataService(this.saveExcelFile, [this.selectedFile]).pipe(
      switchMap(
        (resp: any) => {

          this.model.excelFileID = resp.Data[0];
          return this.apiservice.callPostService(this.getExcelDataUrl, { excelFileID: this.model.excelFileID })
        }
      ),
      map(
        (resp: any) => {
          // console.log(resp);
          this.ui.columns = [];
          this.personelList = resp.Data;
          this.ui.columns = this.getExcelColumns(this.personelList);
        }
      )
    ).subscribe();

  }

}
