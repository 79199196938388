import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { noop, Observable, Observer, of, from } from 'rxjs';
import { map, switchMap, tap, debounce, debounceTime } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { Settings } from 'http2';
export class AutocompleteModel {
  id: any;
  Name: string;
  tcno:any;
  displayText:string;
}
interface IAutoCompleteSettings {
  onDataService(text: string): () => AutocompleteModel[];
  single:boolean
}
export class AutoCompleteSettings implements IAutoCompleteSettings {
  onDataService;
  single=false;
}
@Component({
  selector: 'input-autocomlete',
  templateUrl: './input-autocomlete.component.html',
  styleUrls: ['./input-autocomlete.component.css']
})
export class InputAutocomleteComponent   {
  @Output() onSelectChange: EventEmitter<any> = new EventEmitter();
  @Output() deleteSelecteditem: EventEmitter<any> = new EventEmitter();
  @ViewChild('focusInput', { static: false }) focusInput: ElementRef;

  search: string;
  suggestions$: Observable<{}>;
  errorMessage: string;
  selectedOption: any;
  abc: any;
  selectedItems: AutocompleteModel[] = [];
  @Input() settings: AutoCompleteSettings;

  constructor() {
  }
  ngOnInit(): void {

    this.suggestions$ = new Observable((observer: Observer<string>) => {
      this.onSelect(new TypeaheadMatch(undefined));
      observer.next(this.search);
    }).pipe(debounceTime(500))
      .pipe(
        switchMap((query: string) => {
          if (query) {
            return this.settings.onDataService(query);
          }

          return of([]);
        })
      );
  }
  onFocus(){
    setTimeout(() => {
      console.log(this.focusInput.nativeElement.focus());
    },100);
  }

  onSelect(event: TypeaheadMatch): void {
    if (this.selectedOption == event.item)
      return;
    this.selectedOption = event.item;
    if (!event.item)
      return;
    let item = this.selectedOption as AutocompleteModel;
    // this.selectedItems.length=0;
    this.addItem(item);
    this.search = "";
    this.onSelectChange.emit(this.selectedItems);
  }
  addItem(item: AutocompleteModel) {
    var items = this.selectedItems.filter(o => o.id == item.id);
    if (items.length)
      return;
      if(this.settings.single==true){
        this.selectedItems=[];
        this.selectedItems.push(item);
      }
      else{
        this.selectedItems.push(item);
      }
    
  }
  deleteItem(item: AutocompleteModel) {
    const index = this.selectedItems.indexOf(item);
    if (index > -1) {
      this.selectedItems.splice(index, 1);
    }
  }

  onDelete(item: AutocompleteModel) {
  
    this.deleteItem(item);
    this.deleteSelecteditem.emit(item);
  }
}

