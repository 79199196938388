export class PersonelViewFilter {
  Id: any;
  OsgbId: any;
  FirmaId: any;
  OsgbIdList: any[];
  FirmaAdi: any;
  OsgbAdi: any;
  KimlikNo: any;
  TCKNo: any;
  PersonelSicilNo: any;
  PersonelAdi: any;
  DogumTarihi: any;
  Cinsiyet: any;
  GonderiNo: any;
  FaturaDurumu: any;
  CepTel: any;
  Aktif: any;
  Kaydeden: any;
  BasTarih: any;
  BitTarih: any;
  Degistiren: any;
  DegTarihi: any;
  FatNak: any;
  TumTarihtekiTetkikler: any;
  IsUpdated: any;
  BarkodNo: any;
  PersonelIDList: any[];  
  TetkikGrubuList: any[];
  IsBarkod: any;
  Durum: any;
  TetkikIDList: any;
  DepartmentID: any;
  LabID: any;
  TetkikDurum:any;
  IslemIDList: any[];
  BarkodlayanUserIDList: any[];
  OnaylayanUserIDList: any[];
}
