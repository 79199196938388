import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from '../../../Services/api.service';

@Component({
  selector: 'app-confirmation-dialog-rejection',
  templateUrl: './confirmation-dialog-rejection.component.html',
  styleUrls: ['./confirmation-dialog-rejection.component.css']
})
export class ConfirmationDialogRejectionComponent implements OnInit {
  public onClose: Subject<any>;
  public inputLabel: string;
  public message: string;
  public showMail:boolean=false;
  public islemId:any;
  public dosyaId:any;
  public mesaj:any;
  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService, private modalService: BsModalService) { }

  ngOnInit() {
    this.onClose = new Subject();
  }
  public onConfirm(st: any) {
    const output = {
      message: this.message,
      event: "confirm"
    };
    this.onClose.next(output);
    this.bsModalRef.hide();
  }
  showMailForm(){
    console.log(this.showMail)
    console.log(this.islemId)
    console.log(this.dosyaId)
  }
  public onCancel(st: any) {
    const output = {
      message: "",
      event: "cancel"
    };
    this.onClose.next(output);
    this.bsModalRef.hide();
  }
 

}
