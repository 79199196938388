import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'confirmation-dialog.component',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {


  public onClose: Subject<string>;
  public message:any;

  constructor(public bsModalRef: BsModalRef,private apiservice : ApiService,private modalService: BsModalService) { }

  ngOnInit() {
    // console.log(this.personelIDList);
    this.onClose = new Subject();
  }
  public onConfirm(st:any)
  {
    this.onClose.next("confirm");
    this.bsModalRef.hide();
  }
  public onCancel(st:any)
  {
    this.onClose.next("cancel");
    this.bsModalRef.hide();
  }
}
