import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { BsModalService } from 'ngx-bootstrap';
import { DataManagerService } from 'src/app/Services/dataManager/data-manager.service';
import { Observable } from 'rxjs';
import { PersonelTetkikServices } from '../../../services/PersonelTetkikService';
import { PermissionViewModel } from '../../../../../../shared/models/PermissionViewModel';
import { ModalManagerService } from 'src/app/Services/modal-manager.service';
import { authService } from 'src/app/Services/authService';
import { ConfirmationDialogComponent } from '../../../../../../shared/modals/confirmation-dialog/confirmation-dialog.component';
import { ExamResultEyeDialogComponent } from '../../../modals/examResult/exam-result-eye-dialog/exam-result-eye-dialog.component';
import { ExamResultOdioDialogComponent } from '../../../modals/examResult/exam-result-odio-dialog/exam-result-odio-dialog.component';
import { ExamResultFileDialogComponent } from '../../../modals/examResult/exam-result-file-dialog/exam-result-file-dialog.component';
import { TetkikResultModel } from '../../../models/TetkikResultModel';
import { RaporModel } from '../../../models/RaporModel';
import { DeleteModel } from '../../../models/DeleteModel';
import { ExamResultLabDialogComponent } from '../../../modals/examResult/exam-result-lab-dialog/exam-result-lab-dialog/exam-result-lab-dialog.component';
import { GetpaymentComponent } from '../../../modals/getpayment/getpayment.component';
import { ReturnpaymentComponent } from '../../../modals/returnpayment/returnpayment.component';
import { ExaminationsComponent } from '../../../modals/examinations/examinations.component';
import { ToastrService } from 'ngx-toastr';
import { Grid } from 'src/app/shared/models/UIControls/grid-control';
import { DeleteByPersonelIDListAndTetkikID } from '../../../models/DeleteByPersonelIDListAndTetkikID';
import { RaporResultItemlistModalComponent } from '../../../modals/rapor-result-itemlist-modal/rapor-result-itemlist-modal.component';
import { TetkikIDListModel } from '../../../models/IslemIDListModel';
import { SaveTopluLaboratuvarSonucModel } from '../../../models/SaveTopluLaboratuvarSonucModel';
import { HbysBarkodModalComponent } from '../../../modals/hbys-barkod-modal/hbys-barkod-modal.component';
@Component({
  selector: 'app-personel-examinations',
  templateUrl: './personel-examinations.component.html',
  styleUrls: ['./personel-examinations.component.css']
})
export class PersonelExaminationsComponent implements OnInit {

  idList: any[] = [];


  @Input() firmaevents: EventEmitter<any>;
  tetkikResult: TetkikResultModel = new TetkikResultModel();
  permission: PermissionViewModel = new PermissionViewModel();
  saveTopluLaboratuvarSonucModel:SaveTopluLaboratuvarSonucModel=new SaveTopluLaboratuvarSonucModel();
  public direction: number = 1;
  constructor(
    private apiservice: ApiService,
    private datamanager: DataManagerService,
    private modalService: BsModalService,
    private personelTetkikService: PersonelTetkikServices,
    private modalNotService: ModalManagerService,
    private authService: authService,
    private toastr: ToastrService) {
    this.authService.Ready.then(o => {
      this.permission = o;
    });
  }

  ngOnInit() {
    this.personelTetkikGrid.model.Filter['IsDeleted'] = false;
    this.firmaevents.subscribe(
      (resp: any) => {
        console.log(resp);
        if (resp == 'personel-item-selected') {
          this.personelTetkikGrid.selectedItem = undefined;
          this.personelTetkikGrid.model.Filter['isUpdated'] = false;
        
          this.personelTetkikGrid.model.Filter['GonderiPersonelID'] = this.datamanager.getPersonelID();
          // this.personelTetkikGrid.model.Filter['BasTarih']=this.datamanager.getBasTarih();
          this.personelTetkikGrid.onRefresh();
          console.log(this.datamanager.BasTarih + "Bas tarihi")
          console.log(this.datamanager.BitTarih + "bitiş tarihi")
        }
        else if (resp == 'refresh-Personel-tekik') {
          this.personelTetkikGrid.selectedItem = undefined;
          this.personelTetkikGrid.onRefresh();
        }

        else if (resp == 'firma-item-selected') {

          this.personelTetkikGrid.clearFilterState();
        }


      }
    )
  }

  public getPersonelTekikListUrl: string = "PersonelTetkik/GetTetkikListByPersonelId";

  public personelTetkikGrid: any = {
    clickedItem: undefined,
    selectedItems: [] = [],
    pager: [50,100],
    totalCount: 20,
    data: [],
    model: {
      Filter: {},
      Paging: {
        PageNumber: 1,
        Count: 10
      },
      Sorting: {},
      Pager: [] = [10, 25, 50]
    },
    clearFilterState: () => {
      var t = this.personelTetkikGrid;
      t.model.Paging.PageNumber = this.personelTetkikGrid.model.Paging.PageNumber;
      t.model.Paging.Count = this.personelTetkikGrid.model.Paging.Count;
      t.clickedItem = undefined;
      t.selectedItems.length = 0;
      t.data = [];
    },
    onSorting: (colName) => {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }

      this.personelTetkikGrid.model.Sorting = { colName: colName, direction: this.direction };
      this.personelTetkikGrid.onRefresh();
    },
    hasSelectedItem: () => {
      return this.personelTetkikGrid.selectedItems.length > 0
    },
    hasClickedItem: () => {
      return this.personelTetkikGrid.clickedItem != undefined;
    },
    isSelected: (item: any) => {
      return this.personelTetkikGrid.selectedItems.some(o => o == item);
    },

    onSelectAll: (event: any) => {
      if (!event.srcElement.checked) {
        this.personelTetkikGrid.selectedItems.length = 0;
        return;
      }
      this.personelTetkikGrid.selectedItems.length = 0;
      this.personelTetkikGrid.data.forEach(o => {
        this.personelTetkikGrid.onSelectItem(o);
      });
    },
    onSelectItem: (item: any) => {
      if (this.personelTetkikGrid.isSelected(item)) {
        var ar = this.personelTetkikGrid.selectedItems;
        var index = ar.indexOf(item);
        if (index > -1) {
          ar.splice(index, 1);
        }
        return;
      }
      this.personelTetkikGrid.selectedItems.push(item);
    },
    onOdemeAl: () => {
      this.idList = [];
      if (!this.personelTetkikGrid.hasSelectedItem())
        return;

      this.personelTetkikGrid.selectedItems.forEach(item => {
        if (item.CariHareketNo == " ") {
          this.idList.push(item.Id);
          console.log(item);
        }
      });

      if (this.idList.length == 0) {
        return;
      }

      const initialState = {
        islemIdList: this.idList
      };

      let instance = this.modalService.show(GetpaymentComponent, { initialState, class: 'modal-lg',ignoreBackdropClick:true });
      instance.content.onClose.subscribe(
        response => {
          if (response == "confirm") {
            this.personelTetkikGrid.onRefresh();
          }

        }
      );
    },
    onIadeEt: () => {
      this.idList = [];
      if (!this.personelTetkikGrid.hasSelectedItem())
        return;

      this.personelTetkikGrid.selectedItems.forEach(item => {
        if (item.CariHareketNo == "OK") {
          this.idList.push(item.Id);
        }
      });
      if (this.idList.length == 0) {
        return;
      }
      const initialState = {
        islemIdList: this.idList
      };

      let instance = this.modalService.show(ReturnpaymentComponent, { initialState, class: 'modal-lg',ignoreBackdropClick:true });
      instance.content.onClose.subscribe(
        response => {
          if (response == "confirm") {
            this.personelTetkikGrid.onRefresh();
          }
        }
      );
    },
    onBarkodTetkik: () => {
      let gd = this.personelTetkikGrid
      if (!gd.hasSelectedItem())
        return;

      // let idList = gd.selectedItems.map(o => o.Id);
      // console.log(idList)
      // this.data.GetBarkodByTetkikIDList(idList).subscribe(o => {


        var model=gd.model;
        model.Filter.TetkikIDList=gd.selectedItems.map(o => o.Id);
        model.Sorting=this.personelTetkikGrid.model.Sorting;
     
        this.personelTetkikService.GetBarkodByIslemID(model).then(o => {
        if (!o )
          return;
        this.apiservice.fileOperation.download(o);
        this.personelTetkikGrid.onRefresh();

      });
    },
    onLabKabul:()=>{
      this.saveTopluLaboratuvarSonucModel.IslemIDList=this.personelTetkikGrid.selectedItems.map(o => o.Id);
     
      //this.personelTetkikService.SaveLaboratuvaraGiris(this.saveTopluLaboratuvarSonucModel).then(o=>{
       // this.personelTetkikGrid.onRefresh();
     // })

    },
    onHBYSBarkod:()=>{
    
      this.idList = [];
    

      this.personelTetkikGrid.selectedItems.forEach(item => {

          this.idList.push(item.Id);
      });
      if (this.idList.length == 0) {
        return;
      }
      const initialState = {
        islemIdList: this.idList
      }
      let modal=this.modalService.show(HbysBarkodModalComponent,{initialState,ignoreBackdropClick:true});
    },
    onDelete: () => {
      if (!this.personelTetkikGrid.hasSelectedItem())
        return;
      var idList = this.personelTetkikGrid.selectedItems.map(o => o.Id);

      const initialState = {
        message: "Seçili tetkikleri silmek istediğinizden emin misiniz?" + idList.join(",")
      };
      let instance = this.modalService.show(ConfirmationDialogComponent, { initialState,ignoreBackdropClick:true });
      instance.content.onClose.subscribe(
        response => {
          if (response != "confirm")
            return;
          var md = new TetkikIDListModel();
          md.TetkikIDList = idList;
          this.personelTetkikService.DeleteByTetkikList(md).then(o => {
            this.personelTetkikGrid.clearFilterState();
            this.personelTetkikGrid.onRefresh();
          });

        }
      );
    },
    onTetkikOnayKaldir: () => {
      if (!this.personelTetkikGrid.hasSelectedItem())
        return;
      let idList = this.personelTetkikGrid.selectedItems.map(o => o.Id);
      this.tetkikResult.islemIDList = idList;

      this.personelTetkikService.GetTekrarOnayIste(this.tetkikResult).then(o => {
        this.personelTetkikGrid.onRefresh();
      });

    },
    onClickItem: (item) => {
      this.personelTetkikGrid.clickedItem = item;
    },
    onExportResult: (select) => {
  
      console.log(select);
      if (!this.personelTetkikGrid.hasSelectedItem())
        return;
      let idList: any[] = []
      for (let i = 0; i < this.personelTetkikGrid.selectedItems.length; i++) {
        if (this.personelTetkikGrid.selectedItems[i].TabloNo == "Yaz" || this.personelTetkikGrid.selectedItems[i].TabloNo == "Arsiv")
          idList.push(this.personelTetkikGrid.selectedItems[i].Id)
      }
      //  var idList = this.personelTetkikGrid.selectedItems.map(o => o.Id);
      if (idList.length == 0) {
        console.log("seçili Id listesi yok alan adı değişmiş olabilir IID??");
        return;
      }
      //   filter.idList=gd.selectedItems.map(o => o.Id);
      var model=this.personelTetkikGrid.model;
      var r = this.personelTetkikGrid.model.Filter;
      r.TetkikIDList = idList;
      model.Filter.TetkikIDList = idList;
      
      if (select == 1){
        model.Filter.PdfMi = true;
        this.personelTetkikService.RaporResult(model);
      }
      else{
      
        let itemList:[]=[]
        const initialState={itemList:itemList}
        let modal=this.modalService.show(RaporResultItemlistModalComponent,{initialState,ignoreBackdropClick:true});
        modal.content.onClose.subscribe(res=>{
          model.Filter.PdfMi = false;
        
          model.Filter.ItemList=res;
        this.personelTetkikService.RaporResult(model);
        })
        
      }
     

    },
    canView_AddFileOrResult: () => {
     
      if (!this.personelTetkikGrid.hasClickedItem())
        return false;
      let item = this.personelTetkikGrid.clickedItem;
      if ([3, 11, 1, 2, 5, 9, 10, 14, 215, 405, 375, 187, 186, 185, 184, 8, 144, 217, 54, 525].includes(item.TetkikID)) {
        return true;
      }
      return false;
    },
    onDeleteTetkikFromAll: () => {
      if (!this.personelTetkikGrid.hasClickedItem())
        return false;
      let item = this.personelTetkikGrid.clickedItem;
      var tetkikID = item.Id;
      this.modalNotService.openConfirmation("Tüm personellerden Tetkik:" + tetkikID + " silinecek").subscribe(o => {
        if (o != "confirm")
          return;
        var t = new DeleteModel();
        t.tetkikID = tetkikID;
        t.basTar=this.datamanager.BasTarih;
        t.bitTar=this.datamanager.BitTarih;
        this.personelTetkikService.Delete(t).then(o => {
          this.personelTetkikGrid.onRefresh();
        });
      });
    },
    onDeleteTetkikSelectedemployees: () => {
      if (!this.personelTetkikGrid.hasClickedItem())
        return false;
      let item = this.personelTetkikGrid.clickedItem;
 
      let personelID: any[] = this.datamanager.getPersonelIDList();
      this.modalNotService.openConfirmation("Seçili Personellerden: " + item.TetkikID + " silinecek").subscribe(o => {
        if (o != "confirm")
          return;
        var t = new DeleteByPersonelIDListAndTetkikID();
        t.TetkikID = item.TetkikID;
        t.PersonelIDList=personelID.map(o => o.ID);
        console.log(t.PersonelIDList)
      
        this.personelTetkikService.DeleteBypersonelIDListAndTetkikID(t).then(o => {
          this.personelTetkikGrid.onRefresh();
        });
      });
    },
    onOpenExaminations: () => {
      let firmaID = this.datamanager.getFirmaID()

      let personelID: any[] = this.datamanager.getPersonelIDList();
      let personelIDList: any[] = [];
 
      if(this.datamanager.getPersonelIDList()==undefined)
      return this.toastr.error("Lütfen Personel seçiniz !")
      personelIDList = personelID.map(o => o.ID);
    
      let changeControl = 1
      var filter = this.personelTetkikGrid.model.Filter;
      let item = this.personelTetkikGrid.clickedItem;
      filter.BasTarih = this.datamanager.BasTarih;
      filter.BitTarih = this.datamanager.BitTarih;
    
      let modal = this.modalService.show(ExaminationsComponent, { class: 'modal-lg',ignoreBackdropClick:true });
      modal.content.state.init(personelIDList, firmaID, filter.BasTarih, filter.BitTarih, changeControl, item.TetkikID)
      modal.content.onClose.subscribe(
        response => {

          if (response !== null && response == "save-examination") {
            this.firmaevents.emit('refresh-Personel-tekik');
          } this.personelTetkikGrid.onRefresh();
        }, err => {
          console.log(err);
        }
      );
    },
    onAddFileOrResult: () => {
      if (!this.personelTetkikGrid.hasClickedItem())
        return;
      let item = this.personelTetkikGrid.clickedItem;
      if (item.Yapildi != "ok") {
        var arr = [1, 2, 5, 9, 10, 14, 215];
        var lab = [405, 375, 187, 186, 185, 184, 8, 144, 217, 54, 525];
        const initialState = {
          dialogParams: { tetkikID: item.Id }
        };
        if (item.TetkikID == 3) {
          let instance = this.modalService.show(ExamResultEyeDialogComponent, { initialState,ignoreBackdropClick:true });
          instance.content.eventClose.subscribe(o => {
            this.personelTetkikGrid.onRefresh();
          })
        }
        else if (item.TetkikID == 11) {
          let instance = this.modalService.show(ExamResultOdioDialogComponent, { initialState,ignoreBackdropClick:true });
          instance.content.eventClose.subscribe(o => {
            this.personelTetkikGrid.onRefresh();
          })
        }

        else if (arr.some(o => o == item.TetkikID)) {
          let instance = this.modalService.show(ExamResultFileDialogComponent, { initialState,ignoreBackdropClick:true });
          instance.content.eventClose.subscribe(o => {
            this.personelTetkikGrid.onRefresh();
          })
        }
        else if (lab.some(o => o == item.TetkikID)) {

          let instance = this.modalService.show(ExamResultLabDialogComponent, { initialState,ignoreBackdropClick:true });
          instance.content.eventClose.subscribe(o => {
            this.personelTetkikGrid.onRefresh();
          })
        }
        else {

        }

      }
    },
    getRowClass: (item: any) => {
      
      var list = [];
      if (item.Sonuc != "" || item.Dosya != "") {
        if (item.Red != "") {
          list.push("table-style1");
        }
        else if (item.TabloNo == "Onay") {
          list.push("table-style3");
        }
        else if (item.Red == "" && item.Yapildi != "") {
          list.push("table-style2");
        }
        else if (item.Red == "" && item.Yapildi == "") {
          list.push("table-style3");
        } 
      }
      // if(list.length==0){
      //   if(item.TetkikTuru == "Günlük"){
      //     list.push("table-style4");
      //   }
      //   else if(item.TetkikTuru == "Tarama"){
      //     list.push("table-style5");
      //   }
      // }
      if (this.personelTetkikGrid.isSelected(item)) {
        list.push('table-success');

      } else {

        if (this.personelTetkikGrid.clickedItem == item)
          list.push('table-info');
      }

      return list.join(" ");
    },
    onRefresh: () => {

      this.personelTetkikGrid.clearFilterState();
      this.data.getTetkikList(this.personelTetkikGrid.model).subscribe((resp: any) => {
        // console.log(resp); 
        if (resp !== undefined && 'Data' in resp) {
          this.personelTetkikGrid.data = resp.Data.List;
          this.personelTetkikGrid.totalCount = resp.Data.TotalCount;
        }
      }, err => {
        console.log(err);
      });
      this.personelTetkikGrid.selectedItems.length=0;
    },
    onPersonelTekikGridPageChanged: (event) => {
      console.log(event.page);
      this.personelTetkikGrid.model.Paging.PageNumber = event.page;
      this.personelTetkikGrid.onRefresh();
    }

  };


  public data: any = {
    deleteTetkikByIDList: (idList) => {
      return this.apiservice.callPostService("PersonelTetkik/DeleteByTetkikIdList", { idList: idList })
    },
    getFileID: (idList) => {
      return this.apiservice.callPostService("PersonelTetkik/RaporResult", { idList: idList });
    },
    getTetkikList: (model: any) => {

      return this.apiservice.callPostService(this.getPersonelTekikListUrl, model);

    },
    GetBarkodByTetkikIDList: (idList: []) => {
      return this.apiservice.callPostService("Personel/GetBarkodByTetkikIDList", { TetkikIDList: idList });

    }
  }

  public onPersonelTetkikGridPageChanged(event) {
    this.personelTetkikGrid.model.Paging.PageNumber = event.page;
    this.personelTetkikGrid.onRefresh();
  }


}
