import { Injectable } from '@angular/core';
import { ApiService } from '../../../Services/api.service';
import { PermissionViewModel } from "../../models/PermissionViewModel";
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private api: ApiService) { }

  public getPermission(): Promise<PermissionViewModel> {
    return this.api.callService("KullaniciAyarlari/GetPermission", null).then(o => {
      var res = new PermissionViewModel();
      Object.assign(res, o);
      return res;
    });
  };
  public getVersion(): Observable<any> {
    let version = this.api.callGetService("Test/GetVersion");
    return version;
  };
}
