import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { Subject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { PermissionViewModel } from '../../../../../../shared/models/PermissionViewModel';
import { authService } from 'src/app/Services/authService';

@Component({
  selector: 'app-exam-result-eye-dialog',
  templateUrl: './exam-result-eye-dialog.component.html',
  styleUrls: ['./exam-result-eye-dialog.component.css']
})

export class ExamResultEyeDialogComponent implements OnInit {
  permission: PermissionViewModel = new PermissionViewModel();
  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService, private modalService: BsModalService, private authService: authService) {
    this.authService.Ready.then(o => {
      this.permission = o;
    });
  }
  public eventClose: Subject<any> = new Subject()

  ngOnInit() {
    
    this.data.getByID(this.dialogParams.tetkikID).subscribe(o => {

      if (!o || !o.Data)
        return;

      let dt = o.Data;
      this.frmEyeResult.SPH.sag = dt.SagSph;
      this.frmEyeResult.CYL.sag = dt.SagCyl;
      this.frmEyeResult.AKS.sag = dt.SagAks;
      this.frmEyeResult.tansiyon.sag = dt.SagTansiyon;
      this.frmEyeResult.SPH.sol = dt.SolSph;
      this.frmEyeResult.CYL.sol = dt.SolCyl;
      this.frmEyeResult.AKS.sol = dt.SolAks;
      this.frmEyeResult.tansiyon.sol = dt.SolTansiyon;
      this.frmEyeResult.aciklama = dt.Aciklama;
      //this.frmEyeResult.aciklama = o.

    });
  }
  public dialogParams: any = {
    //tetkikID: 3981877
    tetkikID: 0,
    sourcePage: ""
   

  }
  
  public frmEyeResult: ExamResultEye.EyeResult = new ExamResultEye.EyeResult();
  public errors: [] = [];
  public select: any;


  data: any = {
    save: (frm: ExamResultEye.EyeResult) => {
      var par = {
        "SagAks": frm.AKS.sag,
        "SagCyl": frm.CYL.sag,
        "SagSph": frm.SPH.sag,
        "SagTansiyon": frm.tansiyon.sag,
        "SolAks": frm.AKS.sol,
        "SolCyl": frm.CYL.sol,
        "SolSph": frm.SPH.sol,
        "SolTansiyon": frm.tansiyon.sol,
        "TetkikID": this.dialogParams.tetkikID,
        "Aciklama": frm.aciklama,
        "ImzaliMi":frm.imzaliMi,
        
      }
debugger;
      if(this.dialogParams.sourcePage == "personel")
      {
        return this.apiservice.callPostService("PersonelTetkikIslem/GetImzalaVeOnayla", par)
      }
      else {
        
        return this.apiservice.callPostService("PersonelTetkik/SaveGozResult", par)
      }

    },
    getByID: (id: number) => {
      var par = {
        TetkikID: id
      };
      return this.apiservice.callPostService("PersonelTetkik/GetGozResultByID", par);
    }
  }

  onCancel = () => {
    this.bsModalRef.hide();
  }
  public onSave = (select) => {
    debugger;
    if (this.validate().length > 0) {
      return;
    }
    if(select == 1){
      this.frmEyeResult.imzaliMi = true;
    }
     else if(select==2) {
      this.frmEyeResult.imzaliMi = false;
    }
    else{

    }
    this.data.save(this.frmEyeResult).subscribe((resp: any) => {

      this.eventClose.next('success');
      this.bsModalRef.hide();

    });
    return false;
  };

  validate(): any {
    //TODO
    return [];
    this.errors = []
    var err = this.errors;
    if (this.frmEyeResult.SPH.sag == 0) {
      

    }
    return this.errors;
  };
}

export namespace ExamResultEye {

  export class resultTuple {
    sag: number = 0;
    sol: number = 0;
    constructor() {
    }
  }
  export class EyeResult {

    SPH: resultTuple = new resultTuple();
    CYL: resultTuple = new resultTuple();
    AKS: resultTuple = new resultTuple();
    tansiyon: resultTuple = new resultTuple();
    aciklama: string = '';
    imzaliMi:boolean;
    constructor() {
    }
  }
}

