import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-deletecompany',
  templateUrl: './deletecompany.component.html',
  styleUrls: ['./deletecompany.component.css']
})
export class DeletecompanyComponent implements OnInit {
  public deleteCompanyUrl : string = "FirmaOsgb/Delete";

  public FirmaID : any ;
  constructor(public bsModalRef: BsModalRef,private apiservice : ApiService,private modalService: BsModalService) { }

  ngOnInit() {
  }

  public onSilFirma() {
    this.apiservice.callPostService(this.deleteCompanyUrl,{ FirmaID : this.FirmaID,Aktif:false }).subscribe(
      resp =>{
        console.log(resp);
        this.modalService.setDismissReason('delete-Firma');
        this.bsModalRef.hide();
      }
    );
  }

  

}
