import { Component, OnInit, Input } from '@angular/core';
import { OsgbServices } from '../../../../services/OsgbService';
import { Grid } from '../../../../../../../shared/models/UIControls/grid-control';
import { OsgbFirmaModel } from '../../../../models/OsgbFirmaModel';
import { ApiService } from 'src/app/Services/api.service';
import { BsModalService } from 'ngx-bootstrap';
import { DeletecompanyComponent } from 'src/app/features/private/transactions/modals/deletecompany/deletecompany.component';

@Component({
  selector: 'app-osgbscompanies',
  templateUrl: './osgbscompanies.component.html',
  styleUrls: ['./osgbscompanies.component.css']
})
export class OsgbscompaniesComponent implements OnInit {
  public deleteCompanyUrl : string = "FirmaOsgb/Delete";
  FirmaID:any;
  @Input() set osgbId(value: any) {
    if (value == undefined)
      return;
    this.companyFilter.OsgbId = value;
    this.gridCompany.onRefresh();
  }
  constructor(private modalService: BsModalService,private osgbService: OsgbServices,private apiservice : ApiService) { }

  ngOnInit() {
  
  }
  public openDeleteCompanyModal() {
    const initialState = {
      FirmaID: this.gridCompany.selectedItems[0].FirmaId,
    };
    this.modalService.show(DeletecompanyComponent, { initialState,ignoreBackdropClick:true });
    // console.log(this.modalRef);
    this.modalService.onHide.subscribe(
      response => {
        if (response !== null && response == "delete-Firma")
        this.gridCompany.onRefresh();
      }
    )
  }
  firmaAktif(){
    this.FirmaID=this.gridCompany.selectedItems[0].FirmaId;
    this.apiservice.callPostService(this.deleteCompanyUrl,{ FirmaID : this.FirmaID,Aktif:true }).subscribe(
      resp =>{
        console.log(resp);
        this.gridCompany.onRefresh();
      }
    );
  
  }
  companyFilter: CompanyFilter = new CompanyFilter();

  gridCompany: CompanyListComponent_Models.GridCompany = new CompanyListComponent_Models.GridCompany(this.osgbService, this.companyFilter);
  
}

export class CompanyFilter {
  OsgbId: any;
  FirmaId: any;
  FirmaKodu: any;
  FirmaAdi: any;
  FirmaVD: any;
  FirmaVN: any;
  FirmaAdres: any;
  FirmaIl: any;
  FirmaIlce: any;
  FirmaTel: any;
  FirmaFax: any;
  Aktif:any;
}

namespace CompanyListComponent_Models {

  export class GridCompany extends Grid.GridControl<any> {
    public direction: number = 1;
    constructor(private osgbService: OsgbServices, private companyFilter: CompanyFilter) {
      super();
    }
    filter = new Grid.GridInputModel(new OsgbFirmaModel());
    getFilter() {

      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;

      let item = this.filter.filter;
      var f = this.companyFilter;
      item.OsgbId = f.OsgbId;
      item.FirmaId = f.FirmaId;
      item.FirmaKodu = f.FirmaKodu;
      item.FirmaAdi = f.FirmaAdi;
      item.FirmaVD = f.FirmaVD;
      item.FirmaVN = f.FirmaVN;
      item.FirmaAdres = f.FirmaAdres;
      item.FirmaIl = f.FirmaIl;
      item.FirmaIlce = f.FirmaIlce;
      item.FirmaTel = f.FirmaTel;
      item.FirmaFax = f.FirmaFax;
      return this.filter;
    };
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    onRefresh() {
      var item = this.getFilter()
      this.osgbService.GetFirmaListByOsgbID(item).then(o => {
        this.data = o;
      });
    }
  }

}
