import { Component, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { noop, Observable, Observer, of, from } from 'rxjs';
import { map, switchMap, tap, debounce, debounceTime } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';


interface GitHubUserSearchResponse {
  total_count: number;
  incomplete_results: boolean;
  items: GitHubUser[];
}

interface GitHubUser {
  login: string;
  id: number;
  node_id: string;
  avatar_url: string;
  gravatar_id: string;
  url: string;
  html_url: string;
  followers_url: string;
  subscriptions_url: string;
  organizations_url: string;
  repos_url: string;
  received_events_url: string;
  type: string;
  score: number;
}
@Component({
  selector: 'app-t1',
  templateUrl: './osgb-firma-typeahead.html',
})
export class t1Component {
  @Output() onSelectChange: EventEmitter<any> = new EventEmitter();

  search: string;
  suggestions$: Observable<{}>;
  errorMessage: string;
  selectedOption: any;
  abc: any;

  constructor(private apiService: ApiService) { }


  ngOnInit(): void {

    this.suggestions$ = new Observable((observer: Observer<string>) => {
      this.onSelect(new TypeaheadMatch(undefined));
      observer.next(this.search);
    }).pipe(debounceTime(500))
      .pipe(
        switchMap((query: string) => {
          if (query) {
            // using github public api to get users by name
            return from(this.apiService.callService("FirmaOsgb/GetAutoCompleteList", { Text: query }));

          }

          return of([]);
        })
      );
  }


  onSelect(event: TypeaheadMatch): void {
    if (this.selectedOption == event.item)
      return;
    this.selectedOption = event.item;

    this.onSelectChange.emit(this.selectedOption);
  }
}

