import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '../shared/consts/environment';



@Injectable({
  providedIn: 'root'
})
export class FileService {


  public async downloadAll(urls) {
   
    async function saveBlobs(blobs, wait) {
      for (let blob of blobs) {
        saveBlob(blob[0], blob[1]);
        if (wait)
          await new Promise<void>(resolve => setTimeout(() => resolve(), wait));
      }
    }

    function getBlob(href) {
      return fetch(href).then((response) => response.blob());
    }
    
    function saveBlob(blob, name) {
      //create the initiator
      var download = document.createElement('a');
      download.href = URL.createObjectURL(blob);
      download.download = name;
      
      //fire it off
      document.body.appendChild(download);
      download.click();
      setTimeout(() => download.remove());
    }
    let inMemory=[];
    for (let urlItem of urls) {
      var url = environment.serverAddress + "FileOperation/Download?fileID=" + urlItem;
      inMemory.push([
         await getBlob(url),
         urlItem
      ]);
    }
    return saveBlobs(inMemory,200);
  }

  public download(fileID, newTab: number = 1) {
    if (!fileID)
      return;
    var url = environment.serverAddress + "FileOperation/Download?fileID=" + fileID;
    console.log("Download statrting..." + url);
    if (newTab == 1)
      this.downloadUrlWindow(url);
    else {
      this.downloadUrl(url);
    }
  }

  private downloadUrl(url) {
    var iframe = document.createElement("iframe");
    iframe.src = url;
    iframe.style.display = "none";
    document.body.appendChild(iframe);
  }
  private downloadUrlWindow(url) {
    window.open(url, 'Download');
  }
  private downloadUrlWindowLocation(url) {
    window.open(url, 'Download');
  }
}
