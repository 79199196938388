export class PermissionViewModel {
  AkcigerGrafisiImzalama: any;
  HemogramImzalama: any;
  OdiometriImzalama: any;
  SFTImzalama: any;
  GozOnaylama: any;
  FirmaEklemeGuncelleme: any;
  FirmaSilme: any;
  PersonelEklemeGuncelleme: any;
  PersonelSilme: any;
  PaketTetkikEkleme: any;
  TetkikSilme: any;
  ExcelPersonelEkleme: any;
  PdfPersonelSonucEkleme: any;
  ExcelPersonelOdioSonucEkleme: any;
  Barkodlama: any;
  PersonelCiktiAlma: any;
  TetkikSonucEkleme: any;
  TopluKabulSonucEkleme: any;
  TetkikSonucAlma: any;
  Imzalama: any;
  LaboratuvarIslemleri: any;
  PaketSayfasi: any;
  OsgbSayfasi: any;
  TetkikSayfasi: any;
  FtpSayfasi: any;
  KullaniciSayfasi: any;
  HekimListGörüntüleme: any;
  TetkikOnay: any;
  TransferSevk: any;
  Bildirimler: any;
  RaporSablon: any;
}
