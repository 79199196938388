import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule, trLocale } from 'ngx-bootstrap';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AppComponent } from './app.component';
import { ApiService } from './Services/api.service';
import { ModalManagerService } from './Services/modal-manager.service';
import { DataManagerService } from './Services/dataManager/data-manager.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { authService } from './Services/authService';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ConfirmationDialogComponent } from './shared/modals/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogWithinputComponent } from './shared/modals/confirmation-dialog-withinput/confirmation-dialog-withinput.component';
import { LoadingInterceptor } from './shared/interceptors/loading/loading-interceptor';
import { LoginComponent } from './features/public/login/pages/login/login.component';
import { SidebarComponent } from './shared/pages/sidebar/sidebar.component';
import { TestComponent } from './features/private/test/test/test.component';
import { HeaderComponent } from './shared/pages/header/header.component';
import { FooterComponent } from './shared/pages/footer/footer.component';
import { ContactComponent } from './features/public/contact/pages/contact/contact.component';
import { KimlikConfirmationComponent } from './shared/modals/kimlik-confirmation/kimlik-confirmation.component';
import { TransactionsComponent } from './features/private/transactions/pages/transactions/transactions.component';
import { OsgbsComponent } from './features/private/definitions/pages/osgbs/osgbs.component';
import { ExamResultBloodDialogComponent } from './features/private/transactions/modals/examResult/exam-result-blood-dialog/exam-result-blood-dialog.component';
import { ExamResultLungsDialogComponent } from './features/private/transactions/modals/examResult/exam-result-lungs-dialog/exam-result-lungs-dialog.component';
import { PersoneltetkikComponent } from './features/private/transactions/modals/personeltetkik/personeltetkik.component';
import { ExamResultFileDialogComponent } from './features/private/transactions/modals/examResult/exam-result-file-dialog/exam-result-file-dialog.component';
import { ExamResultOdioDialogComponent } from './features/private/transactions/modals/examResult/exam-result-odio-dialog/exam-result-odio-dialog.component';
import { ExamResultEyeDialogComponent } from './features/private/transactions/modals/examResult/exam-result-eye-dialog/exam-result-eye-dialog.component';
import { DeletemultplExamComponent } from './features/private/transactions/modals/deletemultpl-exam/deletemultpl-exam.component';
import { DeleteplExamComponent } from './features/private/transactions/modals/deletepl-exam/deletepl-exam.component';
import { SavePlBypdfComponent } from './features/private/transactions/modals/save-pl-bypdf/save-pl-bypdf.component';
import { SaveAudioComponent } from './features/private/transactions/modals/save-audio/save-audio.component';
import { SavePlByexcelComponent } from './features/private/transactions/modals/save-pl-byexcel/save-pl-byexcel.component';
import { PersonelExaminationsComponent } from './features/private/transactions/pages/transactions/personel-examinations/personel-examinations.component';
import { PersonelGridComponent } from './features/private/transactions/pages/transactions/personel/personel.component';
import { OsgbComponent } from './features/private/transactions/pages/transactions/osgb/osgb.component';
import { ExaminationsComponent } from './features/private/transactions/modals/examinations/examinations.component';
import { PacketsComponent } from './features/private/transactions/modals/packets/packets.component';
import { InvestigationsComponent } from './features/private/transactions/modals/investigations/investigations.component';
import { DeleteMultiplepersonelComponent } from './features/private/transactions/modals/delete-multiplepersonel/delete-multiplepersonel.component';
import { DeletepersonelComponent } from './features/private/transactions/modals/deletepersonel/deletepersonel.component';
import { NewpersonelComponent } from './features/private/transactions/modals/newpersonel/newpersonel.component';
import { DeletecompanyComponent } from './features/private/transactions/modals/deletecompany/deletecompany.component';
import { NewCompanyComponent } from './features/private/transactions/modals/new-company/new-company.component';
import { RaporComponent } from './features/private/transactions/pages/rapor/rapor.component';
import { PersonelComponent } from './features/private/transactions/pages/personel/personel.component';
import { DetailsComponent } from './features/private/definitions/pages/osgbs/details/details.component';
import { OsgblistComponent } from './features/private/definitions/pages/osgbs/osgblist/osgblist.component';
import { OsgbscompaniesComponent } from './features/private/definitions/pages/osgbs/details/osgbscompanies/osgbscompanies.component';
import { DetailstabComponent } from './features/private/definitions/pages/osgbs/details/detailstab/detailstab.component';
import { SaveOsgbComponent } from './features/private/definitions/modals/save-osgb/save-osgb.component';
import { FileUploader, FileUploadModule } from 'ng2-file-upload';
import { FTPUploadPageComponent } from './features/private/ftps/pages/ftp-upload/ftp-upload-page/ftp-upload-page.component';
import { UploadComponent } from './features/private/ftps/pages/ftp-upload/upload/upload.component';
import { FTPGridComponent } from './features/private/ftps/pages/ftp-upload/ftp-grid/ftp-grid.component';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { PricingsPageComponent } from './features/private/pricings/pricings-page/pricings-page.component';
import { PricingsGridComponent } from './features/private/pricings/pricings-grid/pricings-grid.component';
import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import localeTrExtra from '@angular/common/locales/extra/tr';
import { LoginCheckActivate } from './shared/models/routings/LoginCheckActivate';
import { cookieService } from './shared/services/cookieService';
import { NotificationToastService } from './shared/services/notification-toast.service';
import { FileService } from './Services/file.service';
import { authenticationService } from './shared/services/authenticationService';

registerLocaleData(localeTr, 'tr-TR', localeTrExtra);
import { TetkikComponent } from './features/private/definitions/pages/tetkik/tetkik.component';
import { TetkiklistComponent } from './features/private/definitions/pages/tetkik/tetkiklist/tetkiklist.component';
import { TetkikdetailsComponent } from './features/private/definitions/pages/tetkik/tetkikdetails/tetkikdetails.component';
import { TetkikdetailtabComponent } from './features/private/definitions/pages/tetkik/tetkikdetails/tetkikdetailtab/tetkikdetailtab.component';
import { LabtetkiklistComponent } from './features/private/definitions/pages/tetkik/tetkikdetails/labtetkiklist/labtetkiklist.component';
import { SaveTetkikComponent } from './features/private/definitions/modals/save-tetkik/save-tetkik.component';
import { SaveLabtetkikComponent } from './features/private/definitions/modals/save-labtetkik/save-labtetkik.component';
import { ExamResultLabDialogComponent } from './features/private/transactions/modals/examResult/exam-result-lab-dialog/exam-result-lab-dialog/exam-result-lab-dialog.component';
import { PaketComponent } from './features/private/definitions/pages/paket/paket.component';
import { PaketlistComponent } from './features/private/definitions/pages/paket/paketlist/paketlist.component';
import { PaketdetailsComponent } from './features/private/definitions/pages/paket/paketdetails/paketdetails.component';
import { PaketdetailtabComponent } from './features/private/definitions/pages/paket/paketdetails/paketdetailtab/paketdetailtab.component';
import { PakettetkiklistComponent } from './features/private/definitions/pages/paket/paketdetails/pakettetkiklist/pakettetkiklist.component';
import { SavePaketComponent } from './features/private/definitions/modals/save-paket/save-paket.component';
import { AddTetkiktopaketComponent } from './features/private/definitions/modals/add-tetkiktopaket/add-tetkiktopaket.component';
import { KullaniciComponent } from './features/private/definitions/pages/kullanici/kullanici.component';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { t1Component } from './features/private/transactions/directives/osgb-firma-typeahead/osgb-firma-typeahead';
import { PersonelSearchComponent } from './features/private/transactions/modals/personel-search/personel-search.component';
import { KullanicilistComponent } from './features/private/definitions/pages/kullanici/kullanicilist/kullanicilist.component';
import { KullanicidetailsComponent } from './features/private/definitions/pages/kullanici/kullanicidetails/kullanicidetails.component';
import { KullanicidetailtabComponent } from './features/private/definitions/pages/kullanici/kullanicidetails/kullanicidetailtab/kullanicidetailtab.component';
import { KullaniciyetkiComponent } from './features/private/definitions/pages/kullanici/kullanicidetails/kullaniciyetki/kullaniciyetki.component';
import { SaveKullaniciComponent } from './features/private/definitions/modals/save-kullanici/save-kullanici.component';
import { PersonelTetkikLabResultComponent } from './features/private/transactions/pages/personel/personel-tetkik-lab-result/personel-tetkik-lab-result.component';
import { GetpaymentComponent } from './features/private/transactions/modals/getpayment/getpayment.component';
import { ReturnpaymentComponent } from './features/private/transactions/modals/returnpayment/returnpayment.component';
import { CariraporComponent } from './features/private/transactions/pages/carirapor/carirapor.component';
import { SitelayoutComponent } from './sitelayout/sitelayout.component';
import { SaveResultExcelComponent } from './features/private/transactions/pages/personel/save-result-excel/save-result-excel.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PersoneltetkikmodalComponent } from './features/private/transactions/modals/personeltetkikmodal/personeltetkikmodal.component';
import { RaporResultItemlistModalComponent } from './features/private/transactions/modals/rapor-result-itemlist-modal/rapor-result-itemlist-modal.component';
import { ExamResultEyerefractiveDialogComponent } from './features/private/transactions/modals/exam-result-eyerefractive-dialog/exam-result-eyerefractive-dialog.component';
import { ConfirmationDialogRejectionComponent } from './shared/modals/confirmation-dialog-rejection/confirmation-dialog-rejection.component';
import { ConfirmationMailFormComponent } from './shared/modals/confirmation-mail-form/confirmation-mail-form.component';
import { HbysBarkodModalComponent } from './features/private/transactions/modals/hbys-barkod-modal/hbys-barkod-modal.component';
import { NotificationComponent } from './features/private/Settings/pages/notification/notification.component';
import { NewDepartmentModalComponent } from './features/private/transactions/modals/new-department-modal/new-department-modal.component';
import { FrxComponent } from './features/private/Settings/pages/frx/frx.component';
import { GetResultsComponent } from './features/private/transactions/pages/get-results/get-results.component';
import { DashboardMainComponent } from './features/private/dashboard/pages/dashboard-main/dashboard-main.component';
import { TransferSevkComponent } from './features/private/transactions/pages/transfer-sevk/transfer-sevk.component';
import { TransferSevkFilterComponent } from './features/private/transactions/pages/transfer-sevk/transfer-sevk-filter/transfer-sevk-filter.component';
import { TransferSevkGridComponent } from './features/private/transactions/pages/transfer-sevk/transfer-sevk-grid/transfer-sevk-grid.component';
import { EtkiAlaniComponent } from './features/private/directives/etki-alani/etki-alani.component';
import { InputAutocomleteComponent } from './Shared/component/input-autocomlete/input-autocomlete.component';
defineLocale('tr', trLocale);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    TransactionsComponent,
    PersonelComponent,
    RaporComponent,
    TestComponent,
    NewCompanyComponent,
    DeletecompanyComponent,
    NewpersonelComponent,
    DeletepersonelComponent,
    DeleteMultiplepersonelComponent,
    KimlikConfirmationComponent,
    HeaderComponent,
    FooterComponent,
    InvestigationsComponent,
    PacketsComponent,
    ExaminationsComponent,
    OsgbComponent,
    PersonelGridComponent,
    PersonelExaminationsComponent,
    SavePlByexcelComponent,
    SaveAudioComponent,
    SavePlBypdfComponent,
    DeleteplExamComponent,
    DeletemultplExamComponent,
    ExamResultEyeDialogComponent,
    ExamResultOdioDialogComponent,
    ExamResultFileDialogComponent,
    ConfirmationDialogComponent,
    ContactComponent,
    PersoneltetkikComponent,
    ExamResultLungsDialogComponent,
    ExamResultBloodDialogComponent,
    OsgbsComponent,
    ConfirmationDialogWithinputComponent,
    DetailsComponent,
    OsgblistComponent,
    OsgbscompaniesComponent,
    DetailstabComponent,
    SaveOsgbComponent,
    FTPUploadPageComponent,
    UploadComponent,
    FTPGridComponent,
    PricingsPageComponent,
    PricingsGridComponent,
    TetkikComponent,
    TetkiklistComponent,
    TetkikdetailsComponent,
    TetkikdetailtabComponent,
    LabtetkiklistComponent,
    SaveTetkikComponent,
    SaveLabtetkikComponent,
    ExamResultLabDialogComponent,
    PaketComponent,
    PaketlistComponent,
    PaketdetailsComponent,
    PaketdetailtabComponent,
    PakettetkiklistComponent,
    SavePaketComponent,
    AddTetkiktopaketComponent,
    t1Component,
    PersonelSearchComponent,
    KullaniciComponent,
    KullanicilistComponent,
    KullanicidetailsComponent,
    KullanicidetailtabComponent,
    KullaniciyetkiComponent,
    SaveKullaniciComponent,
    PersonelTetkikLabResultComponent,
    GetpaymentComponent,
    ReturnpaymentComponent,
    CariraporComponent,
    SitelayoutComponent,
    SaveResultExcelComponent,
    PersoneltetkikmodalComponent,
    RaporResultItemlistModalComponent,
    ExamResultEyerefractiveDialogComponent,
    ConfirmationDialogRejectionComponent,
    ConfirmationMailFormComponent,
    HbysBarkodModalComponent,
    NotificationComponent,
    NewDepartmentModalComponent,
    FrxComponent,
    GetResultsComponent,
    DashboardMainComponent,
    TransferSevkComponent,
    TransferSevkFilterComponent,
    TransferSevkGridComponent,
    EtkiAlaniComponent,
    InputAutocomleteComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFontAwesomeModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),    
    NgxDaterangepickerMd.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    TabsModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgxSpinnerModule,
    FileUploadModule,
    NgMultiSelectDropDownModule.forRoot(),
    
  ],
  providers: [ApiService, CookieService, ModalManagerService, DataManagerService, authService, PersonelExaminationsComponent,
    LoginCheckActivate, cookieService, NotificationToastService, FileService, authenticationService,SaveResultExcelComponent,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }],
  entryComponents: [NewCompanyComponent, NewpersonelComponent,
    DeletecompanyComponent, DeletepersonelComponent,
    DeleteMultiplepersonelComponent, KimlikConfirmationComponent, InvestigationsComponent, PacketsComponent, ExaminationsComponent, SavePlByexcelComponent, SaveAudioComponent, SavePlBypdfComponent, DeleteplExamComponent, DeletemultplExamComponent, ExamResultEyeDialogComponent, ExamResultOdioDialogComponent, ExamResultFileDialogComponent, ConfirmationDialogComponent, PersoneltetkikComponent, PersonelExaminationsComponent, ExamResultLungsDialogComponent, ConfirmationDialogWithinputComponent, ExamResultBloodDialogComponent, SaveOsgbComponent
    , FTPUploadPageComponent, SaveTetkikComponent, SaveLabtetkikComponent, ExamResultLabDialogComponent, SavePaketComponent,
    AddTetkiktopaketComponent, PersonelSearchComponent, SaveKullaniciComponent, GetpaymentComponent, ReturnpaymentComponent, SaveResultExcelComponent, PersoneltetkikmodalComponent,RaporResultItemlistModalComponent,
    ExamResultEyerefractiveDialogComponent,ConfirmationDialogRejectionComponent,HbysBarkodModalComponent,NewDepartmentModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
