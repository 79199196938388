import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { OsgbServices } from '../../../services/OsgbService';
import { OsgbDetayModel } from '../../../models/OsgbDetayModel';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
  _osgbID: any;
  @Input() set clickedOsgbID(value: any) {
    if (value == undefined)
      return;
    this._osgbID = value;
  }

  constructor(private osgbService: OsgbServices) {
  }
  ngOnInit() {

  }

}
