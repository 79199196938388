export class AkcigerModel {
  S1: any;
  S2: any;
  S3: any;
  S4: any;
  S5: any;
  S6: any;
  S7: any;
  S8: any;
  S9: any;
  S10: any;
  DosyaID: any;
  ImzaDurumu: any;
  DoktorID:any;
}
