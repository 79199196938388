import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { PersonelComponent } from '../../pages/personel/personel.component';
import { PersonelTetkikServices } from '../../services/PersonelTetkikService';
import { TahsilatViewFilter } from '../../models/TahsilatViewFilter';
import { TahsilatViewModel } from '../../models/TahsilatViewModel';
import { SaveOdemeAlModel } from '../../models/SaveOdemeAlModel';

@Component({
  selector: 'app-getpayment',
  templateUrl: './getpayment.component.html',
  styleUrls: ['./getpayment.component.css']
})
export class GetpaymentComponent implements OnInit {
  public tahsilatViewModel: any[] = [];
  public tahsilatViewFilter: TahsilatViewFilter = new TahsilatViewFilter();
  public saveOdemeAl: SaveOdemeAlModel = new SaveOdemeAlModel();
  public onClose: Subject<string>;
  public islemIdList: any;
  public selectedIslemID: any[] = [];

  public secilenToplam: number = 0;
  public iskontoToplam: number = 0;
  public genelToplam: number = 0;
  public nakit: number = 0;
  public kredi: number = 0;
  public fatura: number = 0;
  public kalanTutar: number = 0;
  public indirimTutar: number;


  public yeniUcret: any;
  public tutar: any;
  constructor(public bsModalRef: BsModalRef,private personelService: PersonelTetkikServices ) { }

  ngOnInit() {
    this.onClose = new Subject();
   
    this.tahsilatViewFilter.IslemIDList = this.islemIdList;
    this.personelService.GetTahsilatList(this.tahsilatViewFilter).then(resp => {
      this.tahsilatViewModel = resp;
      this.onHesaplama(0);
    });
   
  }
  onConfirm(st: any) {
    this.saveOdemeAl.Fatura = this.fatura;
    this.saveOdemeAl.Kredi = this.kredi;
    this.saveOdemeAl.Nakit = this.nakit;
    this.saveOdemeAl.GenelToplam = this.genelToplam;
    this.saveOdemeAl.gridList = this.tahsilatViewModel
    this.saveOdemeAl.PersonelID = this.tahsilatViewModel[0].PersonelID;
    this.saveOdemeAl.FirmaID = this.tahsilatViewModel[0].FirmaID;
    this.saveOdemeAl.OsgbID = this.tahsilatViewModel[0].OsgbID;

    this.personelService.SaveOdemeAl(this.saveOdemeAl).then(resp => {
      this.onClose.next("confirm");
      this.bsModalRef.hide();
    });
  }
  onCancel(st: any) {
    this.onClose.next("cancel");
    this.bsModalRef.hide();
  }

  onHesaplama(parameter) {
    this.iskontoToplam = 0;
    this.secilenToplam = 0
    this.genelToplam = 0;

    this.tahsilatViewModel.forEach(item => {
      item.IndirimTutar = Number(item.IndirimTutar);
      item.YeniUcret = Number(item.YeniUcret);
      item.Tutar = Number(item.Tutar);

      item.Tutar = item.YeniUcret;
      if (parameter == 1) {
        if (item.Indirim == null || item.Indirim == 0) {
          item.IndirimTutar = 0;
        }
        else {
          item.IndirimTutar = (item.Indirim / 100 * item.YeniUcret);
        }
      }
      else {
        if (item.IndirimTutar == null || item.IndirimTutar == 0) {
          item.Indirim = 0;
        }
        else {
          item.Indirim = (item.IndirimTutar / item.YeniUcret * 100);
        }
      }
      this.iskontoToplam = this.iskontoToplam + item.IndirimTutar;
      this.secilenToplam = this.secilenToplam + item.YeniUcret;
      debugger;
      this.genelToplam = this.genelToplam + item.Tutar;
    });
    this.genelToplam = this.genelToplam - this.iskontoToplam;
    this.kalanTutar = this.genelToplam - (Number(this.fatura) + Number(this.kredi) + Number(this.nakit));

  }
}
