import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { IDViewModel } from '../../definitions/models/IDViewModel';
import { NameViewModel } from '../models/NameViewModel';
@Injectable({
  providedIn: 'root'
})
export class FirmaServices {

  constructor(private api: ApiService) { }
  public GetKeyList(model: IDViewModel): Promise<NameViewModel[]> {
    return this.api.callService("Firma/GetKeyList", model).then(o => {
      return o as NameViewModel[];
    });
  }
}
