import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { DataManagerService } from 'src/app/Services/dataManager/data-manager.service';

@Component({
  selector: 'app-deletemultpl-exam',
  templateUrl: './deletemultpl-exam.component.html',
  styleUrls: ['./deletemultpl-exam.component.css']
})
export class DeletemultplExamComponent implements OnInit {

  public deletebypersonelListUrl: string = "PersonelTetkik/DeleteByPersonelList";

  constructor(public bsModalRef: BsModalRef,private apiservice : ApiService,private modalService: BsModalService,private dataservice : DataManagerService) { }

  ngOnInit() {
  }

  onDelete(){
    let idlist = this.dataservice.getPersonelIDList();
    this.apiservice.callPostService(this.deletebypersonelListUrl,{ personelIDList : idlist  }).subscribe(
      resp =>{
        console.log(resp);
        this.modalService.setDismissReason('delete-personelList-tekik');
        this.bsModalRef.hide();
      },err =>{
        console.log(err);
      }
    );
  }

}
