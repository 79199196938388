import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IDViewModel } from '../../definitions/models/IDViewModel';
import { OsgbFirmaModel } from '../../definitions/models/OsgbFirmaModel';
import { PaketDetayModel } from '../../definitions/models/PaketDetayModel';
import { OsgbServices } from '../../definitions/services/OsgbService';
import { PaketTanimlamaServices } from '../../definitions/services/PaketTanimlamaService';
import { LaboratuvarServices } from '../../Settings/services/LaboratuvarServices';
import { ddlSettings } from '../../transactions/modals/personeltetkikmodal/ddlSettings';
import { DepartmentListViewModel } from '../../transactions/models/DepartmentListViewModel';
import { NameViewModel } from '../../transactions/models/NameViewModel';
import { PersonelServices } from '../../transactions/services/PersonelService';

@Component({
  selector: 'app-etki-alani',
  templateUrl: './etki-alani.component.html',
  styleUrls: ['./etki-alani.component.css']
})
export class EtkiAlaniComponent implements OnInit {
  public ddlSettings: ddlSettings = new ddlSettings();
  public ddlLabList: Array<any>
  public ddlLabSelectedItems: any[]
  public ddlLabSettings;
  public ddlOSGBSettings;
  public ddlOSGBList: Array<NameViewModel>;
  public ddlOSGBSelectedItems = [];
  public ddlFirmaSettings;
  public ddlFirmaList: Array<OsgbFirmaModel>;
  public ddlFirmaSelectedItems = [];
  public ddlPersonelList: Array<NameViewModel>;
  public ddlPersonelSelectedItems = [];
  public ddlPersonelSettings;
  idViewModel: IDViewModel = new IDViewModel();
  personelInputModel: DepartmentListViewModel = new DepartmentListViewModel();
  paketDetayModel: PaketDetayModel = new PaketDetayModel();
  etkiAlaniModel: EtkiAlaniModel = new EtkiAlaniModel();

  @Output() etkiAlaniValues: EventEmitter<any> = new EventEmitter();
  constructor(public labServices: LaboratuvarServices,
    private paketServices: PaketTanimlamaServices, private personelServices: PersonelServices,
    private osgbService: OsgbServices) { }

  ngOnInit() {
    this.loadDLL();
  }

  loadDLL() {
    
    this.ddlLabSettings = this.ddlSettings.ddlDizaynSettings;
    this.ddlPersonelSettings = this.ddlSettings.ddlDizaynSettings;
    this.ddlOSGBSettings = this.ddlSettings.ddlDizaynSettings;
    this.ddlOSGBSettings.singleSelection = true;
    this.ddlOSGBSettings.closeDropDownOnSelection=true;
    this.ddlFirmaSettings = this.ddlSettings.ddlFirmaListSettings;
    this.ddlFirmaSettings.closeDropDownOnSelection=true;
    this.getLabList();

  }
  getOSGBListOptions() {
    this.osgbService.GetKeyList(this.idViewModel).then(o => {
      this.ddlOSGBList = [];
      this.ddlOSGBList = o;
      console.log(this.ddlOSGBList);
    })

  }
  getLabList() {
    this.labServices.GetKeyList().then(o => {
      this.ddlLabList = o;
      console.log(this.ddlLabList)
    })
  }
  changeOsgbID() {
    this.ddlFirmaSelectedItems = [];
    this.paketDetayModel.ID = this.ddlOSGBSelectedItems[0].ID
    this.etkiAlaniModel.OsgbId = this.paketDetayModel.ID;
    this.getFirmaList();
    this.changeEtkiAlani();
  }
  getPersoneList() {
    this.personelServices.GetKeyList(this.personelInputModel).then(o => {
      this.ddlPersonelList = o;
    })
  }
  changeFirmaID() {
    debugger;
    this.ddlPersonelSelectedItems = [];
    this.personelInputModel.FirmaID = this.ddlFirmaSelectedItems[0].FirmaId;
    this.etkiAlaniModel.firmaID = this.personelInputModel.FirmaID;
    this.getPersoneList();
    this.changeEtkiAlani();
  }
  getFirmaList() {
    if (this.ddlOSGBSelectedItems.length == 0)
      return;
    this.paketServices.GetFirmaList(this.paketDetayModel).then(o => {
      this.ddlFirmaList = o;
      // this.ddlFirma.sort((a, b) => a.FirmaAdi < b.FirmaAdi ? -1 : a.FirmaAdi > b.FirmaAdi ? 1 : 0)
    });
  }
  changePersonelID() {
    this.etkiAlaniModel.PersonelID = this.ddlPersonelSelectedItems[0].ID;
    this.changeEtkiAlani();
  }
  changeEtkiAlani() {
    this.etkiAlaniValues.emit(this.etkiAlaniModel)
  }
  changeLabID() {
    this.idViewModel.ID = this.ddlLabSelectedItems[0].ID;
    this.etkiAlaniModel.LabID = this.idViewModel.ID
    this.getOSGBListOptions();
    this.changeEtkiAlani();
  }
}
export class EtkiAlaniModel {
  firmaID: any;
  PersonelID: any;
  LabID: any;
  OsgbId: any;
}