import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, BsLocaleService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { OSGBUIModel } from '../../models/OSGBUIModel';
import { OsgbServices } from '../../services/OsgbService';
import { OsgbDetayModel } from '../../models/OsgbDetayModel';
import { defineLocale, trLocale } from 'ngx-bootstrap/chronos';
import { LaboratuvarServices } from '../../../Settings/services/LaboratuvarServices';
import { ddlSettings } from '../../../transactions/modals/personeltetkikmodal/ddlSettings';
defineLocale('tr', trLocale);
@Component({
  selector: 'app-save-osgb',
  templateUrl: './save-osgb.component.html',
  styleUrls: ['./save-osgb.component.css']
})
export class SaveOsgbComponent implements OnInit {
  public onClose: Subject<string>;
  public osgbId: any = undefined;
  public ddlSettings: ddlSettings = new ddlSettings();
  modalTitle: any;
  idVisible: boolean;
  osgbUIModel: OSGBUIModel = new OSGBUIModel();
  public model: any = {
    bittar: undefined,
    bastar: undefined
  };
  public ddlLabList:Array<any>
  public ddlLabSelectedItems:any[]
  public ddlLabSettings
  constructor(
    private localeService: BsLocaleService,
    public bsModalRef: BsModalRef,
    public labServices:LaboratuvarServices,
    private osgbService: OsgbServices) { 
      this.getLabList();
    }

  ngOnInit() {

    this.ddlLabSettings = this.ddlSettings.ddlDizaynSettings
    this.ddlLabSettings.singleSelection=false;
    this.ddlLabSettings.itemsShowLimit=2;
    this.localeService.use('tr');
 
    console.log(this.osgbId);
    this.onClose = new Subject();
   
    if (this.osgbId == undefined) {
      this.modalTitle = "OSGB Ekleme";
      this.idVisible = false;
    }
    else {
      this.modalTitle = "OSGB Güncelleme";
      console.log(this.osgbId);
      let model = new OsgbDetayModel();
      model.ID = this.osgbId
      this.osgbService.GetByOsgbID(model).then(o => {
        o.OSGB_SozBasTar = new Date(o.OSGB_SozBasTar)
        o.OSGB_SozBitTar = new Date(o.OSGB_SozBitTar);
        this.osgbUIModel = o;
        this.ddlLabSelectedItems=this.osgbUIModel.LabList.map(o=>o)

      })
      this.idVisible = true;
    }
   
  }

    getLabList(){
      this.labServices.GetKeyList().then(o=>{
        this.ddlLabList=o;
        console.log(this.ddlLabList)
      })
    }
  public onConfirm(st: any) {
    this.osgbUIModel.LabIDList=[];
    console.log(this.osgbUIModel.Id);
    this.ddlLabSelectedItems.forEach(item=>{
      this.osgbUIModel.LabIDList.push(item.ID);
    })
    this.osgbService.Save(this.osgbUIModel).then(o => {
      this.onClose.next("confirm");
      this.bsModalRef.hide();
    });
  }
  public onCancel(st: any) {
    this.onClose.next("cancel");
    this.bsModalRef.hide();
  }
}
