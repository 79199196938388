import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
export class CustomHttpParams extends HttpParams {
  constructor(public ignoreLoadingSpinner: boolean) {
   super();
  }
}