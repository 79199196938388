import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { Subject, fromEvent } from 'rxjs';
import { PersonelViewModel } from '../../models/PersonelViewModel';
import { PersonelViewFilter } from '../../models/PersonelViewFilter';
import { Grid } from 'src/app/shared/models/UIControls/grid-control';
import { PersonelServices } from '../../services/PersonelService';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-personel-search',
  templateUrl: './personel-search.component.html',
  styleUrls: ['./personel-search.component.css']
})
export class PersonelSearchComponent implements OnInit {
  tarih: { start: moment.Moment, end: moment.Moment };
  personelViewModel: PersonelViewModel = new PersonelViewModel();
  constructor(public bsModalRef: BsModalRef, private modalService: BsModalService,private personelService:PersonelServices) { }
  public eventClose: Subject<any> = new Subject()
  @ViewChild('filterSection', {static: false}) input: ElementRef;
  ngOnInit() {
    this.gridPersonelList.onRefresh();
  
  }
  ngAfterViewInit() {
    
    let tableDom = this.input.nativeElement;
    let items = tableDom.querySelectorAll('.filterInput');
  
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      fromEvent(item, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(2000),
          distinctUntilChanged(),
          tap((text) => {
            if(item.value.length>2)
            this.gridPersonelList.onRefresh();
            else if(item.value.length==0)
            this.gridPersonelList.onRefresh();
          })
        )
        .subscribe();
    }
    
  }
  onFilterInputChange(event) {

    this.gridPersonelList.onRefresh();
  }
  personelFilter: PersonelFilter = new PersonelFilter;
  gridPersonelList: PersonelSearchComponent_Models.GridPersonelSearch = new PersonelSearchComponent_Models.GridPersonelSearch(this.personelService, this.personelFilter)
  
  onCancel() {
    this.eventClose.next(null);
    this.bsModalRef.hide();
  }
  onKeyPress(event: any) {
    debugger;
    if (event.key === "Enter") {
      this.onSave();
    }}
  onSave(){
    debugger;
    var item=this.gridPersonelList.clickedItem;
    if(!item)
    {
      alert("Lütfen elemana tıklayan");
      return;
    }
    var model= new PersonelSearchDialogResult();
    model.FirmaID=item.FirmaID;
    model.OsgbID=item.OsgbID;
    model.PersonelID=item.ID;

    this.eventClose.next(model);
    this.bsModalRef.hide();
  }
}
export class PersonelSearchDialogResult
{
  PersonelID: number;
  OsgbID: number;
  FirmaID: number;
}
export class PersonelFilter{
  TCKNo: any;
  personelAd: any;
  // KayitTarihiRange: any []
  tarihRange: { start: moment.Moment, end: moment.Moment };

  OsgbAdi: any;
  FirmaAdi: any;
  BarkodNo:any;

}
namespace PersonelSearchComponent_Models {
export class GridPersonelSearch extends Grid.GridControl<any> {
  public direction: number = 1;
  constructor(private personelService:PersonelServices , private personelFilter: PersonelFilter) {
    super();
  }
  filter = new Grid.GridInputModel(new PersonelViewFilter());
  getFilter() {

    this.filter.paging.pageNumber = this.model.paging.pageNumber;
    this.filter.paging.count = this.model.paging.count;
    this.filter.sorting = this.model.sorting;

    let item = this.filter.filter;
    var f = this.personelFilter;
    
    item.PersonelAdi= f.personelAd;
    item.TCKNo= f.TCKNo;
    item.OsgbAdi = f.OsgbAdi;
    item.FirmaAdi = f.FirmaAdi;
    item.BarkodNo=f.BarkodNo;
    
    // if(f.KayitTarihiRange){
    // item.BasTarih=f.KayitTarihiRange[0];
    // item.BitTarih=f.KayitTarihiRange[1];
    // }
    
    // if (f.tarihRange) {
    //   item.BasTarih=f.tarihRange.start;        
    //   item.BitTarih=f.tarihRange.end;

    // }
  
    return this.filter;
  };
 
  onSorting(colName) {
    if (this.direction == 0) {
      this.direction = 1;
    } else {
      this.direction = 0;
    }
    this.model.sorting.colName = colName;
    this.model.sorting.direction = this.direction;
    this.onRefresh();
  }

  onRefresh() {
    var item = this.getFilter()
    console.log(item);
    var filter = item.filter;
    this.personelService.getPersonelList(item).then(o=>{
      this.data=o;
      console.log(o)
    })
   
  }
}
}
