import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Grid } from '../../../../../../shared/models/UIControls/grid-control';
import { OsgbViewFilter } from '../../../models/OsgbViewFilter';
import { OsgbDetayModel } from '../../../models/OsgbDetayModel';
import { OsgbServices } from '../../../services/OsgbService';
import { from } from 'rxjs';
import { ConfirmationDialogComponent } from '../../../../../../shared/modals/confirmation-dialog/confirmation-dialog.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { SaveOsgbComponent } from '../../../modals/save-osgb/save-osgb.component';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-osgblist',
  templateUrl: './osgblist.component.html',
  styleUrls: ['./osgblist.component.css']
})
export class OsgblistComponent implements OnInit {

  osgbDetayModel: OsgbDetayModel = new OsgbDetayModel();
  constructor(private osgbServices: OsgbServices, private modalService: BsModalService) { }

  @ViewChild('filterSection', {static: false}) input: ElementRef;

  ngOnInit() {
    this.gridOsgb.onRefresh();
  }
  ngAfterViewInit() {
    
    let tableDom = this.input.nativeElement;
    let items = tableDom.querySelectorAll('.filterInput');
    debugger;
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      fromEvent(item, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(2000),
          distinctUntilChanged(),
          tap((text) => {
            if(item.value.length>2)
            this.gridOsgb.onRefresh();
            else if(item.value.length==0)
            this.gridOsgb.onRefresh();
          })
        )
        .subscribe();
    }
    
  }
  onFilterInputChange(event) {

    this.gridOsgb.onRefresh();
  }
  onDelete() {
    const initialState = {
      message: "OSGB'yi silmek istediğinize emin misiniz",
    };
    let modal = this.modalService.show(ConfirmationDialogComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          let item = this.gridOsgb.clickedItem;
          this.osgbDetayModel.ID = item.Id;
          this.osgbServices.Delete(this.osgbDetayModel).then(o => {
            this.gridOsgb.onRefresh();
          });
        }
      }, err => {
        console.log(err);
      }
    );
  }
  onSave() {
    const initialState = {
      osgb: undefined,
    };
    let modal = this.modalService.show(SaveOsgbComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          this.gridOsgb.onRefresh();
        }
      }, err => {
        console.log(err);
      }
    );
  }
  onUpdate(osgb) {
    console.log(osgb.Id);
    const initialState = {
      osgbId: osgb.Id,
    };
    let modal = this.modalService.show(SaveOsgbComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          this.gridOsgb.onRefresh();
        }
      }, err => {
        console.log(err);
      }
    );
  }
  osgbFilter: OsgbFilter = new OsgbFilter();

  gridOsgb: OsgbListComponent_Models.GridOsgb = new OsgbListComponent_Models.GridOsgb(this.osgbServices, this.osgbFilter);


}

export class OsgbFilter {
  Id: any;
  OSGBAdi: any;
  OSGBIl: any;
  OSGBKodu: any;
  OSGBTel: any;
  OSGBVD: any;
  OSGBVN: any;
  OSGB_Fax: any;
  OSGBılce: any;
}

namespace OsgbListComponent_Models {

  export class GridOsgb extends Grid.GridControl<any> {
    public direction: number = 1;
    constructor(private osgbService: OsgbServices, private osgbFilter: OsgbFilter) {
      super();
    }

    filter = new Grid.GridInputModel(new OsgbViewFilter());
    getFilter() {

      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;

      let item = this.filter.filter;
      var o = this.osgbFilter;

      item.Id = o.Id;
      item.OSGBAdi = o.OSGBAdi;
      item.OSGBIl = o.OSGBIl;
      item.OSGBKodu = o.OSGBKodu;
      item.OSGBTel = o.OSGBTel;
      item.OSGBVD = o.OSGBVD;
      item.OSGBVN = o.OSGBVN;
      item.OSGB_Fax = o.OSGB_Fax;
      item.OSGBılce = o.OSGBılce;

      return this.filter;
    };
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    onRefresh() {
      var item = this.getFilter()
      var filter = item.filter;

      this.osgbService.GetOsgbList(item).then(o => {
        this.data = o;
      })
    }
  }

}
