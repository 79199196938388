import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/Data/AuthenticationService';
import { ApiService } from '../../../Services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public version: any;
  constructor(private userService: AuthenticationService, private api: ApiService) { }

  ngOnInit() {

    this.userService.getVersion().subscribe(o => {
      
      this.version = o.Data;
    });

    console.log(this.version);
  }

}
