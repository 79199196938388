export class IslemListInput {
  firmayaGore: boolean;
  osgbyeGore: boolean;
  personeleGore: boolean;
  tetkikGurubunaGore: boolean;
  tetkikAdinaGore: boolean;
  odemeTuruneGore: boolean;
  baslangicTarihi: any;
  bitisTarihi: any;
  mucret: any;
  osgbadi: any;
  osgbid: any;
  firmaid:any;
  firmaadi: any;
  personeladi: any;
  personeltc: any;
  personelkayittarihi: any;
  personelonaytarihi: any;
  personelonaylayan: any;
  labtetkiki: any;
  tetkikgrubu: any;
  tetkikadi: any;
  fatnak: any;
  miktar: any;
  sonuc: any;
  yapildi: any;
  doktor: any;
  tetkikturu: any;
  personeldogumtarihi: any;
  onaylananlar: any;
  TariheGore: any;
  PersonelTelefon: any;
  OnaylayanlarGorunsun: any;
  LabID:number;
}
