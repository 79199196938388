import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, BsLocaleService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { ToastrService } from 'ngx-toastr';
import { of, EMPTY } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { error } from 'util';
import { defineLocale, trLocale } from 'ngx-bootstrap/chronos';
defineLocale('tr', trLocale);
@Component({
  selector: 'app-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.css']
})
export class NewCompanyComponent implements OnInit {

  public getOsgbListUrl: string = "FirmaOsgb/GetOsgbList";
  public saveFirmaUrl: string = "FirmaOsgb/Save";
  public getFirmaDetailsUrl: string = "FirmaOsgb/GetFirmaDetail";


  public FirmaID: any = undefined;
  public pageTitle: string;
  public visiblityOfFirmaId: string;

  public osgbList: any[] = [];
  public model: any = {
    ID: undefined,
    FirmaKodu: "",
    FirmaAdi: "",
    FirmaVD: "",
    FirmaVN: "",
    FirmaAdres: "",
    FirmaIl: "",
    FirmaIlce: "",
    OSGBAdi: "",
    FirmaTel: "+90",
    FirmaFax: "",
    FirmaInternet: "",
    FirmaEmail: "",
    FirmaSozBasTat: undefined,
    FirmaSozBitTar: undefined,
    Aciklama: "",
    Sifre: "",
    Aktif: true
  };

  constructor(private localeService: BsLocaleService,public bsModalRef: BsModalRef, private apiservice: ApiService, private toastr: ToastrService, private modalService: BsModalService) {

  }

  ngOnInit() {

    this.localeService.use('tr');

    if (this.FirmaID == undefined) {
      this.pageTitle = "Yeni Firma Ekle";
      this.visiblityOfFirmaId = "hidden";
    }
    else {
      this.pageTitle = "Firma düzenle";
      this.visiblityOfFirmaId = "visible";
    }
    //this.pageTitle = (this.FirmaID == undefined) ? "Yeni Firma Ekle" : "Firma düzenle";
    let detailsObs$ = (this.FirmaID == undefined) ? of(null) : this.apiservice.callPostService(this.getFirmaDetailsUrl, { FirmaId: this.FirmaID });

    this.apiservice.callPostService(this.getOsgbListUrl, {}).pipe(
      switchMap(resp => {
        if ('Data' in resp) {
          this.osgbList = resp.Data;
          this.osgbList.sort((a, b) => a.OSGBAdi < b.OSGBAdi ? -1 : a.OSGBAdi > b.OSGBAdi ? 1 : 0)
        }
        return detailsObs$;
      }),
      map((resp: any) => {
        // console.log(resp);
        if (resp !== null && 'Data' in resp) {
          this.convertResponseToModel(resp.Data);
        }
      })
    ).subscribe();
  }

  onSaveFirma() {
    let errors = [];
    if (this.model.FirmaKodu.trim() == "")
      errors.push("Firma Kodu");
    if (this.model.FirmaAdi.trim() == "")
      errors.push("Firma Adi");
    if (this.model.OSGBAdi.trim() == "")
      errors.push("OSGB Adi");
    if (this.model.FirmaTel.trim() == "")
      errors.push("Firma Tel");
    if (errors.length > 0) {
      this.toastr.error(errors.join(","));
      return;
    }


    this.apiservice.callPostService(this.saveFirmaUrl, this.model).subscribe(resp => {
      // console.log(resp);
      this.modalService.setDismissReason('save-Firma')
      this.bsModalRef.hide();
    }, err => { console.log(err); });


  }

  convertResponseToModel(data: any) {
    console.log(data);
    this.model.ID = data.FirmaId;
    this.model.OSGBAdi = data.OsgbAdi;
    this.model.FirmaKodu = data.FirmaKodu;
    this.model.FirmaAdi = data.FirmaAdi;
    this.model.FirmaVD = data.FirmaVD;
    this.model.FirmaVN = data.FirmaVN;
    this.model.FirmaAdres = data.FirmaAdres;
    this.model.FirmaIl = data.FirmaIl;
    this.model.FirmaIlce = data.FirmaIlce;
    this.model.FirmaTel = data.FirmaTel;
    this.model.FirmaFax = data.FirmaFax;
    this.model.FirmaEmail = data.FirmaEmail;
    this.model.FirmaInternet = data.WebSitesi
    this.model.FirmaSozBasTat = new Date(data.SozBitTar);
    this.model.FirmaSozBitTar = new Date(data.SozBasTar);
    this.model.Aciklama = data.Aciklama;
    this.model.KullaniciAdi=data.KullaniciAdi;
    this.model.Sifre = data.Sifre;
  }


}
