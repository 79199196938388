import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { ddlSettings } from './ddlSettings';
import { PersonelTetkikIslemServices } from '../../services/PersonelTetkikIslemService';
import { PersonelTetkikModalModel } from '../../models/persenolTetkikModalModel';
import { Grid } from 'src/app/shared/models/UIControls/grid-control';
import { PersonelServices } from '../../services/PersonelService';
import { PersonelViewFilter } from '../../models/PersonelViewFilter';
import { ApiService } from 'src/app/Services/api.service';
import { OsgbFirmaServices } from '../../services/OsgbFirmaService';
import { PersonelTetkikServices } from '../../services/PersonelTetkikService';
import { BarkodViewFilter } from '../../models/BarkodViewFilter';
import { LaboratuvarServices } from '../../../Settings/services/LaboratuvarServices';
import { KullaniciTanımlamaService } from '../../../definitions/services/KullaniciTanımlamaService';
import { UserViewFilter } from '../../../definitions/models/UserViewFilter';

@Component({
  selector: 'app-personeltetkikmodal',
  templateUrl: './personeltetkikmodal.component.html',
  styleUrls: ['./personeltetkikmodal.component.css']
})
export class PersoneltetkikmodalComponent implements OnInit {
  public labList: any[] = [];
  public perTetModalModel: PersonelTetkikModalModel = new PersonelTetkikModalModel();
  public ddlSettings: ddlSettings = new ddlSettings();
  public onClose: Subject<any>;
  public dllKullaniciList=[];
  public userFilterModel:UserViewFilter=new UserViewFilter();
  public filterDateRange = {
    end: undefined,
    start: undefined
  };
  public ddlTetkikSettings;
  public ddlTetkikData = [];
  public ddlTetkikSelectedItems = [];
  public ddlOnaylayanUserSelectedItems = [];
  public ddlOnaylayanUserSettings;
  public ddlBarkodlayanUserSelectedItems = [];
  public ddlBarkodlayanUserSettings;
  public ddlIslemSettings;
  public ddlIslemData = [];
  public ddlIslemSelectedItems = [];

  public ddlBarkodSettings;
  public ddlBarkodData = [];
  public ddlBarkodSelectedItems = [];
  public filterEnable = true;
  public barcodeForFilter = false;
  public dateRangeVisible = true;
  constructor(private labService: LaboratuvarServices, private personelservice: PersonelTetkikServices,
     private osgbFirmaServices: OsgbFirmaServices, private apiservice: ApiService, public bsModalRef: BsModalRef,
      private dataRapor: PersonelTetkikIslemServices, private perTetServices: PersonelServices,private kullaniciService:KullaniciTanımlamaService) { }

  tetkikFilter: TetkikFilter = new TetkikFilter();
  perTetkikModalGrid: PersonelTetkikModal_Models.perTetkikModalGrid = new PersonelTetkikModal_Models.perTetkikModalGrid(this.personelservice, this.apiservice, this.perTetServices, this.tetkikFilter);

  ngOnInit() {
    this.filterDateRange.end = this.setDateTwoMonthAgo(0, 23);
    this.filterDateRange.start = this.setDateTwoMonthAgo(3, 0);
    this.onClose = new Subject();
    this.ddlTetkikSettings = this.ddlSettings.ddlTetkikModalSettings;
    this.ddlIslemSettings = this.ddlSettings.ddlPublicSettings;
    this.ddlOnaylayanUserSettings = this.ddlSettings.ddlUserListSettings;
    this.ddlBarkodSettings=this.ddlSettings.ddlPublicSettings;
    this.ddlBarkodlayanUserSettings=this.ddlSettings.ddlUserListSettings;

    this.ddlIslemData = [
      { id: null, text: 'Hepsi' },
      { id: 1, text: 'İşlem Yapılmayanlar' },
      { id: 2, text: 'Reddedilenler' },
      { id: 3, text: 'Onayda Bekleyenler' },
      { id: 4, text: 'Raporlanmış olanlar' },
      { id: 5, text: 'Laboratuvara Giriş Yapanlar' }
    ];
    this.ddlBarkodData = [
      { id: 1, text: 'Hepsi' },
      { id: 2, text: 'Barkodlu' },
      { id: 3, text: 'Barkodsuz' }
    ];
    this.getUserList();
    this.getTetkikGrup();
    this.getTransferSevkButton();
    if (this.perTetModalModel.firmaID || this.perTetModalModel.personelIDList.length != 0) {
      this.dateRangeVisible = false;
    }
    this.tetkikFilter.firmaID = this.perTetModalModel.firmaID;
    this.tetkikFilter.personelIdList = this.perTetModalModel.personelIDList;

    this.tetkikFilter.isBarkod = null;
    this.tetkikFilter.isIslemYapilanlar = null;
    this.tetkikFilter.tetkikGrubuList = [];
    this.tetkikFilter.BaslangicTarihi = this.filterDateRange.start;
    this.tetkikFilter.BitisTarihi = this.filterDateRange.end;
  
    
  }
  changeBarcodeMode() {
    this.barcodeForFilter = !this.barcodeForFilter;
    debugger;
    if (this.barcodeForFilter) {
      this.ddlIslemSelectedItems = [];
      this.ddlTetkikSelectedItems = [];
      this.ddlBarkodSelectedItems = [];
      this.filterEnable = false;
    }
    else {
      this.filterEnable = true;
    }
  }
  getUserList(){
    
    this.userFilterModel.UserTypeID=1;
     debugger;
    this.kullaniciService.GetKeyList(this.userFilterModel).then(o=>{
      this.dllKullaniciList=o;
      console.log(this.dllKullaniciList)
    })
  }
  getTetkikGrup() {
    this.dataRapor.GetTetkikGrubuList().then(resp => {
      console.log(resp);
      this.ddlTetkikData = [];
      for (var i = 0; i < resp.length; i++) {
        var item = resp[i].TetkikGrubuAdi;
        if (!item)
          continue;
        this.ddlTetkikData.push(item);
      }
      console.log(this.ddlTetkikData)
    })

  }
  onSearch() {
    if (this.filterDateRange) {
      this.tetkikFilter.BaslangicTarihi = this.filterDateRange.start;
      this.tetkikFilter.BitisTarihi = this.filterDateRange.end;
    }
    this.tetkikFilter.tetkikGrubuList = this.ddlTetkikSelectedItems;
    if (this.ddlIslemSelectedItems.length != 0) {
      this.tetkikFilter.durum = this.ddlIslemSelectedItems[0].id;
    }
    if (this.ddlBarkodSelectedItems.length != 0) {
      if (this.ddlBarkodSelectedItems[0].id == 1) {
        this.tetkikFilter.isBarkod = null;
      }
      else if (this.ddlBarkodSelectedItems[0].id == 2) {
        this.tetkikFilter.isBarkod = true;
      }
      else if (this.ddlBarkodSelectedItems[0].id == 3) {
        this.tetkikFilter.isBarkod = false;
      }
      else { }
    }
    this.tetkikFilter.OnaylayanUserList.length=0;
    this.ddlOnaylayanUserSelectedItems.forEach(item => {
      this.tetkikFilter.OnaylayanUserList.push(item.Id)
    })
    this.tetkikFilter.BarkodlayanUserList.length=0;
    this.ddlBarkodlayanUserSelectedItems.forEach(item => {
      this.tetkikFilter.BarkodlayanUserList.push(item.Id)
    })
    if (this.barcodeForFilter) {
      let barkodModel = new BarkodViewFilter();
      barkodModel.BarkodNo = this.tetkikFilter.barcodeNo;
      this.personelservice.SaveLaboratuvaraGiris(barkodModel).then((resp) => {
        console.log(resp);
      })
    }
    this.perTetkikModalGrid.onRefresh();
  }
  getTransferSevkButton() {
    this.labService.GetKeyList().then((resp) => {
      this.labList = resp;
    });
  }
  onClear() {
    this.ddlBarkodSelectedItems = [];
    this.ddlIslemSelectedItems = [];
    this.ddlTetkikSelectedItems = []
  }
  close() {
    this.onClose.next("close");
    this.bsModalRef.hide();
  }
  setDateTwoMonthAgo(month, hours) {
    let today = new Date();
    today.setMonth(today.getMonth() - month);
    if (hours == 0)
      today.setHours(0, 0, 0, 0);
    else
      today.setHours(23, 59, 59, 59)
    return new Date(today);
  }
}
export class TetkikFilter {
  firmaID: any;
  personelIdList: any[];
  isBarkod: any;
  isIslemYapilanlar: any;
  tetkikGrubuList: any[];
  durum: any;
  islemIdList: any[] = [];
  barcodeNo: undefined;
  BaslangicTarihi: Date;
  BitisTarihi: Date;
  BarkodlayanUserList:any[]=[];
  OnaylayanUserList:any[]=[];

}
namespace PersonelTetkikModal_Models {

  export class perTetkikModalGrid extends Grid.GridControl<any> {

    public direction: number = 0;

    constructor(private personelTetkikService: PersonelTetkikServices, private apiservice: ApiService, private personelServices: PersonelServices, private tetkikFilter: TetkikFilter) {
      super();
    }

    filter = new Grid.GridInputModel(new PersonelViewFilter());
    getFilter() {
      this.tetkikFilter.islemIdList = [];
      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;

      var o = this.tetkikFilter;

      this.filter.filter.FirmaId = o.firmaID;
      this.filter.filter.PersonelIDList = o.personelIdList;
      this.filter.filter.IsBarkod = o.isBarkod;
      this.filter.filter.Durum = o.durum;
      this.filter.filter.TetkikGrubuList = o.tetkikGrubuList;
      this.filter.filter.BarkodNo = o.barcodeNo;
      this.filter.filter.BasTarih = o.BaslangicTarihi
      this.filter.filter.BitTarih = o.BitisTarihi
      this.filter.filter.OnaylayanUserIDList=o.OnaylayanUserList;
      this.filter.filter.BarkodlayanUserIDList=o.BarkodlayanUserList;
      // this.selectedItems.forEach(item => {
      //   o.islemIdList.push(item.IslemID)
      // })
      this.filter.filter.TetkikIDList = o.islemIdList;
      return this.filter;
    };
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    onRefresh() {
      var item = this.getFilter()
      this.personelTetkikService.GetPersonelTetkikModalList(item).then(o => {
        console.log(o);
        this.data = o;
        this.selectedItems.length = 0;
      })

    }
    onSevk(id) {
      this.filter.filter.LabID = id;
      this.personelTetkikService.SaveSevk(this.filter).then((resp) => {
        console.log(resp);
      })
    }
    onTransfer(id) {
      this.filter.filter.LabID = id
      this.personelTetkikService.SaveTransfer(this.filter).then((resp) => {
        console.log(resp);
      })
    }
    onBarkodla() {
      this.personelServices.GetBarkodla(this.getFilter()).then(resp => {
        console.log(resp);
        this.onRefresh();
      });
      this.selectedItems.length = 0;
    }
    onBarkodBas() {
      this.personelServices.GetBarkodBas(this.getFilter()).then(resp => {
        console.log(resp);
        this.apiservice.fileOperation.download(resp);
        this.onRefresh();
      });
      this.selectedItems.length = 0;
    }
  }

}
