import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
import { PaketViewModel } from '../models/PaketViewModel';
import { PaketViewFilter } from '../models/PaketViewFilter';
import { OsgbFirmaModel } from '../models/OsgbFirmaModel';
import { PaketDetayModel } from '../models/PaketDetayModel';
import { PaketSaveModel } from '../models/PaketSaveModel';
import { PaketTetkikFilter } from '../models/PaketTetkikFilter';
import { PaketTetkikView } from '../models/PaketTetkikView';
import { PaketTetkikSaveModel } from '../models/PaketTetkikSaveModel';
import { TetkikEkleViewFilter } from '../models/TetkikEkleViewFilter';
import { TetkikViewModel } from '../models/TetkikViewModel';

@Injectable({
  providedIn: 'root'
})
export class PaketTanimlamaServices {

  constructor(private api: ApiService) { }

  public GetPaketList(model: Grid.GridInputModel<PaketViewFilter>): Promise<Grid.GridOutputModel<PaketViewModel>> {
    return this.api.callService("Paket/GetPaketList", model).then(o => {
      var res = new Grid.GridOutputModel<PaketViewModel>();
      res.list = o["List"]; 
      res.totalCount = o["TotalCount"];
      return res;
    });
  };
  public GetFirmaList(model: PaketDetayModel): Promise<OsgbFirmaModel[]> {
    return this.api.callService("Paket/GetFirmaList", model).then(o => {
      var res = o as OsgbFirmaModel[];
      return res;
    });
  };

  public GetPaketByID(model: PaketDetayModel): Promise<PaketViewModel> {
    return this.api.callService("Paket/GetPaketByID", model).then(o => {
      var res = o as PaketViewModel;
      return res;
    });
  };
  public Save(model: PaketSaveModel): Promise<number> {
    return this.api.callService("Paket/Save", model).then(o => {
      return o as number;
    });
  };
  public GetPaketTetkikListById(model: Grid.GridInputModel<PaketTetkikFilter>): Promise<Grid.GridOutputModel<PaketTetkikView>> {
    return this.api.callService("Paket/GetPaketTetkikListById", model).then(o => {
      var res = new Grid.GridOutputModel<PaketTetkikView>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
 
      return res;
    });
  };
  public GetPaketTetkikUcretListById(model: PaketTetkikFilter): Promise<PaketTetkikView[]> {
    return this.api.callService("Paket/GetPaketTetkikUcretListById", model).then(o => {
      return o as PaketTetkikView[];
    });
  };
  public SavePaketTetkik(model: PaketTetkikSaveModel): Promise<number> {
    return this.api.callService("Paket/SavePaketTetkik", model).then(o => {
      return o as number;
    });
  };
  public DeletePaketTetkik(model: PaketDetayModel): Promise<number> {
    return this.api.callService("Paket/DeletePaketTetkik", model).then(o => {
      return o as number;
    });
  };
  public getOsgb():any {
    return this.api.callService("FirmaOsgb/GetOsgbList", {}).then(o => {
      return o;
    });
  }
  public GetTetkikList(model: Grid.GridInputModel<TetkikEkleViewFilter>): Promise<Grid.GridOutputModel<TetkikViewModel>> {
    return this.api.callService("PersonelTetkik/GetTetkikList", model).then(o => {
      var res = new Grid.GridOutputModel<TetkikViewModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
    });
  };
}
