import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { SaveGozKiranModel } from '../../models/SaveGozKiranModel';
import { PersonelTetkikIslemServices } from '../../services/PersonelTetkikIslemService';
import { Subject } from 'rxjs';
import { GozKiranViewModel } from '../../models/GozKiranViewModel';

@Component({
  selector: 'app-exam-result-eyerefractive-dialog',
  templateUrl: './exam-result-eyerefractive-dialog.component.html',
  styleUrls: ['./exam-result-eyerefractive-dialog.component.css']
})
export class ExamResultEyerefractiveDialogComponent implements OnInit {
  saveGozKiranModel:SaveGozKiranModel=new SaveGozKiranModel;
   gozKiranViewModel:GozKiranViewModel=new GozKiranViewModel;
  public sablon:any;
  public sonuc:any;
  public sablonAdi:any;
  constructor(public bsModalRef: BsModalRef, private tetkikIslem: PersonelTetkikIslemServices) { }
  public eventClose: Subject<any> = new Subject()
  ngOnInit() {
    debugger;
    this.gozKiranViewModel.IslemID=this.dialogParams.tetkikID;
    this.tetkikIslem.GetGozKiranByIslemID(this.gozKiranViewModel).then(o=>{
      this.sonuc=o.Sonuc;
    this.sablonAdi=  o.SablonAdi;
    })
    
  }
  public dialogParams: any = {
    //tetkikID: 3981877
    tetkikID: 0,
    
   

  }
  sablonCahnge($event){
    debugger;
     this.sablon=$event.target.value;
    if(this.sablon=="option1"){
      this.sonuc="Yukarıda bilgileri bulunan kişinin Göz Hastalıkları Uzmanı Op.Dr.Cafer UĞUREL tarafından yapılan göz muayenesinde her iki göz  tashihsiz tam görmektedir.Renk körlüğü yoktur. Gece körlüğü yoktur.Ekranlı araçlarda çalışabilir.Personelin yukarıda yapılan göz muayenesinde gece ve yüksekte çalışmasında sakınca yoktur."
      this.sablonAdi="G.süz RK yoktur"
    }
    else if(this.sablon=="option2"){
      this.sonuc="Yukarıda bilgileri bulunan kişinin Göz Hastalıkları Uzmanı Op.Dr.Cafer UĞUREL tarafından yapılan göz muayenesinde her iki göz tashihsiz tam görmektedir.Kısmı renk körlüğü vardır. Gece körlüğü yoktur.Ekranlı araçlarda çalışabilir.Personelin yukarıda yapılan göz muayenesinde gece ve yüksekte çalışmasında sakınca yoktur."
      this.sablonAdi="G.süz RK vardır"
    }
    else if(this.sablon=="option3"){
      this.sonuc="Yukarıda bilgileri bulunan kişinin Göz Hastalıkları Uzmanı Op.Dr.Cafer UĞUREL tarafından yapılan göz muayenesinde her iki göz tashihle tam görmektedir.Kısmı renk körlüğü vardır. Gece körlüğü yoktur.Ekranlı araçlarda çalışabilir.Personelin yukarıda yapılan göz muayenesinde gece ve yüksekte çalışmasında sakınca yoktur."
      this.sablonAdi="G.lü RK vardır"
    }
    else if(this.sablon=="option4"){
      this.sonuc="Yukarıda bilgileri bulunan kişinin Göz Hastalıkları Uzmanı Op.Dr.Cafer UĞUREL tarafından yapılan göz muayenesinde her iki göz tashihle tam görmektedir.Renk körlüğü yoktur. Gece körlüğü yoktur.Ekranlı araçlarda çalışabilir.Personelin yukarıda yapılan göz muayenesinde gece ve yüksekte çalışmasında sakınca yoktur.";
      this.sablonAdi="G.lü RK yoktur"
    }
  }
  onSave(){
console.log(this.sonuc)
    this.saveGozKiranModel.Sonuc=this.sonuc;
    this.saveGozKiranModel.SablonAdi=this.sablonAdi;
    this.saveGozKiranModel.IslemID=this.dialogParams.tetkikID
    console.log(this.saveGozKiranModel)
    this.tetkikIslem.SaveGozKiran(this.saveGozKiranModel).then(resp=>{
      this.eventClose.next("success");
      this.bsModalRef.hide();
    })
  }
  onCancel() {
    this.bsModalRef.hide();
  }
}
