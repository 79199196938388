import { Component, OnInit, Input } from '@angular/core';
import { KullaniciTanımlamaService } from '../../../../services/KullaniciTanımlamaService';
import { UserDetayModel } from '../../../../models/UserDetayModel';

@Component({
  selector: 'app-kullanicidetailtab',
  templateUrl: './kullanicidetailtab.component.html',
  styleUrls: ['./kullanicidetailtab.component.css']
})
export class KullanicidetailtabComponent implements OnInit {
  userDetailModel: any;
  @Input() set kullaniciId(value: any) {
    if (value == undefined)
      return;
    let model = new UserDetayModel();
    model.ID = value
    this.userService.GetById(model).then(o => {
      this.userDetailModel = o;
    })
  }
  constructor(private userService: KullaniciTanımlamaService) { }

  ngOnInit() {
  }

}
