import { Component, OnInit , EventEmitter, HostListener} from '@angular/core';
import { DataManagerService } from 'src/app/Services/dataManager/data-manager.service';
import { PersonelSearchComponent, PersonelSearchDialogResult } from '../../modals/personel-search/personel-search.component';
import { BsModalService } from 'ngx-bootstrap';
import { PersoneltetkikmodalComponent } from '../../modals/personeltetkikmodal/personeltetkikmodal.component';
import { PersonelTetkikModalModel } from '../../models/persenolTetkikModalModel';
 

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  public maindivClass: string = 'col-md-10 h-100';

  public firmaEvent : EventEmitter<any> = new EventEmitter();
  // @HostListener('document:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //  if( event.keyCode==113){
  //    this.onPersenolSearchModal();
  //  }
 
  // }

   constructor(private dataManagerService:DataManagerService,private modalService: BsModalService   ) { }

  ngOnInit() { }

  setChildData(item:PersonelSearchDialogResult){        
    this.dataManagerService.setFirmaID(item.FirmaID);
    this.dataManagerService.setOsgbID(item.OsgbID);
    this.dataManagerService.setPersonelID(item.PersonelID);
    this.firmaEvent.emit('osgb-item-selected');
  }
  onKeyPress(event){
    if(event.keycode==113){
      this.onPersenolSearchModal();
    }
  
  }
  onPersenolSearchModal ()  {
  
    let modal = this.modalService.show(PersonelSearchComponent,{class:'modal-lg',ignoreBackdropClick: true})
    modal.content.eventClose.subscribe(o=>{
      if(!o)
      {
        return;
      }
      let model=o as PersonelSearchDialogResult;
      console.warn("DIALOG Result Personel:",model);
      this.setChildData(model);
    })
    
  }
}

