import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-deletepersonel',
  templateUrl: './deletepersonel.component.html',
  styleUrls: ['./deletepersonel.component.css']
})
export class DeletepersonelComponent implements OnInit {
  
  public deletePersonelUrl : string = "Personel/Delete";

  public personelID : any ;
  
  constructor(public bsModalRef: BsModalRef,private apiservice : ApiService,private modalService: BsModalService) { }

  ngOnInit() {
  }

  onDeletePersonel(){
    this.apiservice.callPostService(this.deletePersonelUrl,{ personelId : this.personelID }).subscribe(
      resp =>{
        console.log(resp);
        this.modalService.setDismissReason('delete-personel');
        this.bsModalRef.hide();
      },err =>{
        console.log(err);
      }
    );
  }

}
