import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { ApiService } from '../../../../../../Services/api.service';
import { AkcigerModel } from '../../../models/AkcigerModel';
import { PersonelTetkikIslemServices } from '../../../services/PersonelTetkikIslemService';
import { AkcigerSonucByIDViewModel } from '../../../models/AkcigerSonucByIDViewModel';

@Component({
  selector: 'app-exam-result-lungs-dialog',
  templateUrl: './exam-result-lungs-dialog.component.html',
  styleUrls: ['./exam-result-lungs-dialog.component.css']
})
export class ExamResultLungsDialogComponent implements OnInit {

  lungsResult: AkcigerModel = new AkcigerModel();
  akcigerCntrol: AkcigerSonucByIDViewModel = new AkcigerSonucByIDViewModel();
  control

  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService, private tetkikIslem: PersonelTetkikIslemServices, private modalService: BsModalService) { }
  public eventClose: Subject<any> = new Subject()
  ngOnInit() {
    // this.lungsResult.S1 = "Kardiotorasik oran normal sınırlardadır.";
    // this.lungsResult.S2 = "Mediastinal vasküler yapılar normal konfigürasyonda izlenmektedir.";
    // this.lungsResult.S3 = "Her iki hilus ve üst mediasten genişliği olağandır.";
    // this.lungsResult.S4 = "Her iki akciğer havalanma ve damarlanması normaldir.";
    // this.lungsResult.S5 = "Her iki akciğerde aktif enfeksiyöz bulgu saptanmamıştır.";
    // this.lungsResult.S6 = "Toraks duvarını oluşturan kemik yapılar normaldir.";
    // this.lungsResult.S7 = "Kostafrenik sinüsler açıktır. Diafram konturları muntazamdır.";
    // this.lungsResult.S8 = "";
    // this.lungsResult.S9 = "Normal Sınırlarda İnceleme";
    // this.lungsResult.DosyaID = this.dialogParams.tetkikID; // tetkik Id gelmiyor personel sayfasından diğer sayfalarada bu şekilde gönderildiği için değiştirmedim +1 değişken olmasın diye
    // // console.log(this.lungsResult.DosyaID);
    this.akcigerCntrol.DosyaID = this.dialogParams.tetkikID;
    this.tetkikIslem.GetAkcigerSonucByID(this.akcigerCntrol).then(o => {
      console.log(o)
      if (o == null) {
        this.lungsResult.S1 = "Kardiotorasik oran normal sınırlardadır.";
        this.lungsResult.S2 = "Mediastinal vasküler yapılar normal konfigürasyonda izlenmektedir.";
        this.lungsResult.S3 = "Her iki hilus ve üst mediasten genişliği olağandır.";
        this.lungsResult.S4 = "Her iki akciğer havalanma ve damarlanması normaldir.";
        this.lungsResult.S5 = "Her iki akciğerde aktif enfeksiyöz bulgu saptanmamıştır.";
        this.lungsResult.S6 = "Toraks duvarını oluşturan kemik yapılar normaldir.";
        this.lungsResult.S7 = "Kostafrenik sinüsler ve kardiofrenik sinüsler açıktır.";
        this.lungsResult.S8 = "";
        this.lungsResult.S9 = "Normal Sınırlarda İnceleme";
        this.lungsResult.S10 = "Diafram konturları muntazamdır.";
        this.lungsResult.DosyaID = this.dialogParams.tetkikID; // tetkik Id gelmiyor personel sayfasından diğer sayfalarada bu şekilde gönderildiği için değiştirmedim +1 değişken olmasın diye
        // console.log(this.lungsResult.DosyaID);
      } else {
        this.lungsResult.S1 = o.S1;
        this.lungsResult.S2 = o.S2;
        this.lungsResult.S3 = o.S3;
        this.lungsResult.S4 = o.S4;
        this.lungsResult.S5 = o.S5;
        this.lungsResult.S6 = o.S6;
        this.lungsResult.S7 = o.S7;
        this.lungsResult.S8 = o.S8;
        this.lungsResult.S9 = o.S9;
        this.lungsResult.S10 = o.S10;
        this.lungsResult.DosyaID = o.DosyaID;


      }
    });

  }
  public dialogParams: any = {
    //tetkikID: 3981877
    tetkikID: 0
  }
  onCancel() {
    this.bsModalRef.hide();
  }
  onSave(select) {
    debugger;
    if (select == 1) {
      this.lungsResult.ImzaDurumu = true;
      this.tetkikIslem.SaveAkcigerSonuc(this.lungsResult).then(resp => {
        this.eventClose.next('success');
        this.bsModalRef.hide();
      });
    }
    if (select == 2) {
      this.lungsResult.ImzaDurumu = false;
      this.tetkikIslem.SaveAkcigerSonuc(this.lungsResult).then(resp => {
        this.eventClose.next('success');
        this.bsModalRef.hide();
      });
    }
    if (select == 3) {
      this.lungsResult.DoktorID = 3;
      this.tetkikIslem.SaveAkcigerSonuc(this.lungsResult).then(resp => {
        this.eventClose.next('success');
        this.bsModalRef.hide();
      });
    }
    console.log(this.lungsResult);
  }

  onDeafultResult() {
    this.lungsResult.S1 = "Kardiotorasik oran normal sınırlardadır.";
    this.lungsResult.S2 = "Mediastinal vasküler yapılar normal konfigürasyonda izlenmektedir.";
    this.lungsResult.S3 = "Her iki hilus ve üst mediasten genişliği olağandır.";
    this.lungsResult.S4 = "Her iki akciğer havalanma ve damarlanması normaldir.";
    this.lungsResult.S5 = "Her iki akciğerde aktif enfeksiyöz bulgu saptanmamıştır.";
    this.lungsResult.S6 = "Toraks duvarını oluşturan kemik yapılar normaldir.";
    this.lungsResult.S7 = "Kostafrenik sinüsler ve kardiofrenik sinüsler açıktır.";
    this.lungsResult.S8 = "";
    this.lungsResult.S9 = "Normal Sınırlarda İnceleme";
    this.lungsResult.S10 = "Diafram konturları muntazamdır.";
  }


}
