import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sitelayout',
  templateUrl: './sitelayout.component.html',
  styleUrls: ['./sitelayout.component.css']
})
export class SitelayoutComponent implements OnInit {
  collapseSidebar: boolean=true;
  public collapse:boolean=true;
  constructor() { }

  ngOnInit() {
  }
  public onToggleSideBar(event){
    debugger;
    console.log(event)
   this.collapse=event;
  }
}
