export class CariRaporViewFilter {
  MUcret: any;
  OsgbAdi: any;
  FirmaAdi: any;
  FirmaID: any;
  OsgbID: any;
  PersonelAdi: any;
  PersonelTc: any;
  TetkikAdi: any;
  Miktar: any;
  PersonelDogumTarihi: any;
  AciklamayaGore: any;
  IslemUcretleriGorunsun: any;
  TahsilatlarGorunsun: any;
  HarcamalarveIadelerGorunsun: any;
  FirmayaGore: any;
  PersoneleGore: any;
  TetkikAdinaGore: any;
  TariheGore: any;
  BaslangicTarihi: any;
  BitisTarihi: any;
}
