import { Component, OnInit } from '@angular/core';
import { TetkikSaveModel } from '../../models/TetkikSaveModel';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { TetkikTanimlamaServices } from '../../services/TetkikTanimlamaService';
import { TetkikGrubuViewModel } from '../../models/TetkikGurubuViewModel';

@Component({
  selector: 'app-save-tetkik',
  templateUrl: './save-tetkik.component.html',
  styleUrls: ['./save-tetkik.component.css']
})
export class SaveTetkikComponent implements OnInit {

  public onClose: Subject<string>;
  public tetkik: any = undefined;
  modalTitle: any;
  idVisible: boolean;
  tetkikGrupList:any;
  tetkikSaveModel: TetkikSaveModel = new TetkikSaveModel();
  ddlTetkikGrubuOptions: Array<TetkikGrubuViewModel>
;
  constructor(
    public bsModalRef: BsModalRef,
    private tetkikServices: TetkikTanimlamaServices) { }

  ngOnInit() {
    this.onClose = new Subject();
    console.log(this.tetkik);
    if (this.tetkik == undefined) {
      this.modalTitle = "Tetkik Ekleme";
      this.idVisible = false;
     
    }
    else {
      this.modalTitle = "Tetkik Güncelleme";
      this.tetkikSaveModel = this.tetkik;
      this.idVisible = true;
     
    }
   
    this.getTetkikGrubuOptions();
  }
  getTetkikGrubuOptions() {
    this.tetkikServices.GetTetkikGrubuList().then(o => {
  
      this.ddlTetkikGrubuOptions = o;
      console.log(o);
    });
  }
  
   onConfirm(st: any) {
    console.log(this.tetkikSaveModel);
    this.tetkikSaveModel.TetkikGrubu=this.tetkikGrupList.TetkikGrubuAdi
    this.tetkikSaveModel.TetkikGrupID=this.tetkikGrupList.ID
    // this.tetkikSaveModel.TetkikGrubu=this.ddlTetkikGrubuOptions.ID
    this.tetkikServices.Save(this.tetkikSaveModel).then(o => {
      this.onClose.next("confirm");
      this.bsModalRef.hide();
    });
     this.onClose.next("confirm");
     this.bsModalRef.hide();
  }
  onCancel(st: any) {
    this.onClose.next("cancel");
    this.bsModalRef.hide();
  }

}
