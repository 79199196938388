import { Component, OnInit, ElementRef, ViewChild, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-exam-result-odio-dialog',
  templateUrl: './exam-result-odio-dialog.component.html',
  styleUrls: ['./exam-result-odio-dialog.component.css']
})
export class ExamResultOdioDialogComponent implements OnInit,AfterViewInit {
  public control:Boolean;
  @ViewChildren('tetkik') parents: QueryList<any>;
  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService, private modalService: BsModalService) {
     
    for (var i = 0; i < 24; i++) {
      this.ddlValues.push((i + 1) * 5);
    }
  }
  ngAfterViewInit(): void {
  
  }
  public eventClose: Subject<any> = new Subject()
  isNewRecord: boolean;
  ddlValues: any[] = [];
  ngOnInit() {
  
    this.data.getByID(this.dialogParams.tetkikID).subscribe(o => {

      if (!o)
        return;
      if (!o.Data)
        this.isNewRecord = true;
      else {
        let dt = o.Data;
        debugger;
        this.frmOdioResult.DB500.sag = dt.sag500;
        this.frmOdioResult.DB1000.sag = dt.sag1000;
        this.frmOdioResult.DB2000.sag = dt.sag2000;
        this.frmOdioResult.DB4000.sag = dt.sag4000;
        this.frmOdioResult.DB8000.sag = dt.sag8000;
        this.frmOdioResult.DB250.sag = dt.sag250;
        this.frmOdioResult.DB250.sol = dt.sol250;
        this.frmOdioResult.DB500.sol = dt.sol500;
        this.frmOdioResult.DB1000.sol = dt.sol1000;
        this.frmOdioResult.DB2000.sol = dt.sol2000;
        this.frmOdioResult.DB4000.sol = dt.sol4000;
        this.frmOdioResult.DB8000.sol = dt.sol8000;
      }


    });
  }
  public dialogParams: any = {
    //tetkikID: 3981877
    tetkikID: 0
  }
  public frmOdioResult: ExamResultOdio.OdioResult = new ExamResultOdio.OdioResult();

  public errors: [] = [];

  data: any = {
    save: (frm: ExamResultOdio.OdioResult) => {
      var par = {
        "sag1000": frm.DB1000.sag,
        "sag2000": frm.DB2000.sag,
        "sag4000": frm.DB4000.sag,
        "sag8000": frm.DB8000.sag,
        "sag250": frm.DB250.sag,
        "sag500": frm.DB500.sag,
        "sol1000": frm.DB1000.sol,
        "sol2000": frm.DB2000.sol,
        "sol4000": frm.DB4000.sol,
        "sol8000": frm.DB8000.sol,
        "sol250": frm.DB250.sol,
        "sol500": frm.DB500.sol,
        "TetkikID": this.dialogParams.tetkikID,
      }

      return this.apiservice.callPostService("PersonelTetkik/SaveOdioResult", par)
    },
    getByID: (id: number) => {
      var par = {
        TetkikID: id
      };
      return this.apiservice.callPostService("PersonelTetkik/GetOdioResultByID", par);
    }
  }

  onCancel = () => {
    this.bsModalRef.hide();
  }
  public onSave = () => {
    if (this.validate().length > 0) {
      return;
    }

    this.data.save(this.frmOdioResult).subscribe((o: any) => {
      if (!o.Data)
        return;

      this.eventClose.next('success');
      this.bsModalRef.hide();

    });
    return false;
  };

  odioControl(item){
    if(isNaN(item))
    return false
    return item==0 || item%5!=0 || item>120;
  }
  
  onKeyUp($event){
  
     if($event.keyCode!=13)
      return;
    let items= this.parents.map(o=>o.nativeElement);
    var ix=items.indexOf($event.currentTarget);
    if(ix==-1 || (ix+1)==items.length){
      return;
    }
    if(ix==10)
    items[1].focus();
    else
    items[ix+2].focus();
  }
  validate(): any {
    //TODO
    return [];
    this.errors = []
    var err = this.errors;
    
    return this.errors;
  };

}
export namespace ExamResultOdio {

  export class OdioResult {

    DB1000: resultTuple = new resultTuple();
    DB2000: resultTuple = new resultTuple();
    DB4000: resultTuple = new resultTuple();
    DB8000: resultTuple = new resultTuple();
    DB250: resultTuple = new resultTuple();
    DB500: resultTuple = new resultTuple();
  };
  export class resultTuple {
    sag: number ;
    sol: number ;
  };
}
