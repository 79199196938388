import { Component, OnInit, Input } from '@angular/core';
import { TetkikModel } from '../../../../models/TetkikModel';
import { TetkikTanimlamaServices } from '../../../../services/TetkikTanimlamaService';

@Component({
  selector: 'app-tetkikdetailtab',
  templateUrl: './tetkikdetailtab.component.html',
  styleUrls: ['./tetkikdetailtab.component.css']
})
export class TetkikdetailtabComponent implements OnInit {

  tetkikDetailModel: any;
  @Input() set tetkikId(value: any) {
    if (value == undefined)
      return;
    let model = new TetkikModel();
    model.ID = value
    this.tetkikServices.GetTetkikById(model).then(o => {
      this.tetkikDetailModel = o;
    })
  }
  constructor( private tetkikServices : TetkikTanimlamaServices) { }

  ngOnInit() {
  }

}
