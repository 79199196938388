import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { Grid } from 'src/app/shared/models/UIControls/grid-control';
import { TetkikGrubuViewModel } from '../../../definitions/models/TetkikGurubuViewModel';
import { ddlSettings } from '../../modals/personeltetkikmodal/ddlSettings';
import { TetkikResultFilter } from '../../models/TetkikResultFilter';
import { PersonelTetkikIslemServices } from '../../services/PersonelTetkikIslemService';
import { OsgbViewModel } from '../../models/OsgbViewModel';
import { PermissionViewModel } from 'src/app/shared/models/PermissionViewModel';
import { authService } from 'src/app/Services/authService';
import { OsgbFirmaServices } from '../../services/OsgbFirmaService';
import { RaporResultItemlistModalComponent } from '../../modals/rapor-result-itemlist-modal/rapor-result-itemlist-modal.component';
import { BsModalService } from 'ngx-bootstrap';
import { PersonelTetkikServices } from '../../services/PersonelTetkikService';
import { TetkikResultModel } from '../../models/TetkikResultModel';
moment.locale('tr');
@Component({
  selector: 'app-get-results',
  templateUrl: './get-results.component.html',
  styleUrls: ['./get-results.component.css'],
})
export class GetResultsComponent implements OnInit {
  grid: ResultsComponent_Models.GridResults = new ResultsComponent_Models.GridResults(this.dataRapor,this.modalService);
  ranges: any = {
    'Bugün': [moment(), moment()],
    'Dün': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Son 7 gün': [moment().subtract(6, 'days'), moment()],
    'Son 30 gün': [moment().subtract(29, 'days'), moment()],
    'Bu Ay': [moment().startOf('month'), moment().endOf('month')],
    'Önceki Ay': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  public getTetkikGrupList: Array<TetkikGrubuViewModel>;
  public ddlSettings: ddlSettings = new ddlSettings();
  public ddlOSGBSettings;
  public ddlOSGBList: Array<OsgbViewModel>;
  public ddlOSGBSelectedItems = [];
  tetkikResult: TetkikResultModel = new TetkikResultModel();
  tarihRange: { start: moment.Moment, end: moment.Moment };
  ddlYazdirOptions = [{ id: null, name: "Yazdırılma Durumu" }, { id: false, name: "Yazdırılmamış" }, { id: true, name: "Yazdırılmış" }];
  permission: PermissionViewModel = new PermissionViewModel();
  constructor(public dataRapor: PersonelTetkikIslemServices, private authService: authService, private osgbFirmaServices: OsgbFirmaServices,
    public modalService: BsModalService,private sonucAl: PersonelTetkikServices) {
    this.authService.Ready.then(o => {
      this.permission = o;
    });
  }

  ngOnInit() {
    this.ddlOSGBSettings = this.ddlSettings.ddlOSGBListSettings;
    this.getTetkikGruplist();
    this.getOSGBListOptions();
    this.grid.filter.filter.Yazdirilanlar = null;
    this.grid.filter.filter.BasTarih = null;
    this.grid.filter.filter.BitTarih = null;
    this.grid.filter.filter.TetkikGrubuID = "";
    this.grid.filter.filter.IsPatolojik=false;
    this.grid.filter.filter.Reddedilenler=false;

  }
  getTetkikGruplist() {
    this.dataRapor.GetTetkikGrubuList().then(dataTetkik => {
      this.getTetkikGrupList = dataTetkik;
    })
  }
  getOSGBListOptions() {
    this.osgbFirmaServices.GetOsgbList().then(o => {
      this.ddlOSGBList = [];
      this.ddlOSGBList = o;
    })
  }
  onFilter(){
    if (this.tarihRange.start == null) {
      this.grid.filter.filter.BasTarih = null
      this.grid.filter.filter.BitTarih = null
    }
    else {
      this.grid.filter.filter.BasTarih = this.tarihRange.start;
      this.grid.filter.filter.BitTarih = this.tarihRange.end;
    }
    this.grid.filter.filter.OsgbIDList = [];
    this.ddlOSGBSelectedItems.forEach(item => {
      this.grid.filter.filter.OsgbIDList.push(item.Id)
    })
    this.grid.onRefresh();
  }
  onTetkikOnayKaldir() {
    if (!this.grid.hasSelectedItem())
      return;
    var idList = this.grid.selectedItems.map(o => (o as TetkikResultFilter).IslemID);
    this.tetkikResult.islemIDList = idList;
    this.sonucAl.GetTekrarOnayIste(this.tetkikResult).then(o => {
      this.grid.onRefresh();
    });

  }

}
namespace ResultsComponent_Models {

  export class GridResults extends Grid.GridControl<any> {
    public direction: number = 1;
    pager: number[] = [10, 25, 50, 100, 150, 300];
    constructor(public dataRapor: PersonelTetkikIslemServices,public modalService: BsModalService) {
      super();
    }
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    filter = new Grid.GridInputModel<TetkikResultFilter>(new TetkikResultFilter());
    getFilter() {

      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;
      this.filter.filter.Islem = 4;



      return this.filter;

    };

    onRefresh() {
      var item = this.getFilter();
      return this.dataRapor.getTetkikList(item).then(o => {
        this.data = o;

      })

    };

    onGetResult(select) {
      this.filter.filter.IslemIDList = this.selectedItems.map(o => (o as TetkikResultFilter).IslemID);
      this.filter.filter.PdfMi
      if (select == 1) {
        this.filter.filter.PdfMi = true;
        this.dataRapor.RaporResult(this.filter).then(o => {
        })
      }
      if (select == 2) {
        let itemList: [] = []
        const initialState = { itemList: itemList }
        let modal = this.modalService.show(RaporResultItemlistModalComponent, { initialState, ignoreBackdropClick: true });
        modal.content.onClose.subscribe(res => {
          this.filter.filter.PdfMi = false;
          this.filter.filter.ItemList = res;
          this.dataRapor.RaporResult(this.filter).then(o => {
          });
        });
      }

    }
    onGetResultExcel() {
      this.filter.filter.IslemIDList = this.selectedItems.map(o => (o as TetkikResultFilter).IslemID);
      this.filter.filter.PdfMi
      this.dataRapor.ExcelExportResult(this.filter).then(o => {
      })

    }
  }

}