import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
import { TetkikResultFilter } from '../models/TetkikResultFilter';
import { TetkikSonucViewModel } from '../models/TetkikSonucViewModel';
import { TetkikResultModelByIslemID } from '../models/TetkikResultModelByIslemID';
import { AkcigerModel } from '../models/AkcigerModel';
import { SaveTopluAkcigerModel } from '../models/SaveTopluAkcigerModel';
import { ImzalamaModel } from '../models/ImzalamaModel';
import { ImzalamaRedModel } from '../models/ImzalamaRedModel';
import { LaboratuvarSonucSaveFilter } from '../models/LaboratuvarSonucSaveFilter';
import { LaboratuvarViewModel } from '../models/LaboratuvarViewModel';
import { LabSonucSaveModel } from '../models/LabSonucSaveModel';
import { LabOnayModel } from '../models/LabOnayModel';
import { GozImzalaVeOnaylaModel } from '../models/GozImzalaVeOnaylaModel';
import { SaveTopluLaboratuvarSonucModel } from '../models/SaveTopluLaboratuvarSonucModel';
import { BarkodModelByTetkikIDList } from '../models/BarkodModelByTetkikIDList';
import { ImportViewModel } from '../models/ImportViewModel';
import { AkcigerSonucByIDViewModel } from '../models/AkcigerSonucByIDViewModel';
import { SaveGozKiranModel } from '../models/SaveGozKiranModel';
import { GozKiranViewModel } from '../models/GozKiranViewModel';
import { TetkikGrubuViewModel } from '../../definitions/models/TetkikGurubuViewModel';
import { EmailIcerikModel } from '../models/EmailIcerikModel';
import { RaporOnayRedViewModel } from '../models/RaporOnayRedViewModel';
import { SentMailFilter } from '../models/SentMailFilter';
import { NameViewModel } from '../models/NameViewModel';


@Injectable({
  providedIn: 'root'
})
export class PersonelTetkikIslemServices {

  constructor(private api: ApiService) { }
 
  public getTetkikList(model: Grid.GridInputModel<TetkikResultFilter>): Promise<Grid.GridOutputModel<TetkikSonucViewModel>> {
    return this.api.callService("PersoneltetkikIslem/GetTetkikList", model).then(o => {
      var res = new Grid.GridOutputModel<TetkikSonucViewModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
    });
  }

  public GetTetkikResult(model: Grid.GridInputModel<TetkikResultFilter>): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/GetTetkikResult", model).then(o => {
      this.api.fileOperation.download(o);
    });
  }

  public ExportPdfTetkikResultByID(model: TetkikResultModelByIslemID): Promise<string> {
    return this.api.callService("PersonelTetkikIslem/ExportPdfTetkikResultByID", model).then(o => {
      return o as string;
    });
  }
  public SaveAkcigerSonuc(model: AkcigerModel): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/SaveAkcigerSonuc", model).then(o => {
      return o;
    });
  }
  public GetAkcigerSonucByID(model: AkcigerSonucByIDViewModel): Promise<AkcigerModel> {
    return this.api.callService("PersonelTetkikIslem/GetAkcigerSonucByID", model).then(o => {
      return o as AkcigerModel;
    });
  }
  public SaveTopluAkcigerSonuc(model: SaveTopluAkcigerModel): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/SaveTopluAkcigerSonuc", model).then(o => {
      return o;
    });
  }
  public GetRaporImzalama(model: ImzalamaModel): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/GetRaporImzalama", model).then(o => {
      return o;
    });
  }
  public GetSonucRed(model: ImzalamaRedModel): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/GetSonucRed", model).then(o => {
      return o;
    });
  }
  public SentMail(model: SentMailFilter): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/SentMail", model).then(o => {
      return o;
    });
  }
  public GetEmailDetail(model: EmailIcerikModel): Promise<RaporOnayRedViewModel> {
    return this.api.callService("PersonelTetkikIslem/GetEmailDetail", model).then(o => {
      return o as RaporOnayRedViewModel;
    });
  }
  public GetLabTetkikSonucList(model: LaboratuvarSonucSaveFilter): Promise<LaboratuvarViewModel[]> {
    return this.api.callService("PersonelTetkikIslem/GetLabTetkikSonucList", model).then(o => {
      return o as LaboratuvarViewModel[];
    });
  }
  public GetLabTetkikOnayList(model: LaboratuvarSonucSaveFilter): Promise<LaboratuvarViewModel[]> {
    return this.api.callService("PersonelTetkikIslem/GetLabTetkikOnayList", model).then(o => {
      return o as LaboratuvarViewModel[];
    });
  }
  public SaveLabSonuc(model: LabSonucSaveModel): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/SaveLabSonuc", model).then(o => {
      return o;
    });
  }
  public GetLabTetkikOnayla(model: LabOnayModel): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/GetLabTetkikOnayla", model).then(o => {
      return o;
    });
  }
  public GetImzalaVeOnayla(model: GozImzalaVeOnaylaModel): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/GetImzalaVeOnayla", model).then(o => {
      return o;
    });
  }
  public GetTetkikGrubuList(): Promise<TetkikGrubuViewModel[]> {
    return this.api.callService("Tetkik/GetTetkikGrubuList",null).then(o => {
      return o as TetkikGrubuViewModel[];
    });
  };
  public SaveTopluKanSonuc(model: SaveTopluLaboratuvarSonucModel): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/SaveTopluKanSonuc", model).then(o => {
      return o;
    });
  }
  public Export(model: BarkodModelByTetkikIDList): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/Export", model).then(o => {
      this.api.fileOperation.download(o);
    });
  }
  public Import(model: ImportViewModel): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/Import", model).then(o => {
      return o;
    });
  }
  public RaporResult(model: Grid.GridInputModel<TetkikResultFilter>): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/RaporResult", model).then(o => {
      this.api.fileOperation.download(o);
    });
  };
  public ExcelExportResult(model: Grid.GridInputModel<TetkikResultFilter>): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/ExcelExportResult", model).then(o => {
      this.api.fileOperation.download(o);
    });
  }
  public SaveGozKiran(model: SaveGozKiranModel): Promise<any> {
    return this.api.callService("PersonelTetkikIslem/SaveGozKiran", model).then(o => {
      return o;
    });
  }
  public GetGozKiranByIslemID(model: TetkikResultModelByIslemID): Promise<GozKiranViewModel> {
    return this.api.callService("PersonelTetkikIslem/GetGozKiranByIslemID", model).then(o => {
      return o as GozKiranViewModel;
    });
  }
  public GetDizaynList(): Promise<NameViewModel[]> {
    return this.api.callService("PersonelTetkikIslem/GetDizaynList", null).then(o => {
      return o as NameViewModel[];
    });
  }
}
