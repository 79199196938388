import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { DepartmentListViewModel } from '../models/DepartmentListViewModel';
import { DepartmentViewModel } from '../models/DepartmentViewModel';
import { NameViewModel } from '../models/NameViewModel';
import { OsgbViewModel } from '../models/OsgbViewModel';
import { SaveDepartmentViewModel } from '../models/SaveDepartmentViewModel';
@Injectable({
  providedIn: 'root'
})
export class OsgbFirmaServices {

  constructor(private api: ApiService) { }
  public GetOsgbList(): Promise<OsgbViewModel[]> {
    return this.api.callService("FirmaOsgb/GetOsgbList", undefined).then(o => {
      return o as OsgbViewModel[];
    });
  }
  public GetDepartmentByFirmaID(model: DepartmentListViewModel): Promise<NameViewModel[]> {
    return this.api.callService("FirmaOsgb/GetDepartmentByFirmaID", model).then(o => {
      return o as NameViewModel[];
    });
  }
  public SaveDepartment(model: SaveDepartmentViewModel): Promise<number> {
    return this.api.callService("FirmaOsgb/SaveDepartment", model).then(o => {
      return o as number;
    });
  }
  public DeleteDepartment(model: DepartmentViewModel): Promise<number> {
    return this.api.callService("FirmaOsgb/DeleteDepartment", model).then(o => {
      return o as number;
    });
  }
  public GetByDepartmentID(model: DepartmentViewModel): Promise<SaveDepartmentViewModel> {
    return this.api.callService("FirmaOsgb/GetByDepartmentID", model).then(o => {
      return o as SaveDepartmentViewModel;
    });
  }
}
