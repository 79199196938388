export class TetkikViewFilter {
  Id: any;
  TetkikGrubu: any;
  TetkikAdi: any;
  Ucret: any;
  OrnekTuru: any;
  DizaynAdi: any;
  GozMu: any;
  OSGBF: any;
  OSGBF2: any;
  FirmaF: any;
  Firma2: any;
  idList: any[];
  PdfMi: any;
  Aktif: any;
}
