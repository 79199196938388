import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
import { CariRaporViewFilter } from '../models/CariRaporViewFilter';
import { CariRaporViewModel } from '../models/CariRaporViewModel';
@Injectable({
  providedIn: 'root'
})
export class CariRaporServices {

  constructor(private api: ApiService) { }
  
  public GetCariRaporList(model: Grid.GridInputModel<CariRaporViewFilter>): Promise<Grid.GridOutputModel<CariRaporViewModel>> {
    return this.api.callService("CariRapor/GetCariRaporList", model).then(o => {
      var res = new Grid.GridOutputModel<CariRaporViewModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
    });
  };
  public ExportExcelCariIslemList(model: Grid.GridInputModel<CariRaporViewFilter>): Promise<any> {

    return this.api.callService("CariRapor/ExportExcelCariIslemList", model).then(o => {
      this.api.fileOperation.download(o);
    });
  }

}
