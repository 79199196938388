export class UserViewFilter {
  Id: any;
  KullaniciAdi: any;
  AdSoyad: any;
  Email: any;
  OsgbId: any;
  OsgbAdi: any;
  Aktif: any;
  Kaydeden: any;
  Degistiren: any;
  DegistirmeTarihi: any;
  KayitTarihi: any;
  UserTypeID: any;
}
