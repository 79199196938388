import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpEvent,
} from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { CustomHttpParams } from 'src/app/Services/costumHttpParams';


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private totalRequests = 0;
    constructor(private spinnerservice: NgxSpinnerService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.totalRequests++;
  
        if(req.params instanceof CustomHttpParams){
            if(!req.params.ignoreLoadingSpinner){
                this.spinnerservice.show();
            }
        }
        else{
            this.spinnerservice.show();
        }
    
        return next.handle(req).pipe(
            finalize(() => {
              
                this.totalRequests--;
                if (this.totalRequests === 0) {
                    // console.log('http finished');
                    this.spinnerservice.hide();
                }
            })
        );
    }
}

