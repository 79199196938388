import { Component, OnInit, Input } from '@angular/core';
import { TetkikTanimlamaServices } from '../../../../services/TetkikTanimlamaService';
import { Grid } from '../../../../../../../shared/models/UIControls/grid-control';
import { LabTetkikFilter } from '../../../../models/LabTetkikFilter';
import { ConfirmationDialogComponent } from '../../../../../../../shared/modals/confirmation-dialog/confirmation-dialog.component';
import { BsModalService } from 'ngx-bootstrap';
import { SaveLabtetkikComponent } from '../../../../modals/save-labtetkik/save-labtetkik.component';
import { TetkikModel } from '../../../../models/TetkikModel';

@Component({
  selector: 'app-labtetkiklist',
  templateUrl: './labtetkiklist.component.html',
  styleUrls: ['./labtetkiklist.component.css']
})
export class LabtetkiklistComponent implements OnInit {

  @Input() set tetkikId(value: any) {
    if (value == undefined)
      return;
    this.labTetkikFilter.TetkikId = value;
    this.tempTetkikId = value;
    this.gridLabTetkik.onRefresh();
  }

  constructor(private tetkikServices: TetkikTanimlamaServices, private modalService: BsModalService) { }

  public tempTetkikId: any = undefined;
  tetkikModel: TetkikModel = new TetkikModel();

  ngOnInit() {
  }
  labTetkikFilter: TetkikLabFilter = new TetkikLabFilter();

  gridLabTetkik: LabTetkikListComponent_Models.GridLabTetkik = new LabTetkikListComponent_Models.GridLabTetkik(this.tetkikServices, this.labTetkikFilter);

  onLabTetkikSave() {
    const initialState = {
      tetkikID: this.tempTetkikId,
      labTetkikID: undefined
    };
    let modal = this.modalService.show(SaveLabtetkikComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          this.gridLabTetkik.onRefresh();
        }
      }, err => {
        console.log(err);
      }
    );
  }
  onLabTetkikUpdate(clickeditem) {
    const initialState = {
      tetkikID: this.tempTetkikId,
      labTetkikID: clickeditem.Id
    };
    let modal = this.modalService.show(SaveLabtetkikComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          this.gridLabTetkik.onRefresh();
        }
      }, err => {
        console.log(err);
      }
    );
  }
  onLabTekikDelete() {
    const initialState = {
      message: "Tetkiği silmek istediğinize emin misiniz",
    };
    let modal = this.modalService.show(ConfirmationDialogComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          let item = this.gridLabTetkik.clickedItem;
          this.tetkikModel.ID = item.Id;
          this.tetkikServices.LabTetkikDelete(this.tetkikModel).then(o => {
            this.gridLabTetkik.onRefresh();
          })
        }
      }, err => {
        console.log(err);
      }
    );
  }
}

export class TetkikLabFilter {
  Id: any;
  TetkikId: any;
  AltTetkikKodu: any;
  AltTetkikAdi: any;
  AsagiSonuc: any;
  AralikSonuc: any;
  YukariSonuc: any;
  DegerMin: any;
  DegerMax: any;
  DegerDizi: any;
  Referans: any;
  Birimi: any;
  Aciklama: any;
  Aktif: any;
  Kaydeden: any;
  KayitTarihi: any;
  Degistiren: any;
  DegTarihi: any;
}

namespace LabTetkikListComponent_Models {

  export class GridLabTetkik extends Grid.GridControl<any> {
    public direction: number = 1;
    constructor(private tetkikServices: TetkikTanimlamaServices, private labTetkikFilter: TetkikLabFilter) {
      super();
    }
    filter = new Grid.GridInputModel(new LabTetkikFilter());
    getFilter() {

      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;

      let item = this.filter.filter;
      var f = this.labTetkikFilter;

      
      item.ID = f.Id;
      item.TetkikID = f.TetkikId;
      item.AltTetkikKodu = f.AltTetkikKodu;
      item.AltTetkikAdi = f.AltTetkikAdi;
      item.AsagiSonuc = f.AsagiSonuc;
      item.AralikSonuc = f.AralikSonuc;
      item.YukariSonuc = f.YukariSonuc;
      item.DegerMin = f.DegerMin;
      item.DegerMax = f.DegerMax;
      item.DegerDizi = f.DegerDizi;
      item.Referans = f.Referans;
      item.Birimi = f.Birimi;
      item.Aciklama = f.Aciklama;
      item.Aktif = f.Aktif;
      item.Kaydeden = f.Kaydeden;
      item.KaydedenTarihi = f.KayitTarihi;
      item.Degistiren = f.Degistiren;
      item.DegistirenTarihi = f.DegTarihi;
      
      return this.filter;
    };
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    onRefresh() {
      var item = this.getFilter()
      console.log(item);
      var filter = item.filter;
      this.tetkikServices.GetLabTetkikList(item).then(o => {
        console.log(o);
        this.data = o;
      });
    }
  }

}
