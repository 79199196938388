import { Component, OnInit, ViewChild } from '@angular/core';
import { KullanicilistComponent } from './kullanicilist/kullanicilist.component';

@Component({
  selector: 'app-kullanici',
  templateUrl: './kullanici.component.html',
  styleUrls: ['./kullanici.component.css']
})
export class KullaniciComponent implements OnInit {

  @ViewChild(KullanicilistComponent, { static: false })
  public kullaniciListComponent: KullanicilistComponent;

  constructor() { }

  ngOnInit() {
  }
}


