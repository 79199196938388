import { Component, OnInit } from '@angular/core';
import { IslemRaporServices } from '../../transactions/services/IslemRaporService';
import { PersonelTetkikServices } from '../../transactions/services/PersonelTetkikService';
import { PersonelServices } from '../../transactions/services/PersonelService';
import { AuthenticationService } from '../../../../shared/services/Data/AuthenticationService';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
import { TetkikTanimlamaServices } from '../../definitions/services/TetkikTanimlamaService';
import { LabTetkikFilter } from '../../definitions/models/LabTetkikFilter';
import { DeleteByFirmaIDModel } from '../../transactions/models/DeleteByFirmaIDModel';
import { DeleteModel } from '../../transactions/models/DeleteModel';
import { DeleteByPersonelListModel } from '../../transactions/models/DeleteByTetkikListModel';
import { RaporModel } from '../../transactions/models/RaporModel';
import { PersonelViewFilter } from '../../transactions/models/PersonelViewFilter';
import { IslemListInput } from '../../transactions/models/IslemListInput';
import { TetkikResultFilter } from '../../transactions/models/TetkikResultFilter';



@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
    abc: any;


  constructor(
    private rapor: IslemRaporServices,
    private tetkikgrid: PersonelTetkikServices,
    private personel: PersonelServices,
    private user: AuthenticationService,
    private tetkikTanimlama: TetkikTanimlamaServices) {

  }
  public onChange(item: any) {    
    this.abc = item.KurumAd;
    //item.OsgbID
    //item.FirmaID;
  }

  ngOnInit() {
    var x = JSON.parse('{"List":[{"ID":1525339,"OsgbID":1,"FirmaID":31907,"PersonelKimlikNo":1512802,"TCKNo":"","PersonelSicilNo":"","PersonelAdi":"eda anıl","PersonelSoyadi":null,"Doktor":null,"OdemeSekli":null,"DogumTarihi":"2020-02-19T12:34:51.617","Cinsiyet":"E","GonderiNo":null,"FaturaDurumu":null,"CepTel":"+90","Aktif":null,"Kaydeden":null,"KayitTarihi":"2020-02-19T15:34:53.667","Degistiren":null,"DegTarihi":null,"FatNak":"F","TumTarihtekiTetkikler":false},{"ID":1525338,"OsgbID":1,"FirmaID":31907,"PersonelKimlikNo":1512801,"TCKNo":"","PersonelSicilNo":"","PersonelAdi":"mustaf ataş","PersonelSoyadi":null,"Doktor":null,"OdemeSekli":null,"DogumTarihi":"2020-02-19T12:32:45.56","Cinsiyet":"E","GonderiNo":null,"FaturaDurumu":null,"CepTel":"+90","Aktif":null,"Kaydeden":null,"KayitTarihi":"2020-02-19T15:32:46.347","Degistiren":null,"DegTarihi":null,"FatNak":"F","TumTarihtekiTetkikler":false},{"ID":1525337,"OsgbID":1,"FirmaID":31907,"PersonelKimlikNo":1512800,"TCKNo":"","PersonelSicilNo":"","PersonelAdi":"medlife kişi","PersonelSoyadi":null,"Doktor":null,"OdemeSekli":null,"DogumTarihi":"2020-02-19T12:30:01.897","Cinsiyet":"E","GonderiNo":null,"FaturaDurumu":null,"CepTel":"+90","Aktif":null,"Kaydeden":null,"KayitTarihi":"2020-02-19T15:31:54.86","Degistiren":null,"DegTarihi":null,"FatNak":"F","TumTarihtekiTetkikler":false}],"TotalCount":3}');
    //this.Test_ExportPdfTetkikResultByID();

    //this.personnelGrid.data = new Grid.IGridOutputModel();
    //this.Test_GetLabTetkikList()
  }
  TEST() {


  }
  /*Test_ExportPdfTetkikResultByID() {
    this.tetkik.ExportPdfTetkikResultByID(3978415).then(o => {
      console.log(o);
    });
  }*/
  Test_GetLabTetkikList() {
    var input = new LabTetkikFilter();
    input.TetkikID = 513;
    var model = new Grid.GridInputModel(input);
    this.tetkikTanimlama.GetLabTetkikList(model).then(o => {
      console.log(o);
    });
  }
  Test_GetPermission() {
    this.user.getPermission().then(o => {
      console.log(o);
    });
  }
  // Test_LaboratuvarDeger() {
  //   var input = new LaboratuvarSonucSaveFilter();
  //   input.PersonelID = 1525325;
  //   input.TetkikGrubu = "Kültürler";
  //   input.IslemID = 0;
  //   this.tetkikıslem.GetLabTetkikDegerListByID(input).then(o => {
  //     console.log(o);
  //   });
  // }
  Test_DeleteByFirmaID() {
    var input = new DeleteByFirmaIDModel();
    input.firmaID = 31906;
    this.tetkikgrid.DeleteByFirmaID(input).then(o => {
      console.log(o);
    });
  }
  Test_DeleteByPersonelList() {
    var input = new DeleteByPersonelListModel();
    input.personelIDList = [1525350];
    this.tetkikgrid.DeleteByPersonelList(input).then(o => {
      console.log(o);
    });
  }
  // TEST_RaporResult() {
  //   var input = new RaporModel();
  //   input.PersonelIslemIDList = [2431373];

  //   this.tetkikgrid.RaporResult(input).then(o => {
  //     console.log(o);
  //   });
  // }
  TEST_Rapor() {
    var input = new IslemListInput();
    input.osgbyeGore = true;
    input.baslangicTarihi = new Date("2020-01-01");
    input.bitisTarihi = new Date("2020-03-05");
    var model = new Grid.GridInputModel(input);

    this.rapor.getIslemList(model).then(o => {
      console.log(o);
    });
  }
  TEST_PersonelList() {
    var input = new PersonelViewFilter();
    //input.FirmaID =54;
    // input.BasTarih = new Date("2020-03-05");
    // input.BitTarih = new Date("2020-03-06");
    var model = new Grid.GridInputModel(input);

    this.personel.getPersonelList(model).then(o => {
      console.log(o);
    });
  }
  TEST_GetTetkikGruplariList() {
    var input = new DeleteByPersonelListModel();
    input.personelIDList = [1525054];

    this.tetkikgrid.GetTetkikGruplariList(input).then(o => {
      console.log(o);
    });
  }
}
