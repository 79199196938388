import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { DataManagerService } from 'src/app/Services/dataManager/data-manager.service';
import { switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-save-pl-bypdf',
  templateUrl: './save-pl-bypdf.component.html',
  styleUrls: ['./save-pl-bypdf.component.css']
})
export class SavePlBypdfComponent implements OnInit {

  public uploadFileUrl : string = "FileOperation/Upload";
  public savePersonelByPdfUrl : string = "Personel/SaveByPDF";

  public model : any = {
    firmaID : undefined,
    tetkikID : undefined,
    tetkikTuru : undefined,
    fileIDList : []
  };

  public selectedFiles: any[] = [];


  public disableUpload : boolean = true;

  constructor(public bsModalRef: BsModalRef,private apiservice : ApiService,private datamanager : DataManagerService,private modalService: BsModalService) { }

  ngOnInit() {
    this.model.firmaID = this.datamanager.getFirmaID();
  }


  public handleFileChoose(event) {
    this.selectedFiles = [];
      for (let [key, value] of Object.entries(event.target.files)) {
          // console.log(key, value);
          this.selectedFiles.push(value);
      }
    this.disableUpload = false;
  }

  onUploadFiles() {
    this.model.fileIDList=[]
    for (var i = 0; i < this.selectedFiles.length; i++) {
      var item = this.selectedFiles[i];
      this.apiservice.callFormDataService(this.uploadFileUrl, [item]).pipe(
        map(
          (resp: any) => {      
            this.model.fileIDList.push(resp.Data[0]);
          }
        )
      ).subscribe();
    }
    

  }

  public savePersonel(){
    //  console.log(this.model);
    this.apiservice.callPostService(this.savePersonelByPdfUrl,this.model).subscribe(
      (resp) =>{
        this.modalService.setDismissReason('save-personel');
        this.bsModalRef.hide();
      }
    );
  }

  public disableSaving(){
    if(this.model.fileIDList.length == 0 || this.model.tetkikTuru== undefined || this.model.tetkikID == undefined){
      return true;
    }
    return false;
  }

}
