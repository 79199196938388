import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Grid } from '../../../../../../shared/models/UIControls/grid-control';
import { ConfirmationDialogComponent } from '../../../../../../shared/modals/confirmation-dialog/confirmation-dialog.component';
import { BsModalService } from 'ngx-bootstrap';

import { UserViewFilter } from '../../../models/UserViewFilter';
import { KullaniciTanımlamaService } from '../../../services/KullaniciTanımlamaService';
import { UserDetayModel } from '../../../models/UserDetayModel';
import { SaveKullaniciComponent } from '../../../modals/save-kullanici/save-kullanici.component';
import { fromEvent } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-kullanicilist',
  templateUrl: './kullanicilist.component.html',
  styleUrls: ['./kullanicilist.component.css']
})
export class KullanicilistComponent implements OnInit {
  userDetayModel: UserDetayModel = new UserDetayModel();
  constructor(private userService: KullaniciTanımlamaService, private modalService: BsModalService) { }
  ddlUserType=[{id:1,name:"Laboratuvar"},{id:2,name:"Osgb"},{id:3,name:"Firma"},{id:4,name:"Personel"}]
  @ViewChild('filterSection', { static: false }) input: ElementRef;
  ngOnInit() {
    this.gridKullanici.onRefresh();
  }
  ngAfterViewInit() {

    let tableDom = this.input.nativeElement;
    let items = tableDom.querySelectorAll('.filterInput');
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      fromEvent(item, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(2000),
          distinctUntilChanged(),
          tap((text) => {
            this.gridKullanici.onRefresh();
          })
        )
        .subscribe();
    }

  }
  kullaniciFilter: KullaniciFilter = new KullaniciFilter();
  gridKullanici: KullaniciListComponent_Models.GridKullanici = new KullaniciListComponent_Models.GridKullanici(this.userService, this.kullaniciFilter);

  onKullaniciSave() {
    const initialState = {
      kullaniciId: undefined
    };
    let modal = this.modalService.show(SaveKullaniciComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          this.gridKullanici.onRefresh();
        }
      }, err => {
        console.log(err);
      }
    );
  }
  onKullaniciUpdate() {
    const initialState = {
      kullaniciId: this.gridKullanici.clickedItem.Id
    };
    let modal = this.modalService.show(SaveKullaniciComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          this.gridKullanici.onRefresh();
        }
      }, err => {
        console.log(err);
      }
    );
  }
  ddlUserTypeIDChange(item){
    let itemID = item.substr(item.indexOf(' ')+1);
    this.kullaniciFilter.UserTypeID = itemID;
    this.gridKullanici.onRefresh();
  }
  onKullaniciDelete() {
    const initialState = {
      message: "Kıllanıcıyı silmek istediğinize emin misiniz",
    };
    let modal = this.modalService.show(ConfirmationDialogComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          let item = this.gridKullanici.clickedItem;
          this.userDetayModel.ID = item.Id;
          this.userService.Delete(this.userDetayModel).then(o => {
            this.gridKullanici.onRefresh();
          });
        }
      }, err => {
        console.log(err);
      }
    );
  }
}
export class KullaniciFilter {
  Id: any;
  KullaniciAdi: any;
  AdiSoyadi: any;
  MailAdresi: any;
  OsgbAdi: any;
  UserTypeID:any;
}
namespace KullaniciListComponent_Models {

  export class GridKullanici extends Grid.GridControl<any> {

    public direction: number = 1;

    constructor(private userService: KullaniciTanımlamaService, private kullaniciFilter: KullaniciFilter) {
      super();
    }

    filter = new Grid.GridInputModel(new UserViewFilter());
    getFilter() {

      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;

      let item = this.filter.filter;
      var o = this.kullaniciFilter;

      item.Id = o.Id;
      item.KullaniciAdi = o.KullaniciAdi;
      item.AdSoyad = o.AdiSoyadi
      item.Email = o.MailAdresi
      item.OsgbAdi = o.OsgbAdi
      item.UserTypeID=o.UserTypeID;

      return this.filter;
    };
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    onRefresh() {
      var item = this.getFilter()
      var filter = item.filter;
      this.userService.GetUserList(item).then(o => {
        this.data = o;
      })
    }
  }

}
