import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
import { PersonelViewFilter } from '../models/PersonelViewFilter';
import { PersonelViewModel } from '../models/PersonelViewModel';
import { BarkodModelByTetkikIDList } from '../models/BarkodModelByTetkikIDList';
import { IslemListInput } from '../models/IslemListInput';
import { PersonelDetailModel } from '../models/PersonelDetailModel';
import { IslemIDListModel } from '../models/IslemIDListModel';
import { BarkodViewModel } from '../models/BarkodViewModel';
import { PersonelIDListModel } from '../models/PersonelIDListModel';
import { NameViewModel } from '../models/NameViewModel';
import { DepartmentListViewModel } from '../models/DepartmentListViewModel';
@Injectable({
  providedIn: 'root'
})
export class PersonelServices {

  constructor(private api: ApiService) { }
  
  public getPersonelList(model: Grid.GridInputModel<PersonelViewFilter>): Promise<Grid.GridOutputModel<PersonelViewModel>> {
    return this.api.callService("Personel/GetPersonelList", model).then(o => {
      var res = new Grid.GridOutputModel<PersonelViewModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
    });
  };
  public ExportExcelPersonelList(model: IslemListInput): Promise<any> {
    return this.api.callService("Personel/ExportExcelPersonelList", model).then(o => {
      this.api.fileOperation.download(o);
    });
  }
  public GetPersonelDetail(model: PersonelDetailModel): Promise<string> {
    return this.api.callService("Personel/GetPersonelDetail", model).then(o => {
      return o as string;
    });
  }
  public GetPersonelDetailByKimlikNo(model: PersonelDetailModel): Promise<string> {
    return this.api.callService("Personel/GetPersonelDetailByKimlikNo", model).then(o => {
      return o as string;
    });
  }
  public GetPersonelTetkikModalList(model: Grid.GridInputModel<PersonelViewFilter>): Promise<Grid.GridOutputModel<BarkodViewModel>> {
    return this.api.callService("Personel/GetPersonelTetkikModalList", model).then(o => {
      var res = new Grid.GridOutputModel<BarkodViewModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
    });
  }
  public GetBarkodBas(model: Grid.GridInputModel<PersonelViewFilter>): Promise<string> {
    return this.api.callService("Personel/GetBarkodBas", model).then(o => {
      return o as string;
    });
  }
  public GetBarkodla(model: Grid.GridInputModel<PersonelViewFilter>): Promise<string> {
    return this.api.callService("Personel/GetBarkodla", model).then(o => {
      return o as string;
    });
  }
 public GetBarkodByPersonelID(model: Grid.GridInputModel<PersonelViewFilter>): Promise<string> {
    return this.api.callService("Personel/GetBarkodByPersonelID", model).then(o => {
      return o as string;
    });
  }
  public GetPersonelBarkodBas(model: PersonelIDListModel): Promise<any> {
    return this.api.callService("Personel/GetPersonelBarkodBas", model).then(o => {
      this.api.fileOperation.download(o);
    });
  }
  public GetUserResult(model: PersonelIDListModel): Promise<any> {
    return this.api.callService("Personel/GetUserResult", model).then(o => {
      this.api.fileOperation.download(o);
    });
  }
  public GetKeyList(model: DepartmentListViewModel): Promise<NameViewModel[]> {
    return this.api.callService("Personel/GetKeyList", model).then(o => {
      return o as NameViewModel[];
    });
  }
  public GetAutoComplete(model: PersonelViewFilter): Promise<PersonelViewModel[]> {
    return this.api.callService("Personel/GetAutoComplete", model).then(o => {
      return o as PersonelViewModel[];
    });
  }
}
