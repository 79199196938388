import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, TabHeadingDirective } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { Subject } from 'rxjs';
import { PersonelTetkikIslemServices } from '../../../services/PersonelTetkikIslemService';
import { LaboratuvarSonucSaveFilter } from '../../../models/LaboratuvarSonucSaveFilter';
import { LaboratuvarViewModel } from '../../../models/LaboratuvarViewModel';
import { LabSonucSaveModel } from '../../../models/LabSonucSaveModel';

@Component({
  selector: 'app-exam-result-blood-dialog',
  templateUrl: './exam-result-blood-dialog.component.html',
  styleUrls: ['./exam-result-blood-dialog.component.css']
})
export class ExamResultBloodDialogComponent implements OnInit {
  [x: string]: any;
  tetkikID: any;
  islemControl:any;
  yorum: string;
  data: LaboratuvarViewModel[];
  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService, private modalService: BsModalService, private personeltetkikIslemService: PersonelTetkikIslemServices) {
    console.log(this);
  }
  public eventClose: Subject<any> = new Subject()
  yonOptions = [{ id: 0, name: ""},{ id: 1, name: ">"}, { id: 2, name: "<"},{ id:3, name: ">="}, { id: 4, name: "<="}];
  ngOnInit() {
    const labSonucModel = new LaboratuvarSonucSaveFilter();
      labSonucModel.PersonelID = this.dialogParams.personelId;
      labSonucModel.TetkikGrubu = this.dialogParams.tetkikGrubu;
      labSonucModel.IslemID=this.dialogParams.tetkikID; 
      
      labSonucModel.LaboratuvarIslem=this.dialogParams.laboratuvarIslem;
      this.personeltetkikIslemService.GetLabTetkikSonucList(labSonucModel).then((response: LaboratuvarViewModel[]) => {
        this.data = response;
      })  
      this.islemControl=this.dialogParams.islemControl;
  }

  onSave() {
      const saveModel = new LabSonucSaveModel();
      saveModel.Yorum = this.yorum;
      saveModel.list = this.data;
      

      this.personeltetkikIslemService.SaveLabSonuc(saveModel).then((response: boolean) => {
        console.log(response)
      },(err) => {
        console.log('hata var');
      })
   

      this.eventClose.next('success');
      this.bsModalRef.hide();
    
}}
