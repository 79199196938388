import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { IDViewModel } from '../../definitions/models/IDViewModel';
@Injectable({
  providedIn: 'root'
})
export class UserRolServices {

  constructor(private api: ApiService) { }

  public GetRolList(): Promise<any> {
    return this.api.callService("userRol/GetRolList", undefined).then(o => {
      return o;
    });
  }
  public GetUserByRolID(model:IDViewModel): Promise<any> {
    return this.api.callService("userRol/GetUserByRolID", model).then(o => {
      return o;
    });
  }
}
