import { Component, OnInit } from '@angular/core';
import { Subject, from } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { PersonelTetkikServices } from '../../../../services/PersonelTetkikService';
import { TetkikResultModel } from '../../../../models/TetkikResultModel';
import { PersonelTetkikIslemServices } from '../../../../services/PersonelTetkikIslemService';
import { LabSonucSaveModel } from '../../../../models/LabSonucSaveModel';
import { LaboratuvarViewModel } from '../../../../models/LaboratuvarViewModel';
import { LaboratuvarSonucSaveFilter } from '../../../../models/LaboratuvarSonucSaveFilter';



@Component({
  selector: 'app-exam-result-lab-dialog',
  templateUrl: './exam-result-lab-dialog.component.html',
  styleUrls: ['./exam-result-lab-dialog.component.css']
})
export class ExamResultLabDialogComponent implements OnInit {
  // public tetkikID: any;
  data: LaboratuvarViewModel[];
  public dialogParams: any = {
    tetkikID: 0
  }
  tetkikResultModel: TetkikResultModel = new TetkikResultModel();
   labSonucModel = new LaboratuvarSonucSaveFilter();
  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService, private personelTetkikServices: PersonelTetkikServices, private personeltetkikIslemService: PersonelTetkikIslemServices) {
    console.log(this);
  }
  yonOptions = [{ id: 0, name: ""},{ id: 1, name: ">"}, { id: 2, name: "<"},{ id:3, name: ">="}, { id: 4, name: "<="}];
  public eventClose: Subject<any> = new Subject()
  ngOnInit() {
    const tetkikResultModel = new TetkikResultModel();
    this.labSonucModel.IslemID = this.dialogParams.tetkikID
    this.personeltetkikIslemService.GetLabTetkikSonucList(this.labSonucModel).then((response: LaboratuvarViewModel[]) => {
      this.data = response;
     
    })
    
  }


  onSave() {
    const saveModel = new LabSonucSaveModel();

    saveModel.list = this.data;


    this.personeltetkikIslemService.SaveLabSonuc(saveModel).then((response: boolean) => {
      this.eventClose.next('success');
      this.bsModalRef.hide();
    }, (err) => {
      console.log('hata var');
    })

  }
  onCancel() {
    this.eventClose.next('cancel');
    this.bsModalRef.hide();
  }
}
