import { TahsilatViewModel } from './TahsilatViewModel';

export class SaveOdemeAlModel {
  FirmaID: any;
  OsgbID: any;
  PersonelID: any;
  Nakit: any;
  Fatura: any;
  Kredi: any;
  GenelToplam: any;
  gridList: TahsilatViewModel[];
}
