import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './features/public/login/pages/login/login.component';
import { TestComponent } from './features/private/test/test/test.component';
import { ContactComponent } from './features/public/contact/pages/contact/contact.component';
import { TransactionsComponent } from './features/private/transactions/pages/transactions/transactions.component';
import { PersonelComponent } from './features/private/transactions/pages/personel/personel.component';
import { RaporComponent } from './features/private/transactions/pages/rapor/rapor.component';
import { OsgbsComponent } from './features/private/definitions/pages/osgbs/osgbs.component';

import { FTPUploadPageComponent } from './features/private/ftps/pages/ftp-upload/ftp-upload-page/ftp-upload-page.component';
import { PricingsPageComponent } from './features/private/pricings/pricings-page/pricings-page.component';
import { LoginCheckActivate } from './shared/models/routings/LoginCheckActivate';
import { TetkikComponent } from './features/private/definitions/pages/tetkik/tetkik.component';
import { PaketComponent } from './features/private/definitions/pages/paket/paket.component';
import { KullaniciComponent } from './features/private/definitions/pages/kullanici/kullanici.component';
import { CariraporComponent } from './features/private/transactions/pages/carirapor/carirapor.component';
import { SitelayoutComponent } from './sitelayout/sitelayout.component';
import { NotificationComponent } from './features/private/Settings/pages/notification/notification.component';
import { FrxComponent } from './features/private/Settings/pages/frx/frx.component';
import { GetResultsComponent } from './features/private/transactions/pages/get-results/get-results.component';
import { DashboardMainComponent } from './features/private/dashboard/pages/dashboard-main/dashboard-main.component';
import { TransferSevkComponent } from './features/private/transactions/pages/transfer-sevk/transfer-sevk.component';

const routes: Routes = [
  { 
    path: '', 
    component: SitelayoutComponent,
    canActivate: [LoginCheckActivate],
    children: [
      {path: '', component: TransactionsComponent, pathMatch: 'full'},
      {path: 'transactions', component: TransactionsComponent},
      {path: 'contact',component: ContactComponent},
      {path:'personel',component: PersonelComponent,},
      {path:'raporu',component: RaporComponent,},
      {path:'test',component: TestComponent,},
      {path:'osgbs',component: OsgbsComponent,},
      {path:'pricings', component: PricingsPageComponent,},
      {path:'ftpupload',component: FTPUploadPageComponent,},
      {path:'tetkik',component: TetkikComponent,},
      {path:'paket',component: PaketComponent,},
      {path:'kullanici',component: KullaniciComponent,},
      {path:'carirapor', component: CariraporComponent,},
      {path:'notification', component: NotificationComponent,},
      {path:'frx', component: FrxComponent,},
      {path:'sonucal', component: GetResultsComponent,},
      {path:'dashboard', component: DashboardMainComponent,},
      {path:'transfer-sevk', component: TransferSevkComponent,},

    ]
},
  {
    path: '',
    redirectTo: 'transactions',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginCheckActivate]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
