import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';

import { Router } from '@angular/router';
import { ApiService } from '../../../../../Services/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public LoginUrl : string = "Token";

  public username : string = "";
  public password: string = "";
  public message: string = "";
  public alert: boolean = false;

  constructor(private apiservice : ApiService,private router: Router) { }

  ngOnInit() {
    
  }
  onLoginEnter(event) {

    if (event.key == "Enter") {
      this.onLogin();
    }

  }

  public onLogin(){
    if(this.username.trim() !== '' && this.password.trim() ){
      let data = "username=" + this.username + "&password=" + this.password + "&grant_type=password";
     
      this.apiservice.callPostServiceByUrl(this.LoginUrl,data).then((resp) =>{
        // console.log(resp);
        if(resp !== undefined){          
          this.apiservice.authentication.authenticate(resp['access_token']);
          this.router.navigate(['transactions']);
        }
      }, err => {
        
        console.log(err);
        this.message = err;
        this.alert = true;
      })
    }

  }

}
