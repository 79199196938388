import { Component, OnInit, Input } from '@angular/core';
import { OsgbDetayModel } from '../../../../models/OsgbDetayModel';
import { OsgbServices } from '../../../../services/OsgbService';

@Component({
  selector: 'app-detailstab',
  templateUrl: './detailstab.component.html',
  styleUrls: ['./detailstab.component.css']
})
export class DetailstabComponent implements OnInit {

  osgbDetailModel: any;
  @Input() set osgbId(value: any) {
    if (value == undefined)
      return;
    let model = new OsgbDetayModel();
    model.ID = value
    this.osgbService.GetByOsgbID(model).then(o => {
      this.osgbDetailModel = o;
    })
  }
  constructor(private osgbService: OsgbServices) { }

  ngOnInit() {
    
  }

}
