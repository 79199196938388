import { Component, OnInit, Input, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { BsModalService } from 'ngx-bootstrap';
import { ModalManagerService } from 'src/app/Services/modal-manager.service';
import { DataManagerService } from 'src/app/Services/dataManager/data-manager.service';
import { authService } from 'src/app/Services/authService';
import { PermissionViewModel } from '../../../../../../shared/models/PermissionViewModel';
import { NewCompanyComponent } from '../../../modals/new-company/new-company.component';
import { DeletecompanyComponent } from '../../../modals/deletecompany/deletecompany.component';
import { switchMap, map, filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { PersonelTetkikModalModel } from '../../../models/persenolTetkikModalModel';
import { PersoneltetkikmodalComponent } from '../../../modals/personeltetkikmodal/personeltetkikmodal.component';
import { keyframes } from '@angular/animations';
import { OsgbFirmaServices } from '../../../services/OsgbFirmaService';
import { DepartmentListViewModel } from '../../../models/DepartmentListViewModel';
import { NameViewModel } from '../../../models/NameViewModel';
import { NewDepartmentModalComponent } from '../../../modals/new-department-modal/new-department-modal.component';
import { DepartmentViewModel } from '../../../models/DepartmentViewModel';

@Component({
  selector: 'app-osgb',
  templateUrl: './osgb.component.html',
  styleUrls: ['./osgb.component.css']
})
export class OsgbComponent implements OnInit {
  @ViewChild('filterSection', {static: false}) input: ElementRef;
  public getFirmaOsgbUrl: string = "FirmaOsgb/GetFirmaOSGBList";
  public getFirmaOsgbDetailUrl: string = "FirmaOsgb/GetFirmaDetail";
  public direction: number = 1;
  public departmentListViewModel:DepartmentListViewModel=new DepartmentListViewModel();
  public nameViewModel: Array<NameViewModel>;
  public perTetModalModel: PersonelTetkikModalModel = new PersonelTetkikModalModel();
  departmentViewModel:DepartmentViewModel=new DepartmentViewModel();

  @Input() firmaevents: EventEmitter<any>;

  firmaOsgbGrid: any = {
    selectedItem: undefined,
    selectedItems: [],
    totalCount: 30,
    data: [],
    model: {
      Filter: {},
      Paging: {
        PageNumber: 1,
        Count: 10
      },
      Sorting: {},
      Pager: [] = [10, 25, 50]
    }
  };

  index: string[] = [];
  firmaOsgbDetailData: any = {
    firmaID: 0,
    firmaKodu: undefined,
    firmaAdi: undefined,
    firmaVD: undefined,
    firmaVN: undefined,
    firmaAdres: undefined,
    firmaIl: undefined,
    firmaIlce: undefined,
    firmaTel: undefined,
    firmaFax: undefined,
    firmaEmail: undefined,
    Osgb: undefined,
    webSitesi: undefined,
    sozBitTar: undefined,
    sozBasTar: undefined,
    aktif: undefined,
    aciklama: undefined,
    fatNak: undefined,
    sifre: undefined
  };

  permission: PermissionViewModel = new PermissionViewModel();
  constructor(private apiservice: ApiService, private modalService: BsModalService, private modalmanager: ModalManagerService, private datamanager: DataManagerService, private authService: authService,private osgbFirmaServices:OsgbFirmaServices) {
    this.authService.Ready.then(o => {
      this.permission = o;
    });

    //debugger;
  }

  ngOnInit() {
    this.firmaevents.subscribe(
      (resp: any) => {
        if (resp == 'osgb-item-selected') {
          console.log("SEARCH_BUTTON_TEST Firma id transferred to osgb_grid")
          this.firmaOsgbGrid.model.Filter.FirmaID = this.datamanager.FirmaId;
          this.getFirmasOsbg().subscribe(() => {
            console.log("SEARCH_BUTTON_TEST  osgb_grid filled")
            if (!this.firmaOsgbGrid.data || !this.firmaOsgbGrid.data)
              return;
            console.log("SEARCH_BUTTON_TEST first OSGB item selected");
            let item = this.firmaOsgbGrid.data[0];
            this.onFirmaGridSelectItem(item);

          });
        }
      }
    );

    this.firmaOsgbGrid.model.Filter.IsUpdated = 0;
    this.getFirmasOsbg();
  }
  ngAfterViewInit() {
    
    let tableDom = this.input.nativeElement;
    let items = tableDom.querySelectorAll('.filterInput');
    
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      fromEvent(item, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(1000),
          distinctUntilChanged(),
        
            tap((text) => {
              if(item.value.length>2)
            this.getFirmasOsbg();
            else if(item.value.length==0)
            this.getFirmasOsbg();
          })
        )
        .subscribe();
    }
    
  }
  onKeyPress($event){
   
  if($event.keyCode==118){
    
    this.openPersonelTetkikModal()
  }

}
  openPersonelTetkikModal() {
    this.perTetModalModel.firmaID = this.firmaOsgbGrid.selectedItem.FirmaId;
    this.perTetModalModel.personelIDList = null;
    const initialState = {
      perTetModalModel: this.perTetModalModel
    };

    let modal = this.modalService.show(PersoneltetkikmodalComponent, { initialState, ignoreBackdropClick: true, class: 'modal-xl' });
    modal.content.onClose.subscribe(
      response => {
        if (response.length != 0) {
        }
        if (response !== null && response == "close") {
        }
      }, err => {
        console.log(err);
      }
    );
  }

  onFilterInputChange(event) {

    //if (event.key == "Enter") {
    this.getFirmasOsbg();
    //}

  }
  onClearFilter(){
this.firmaOsgbGrid.model.Filter={};
    this.getFirmasOsbg();
  }
  public onSorting(colName) {
    if (this.direction == 0) {
      this.direction = 1;
    } else {
      this.direction = 0;
    }
    this.firmaOsgbGrid.model.Sorting = { colName: colName, direction: this.direction };
    this.getFirmasOsbg();
  }
  public getFirmasOsbg() {
    var item = this.apiservice.callPostService(this.getFirmaOsgbUrl, this.firmaOsgbGrid.model)
      .pipe(
        map(resp => {
          // console.log(resp); 
        if (resp !== undefined && 'Data' in resp) {
            this.firmaOsgbGrid.data = resp.Data.List;
            this.firmaOsgbGrid.totalCount = resp.Data.TotalCount;
          }
        }));
    item.subscribe();
    return item;
  }

  public onFirmaGridPageChanged(event) {
    this.firmaOsgbGrid.model.Paging.PageNumber = event.page;
    this.getFirmasOsbg();
  }

  public onFirmaGridSelectItem(item) {
    this.firmaOsgbGrid.selectedItem = item;

    // this.personnelGrid.model.Filter['FirmaId'] = item.FirmaId ;
    // this.getpersonelList();
    this.datamanager.setFirmaID(item.FirmaId);
    this.datamanager.setOsgbID(item.OsgbId);
    this.firmaevents.emit('firma-item-selected');

  }

  public firmaGridGetRowClass(item) {
    if (this.firmaOsgbGrid.selectedItem == undefined) {
      return '';
    } else {
      if (this.firmaOsgbGrid.selectedItem.FirmaId == item.FirmaId) {
        return 'table-info';
      } else {
        return '';
      }
    }
  }

  public openNewCompanyModal(id) {

    const initialState = {
      FirmaID: id,
    };
    this.modalService.show(NewCompanyComponent, { initialState,ignoreBackdropClick:true });
    // console.log(this.modalRef);
    this.modalService.onHide.subscribe(response => {
      if (response !== null && response == "save-Firma")
        this.getFirmasOsbg();
    })
  }

  public openDeleteCompanyModal(id) {
    const initialState = {
      FirmaID: id,
    };
    this.modalService.show(DeletecompanyComponent, { initialState,ignoreBackdropClick:true });
    // console.log(this.modalRef);
    this.modalService.onHide.subscribe(
      response => {
        if (response !== null && response == "delete-Firma")
          this.getFirmasOsbg();
      }
    )
  }

  public onCollapseFirma(firmaID) {
    
    if (this.index.includes(firmaID)) {
      var arrayIndex = this.index.indexOf(firmaID);
      this.index.splice(arrayIndex, 1);
      return;
    }
    this.index.length=0;
    this.firmaDepartmentList(firmaID)
    this.firmaOsgbDetailData.firmaID = firmaID;
    this.index.push(firmaID);
    this.apiservice.callPostService(this.getFirmaOsgbDetailUrl, this.firmaOsgbDetailData).subscribe((resp: any) => {
      // console.log(resp);
      if (resp !== undefined && 'Data' in resp) {
        this.firmaOsgbDetailData.firmaKodu = resp.Data.FirmaKodu;
        this.firmaOsgbDetailData.firmaVD = resp.Data.FirmaVD;
        this.firmaOsgbDetailData.firmaVN = resp.Data.FirmaVN;
        this.firmaOsgbDetailData.firmaAdres = resp.Data.FirmaAdres;
        this.firmaOsgbDetailData.firmaIl = resp.Data.FirmaIl;
        this.firmaOsgbDetailData.firmaIlce = resp.Data.FirmaIlce;
        this.firmaOsgbDetailData.firmaTel = resp.Data.FirmaTel;
        this.firmaOsgbDetailData.firmaFax = resp.Data.FirmaFax;
        this.firmaOsgbDetailData.firmaEmail = resp.Data.FirmaEmail;
        this.firmaOsgbDetailData.webSitesi = resp.Data.WebSitesi;
        this.firmaOsgbDetailData.sozBitTar = resp.Data.SozBitTar;
        this.firmaOsgbDetailData.sozBasTar = resp.Data.SozBasTar;
        this.firmaOsgbDetailData.aktif = resp.Data.Aktif;
        this.firmaOsgbDetailData.aciklama = resp.Data.Aciklama;
        this.firmaOsgbDetailData.fatNak = resp.Data.FatNak;
        this.firmaOsgbDetailData.sifre = resp.Data.Sifre;
      }
    }, err => {
      console.log(err);
    });
  }

  // --------------departman işlmeler-----------------
  public firmaDepartmentList(firmaID){
    this.departmentListViewModel.FirmaID=firmaID;
     this.osgbFirmaServices.GetDepartmentByFirmaID(this.departmentListViewModel).then(o=>{
      this.nameViewModel=o
     })
  }
  onNewDepartment(id){
    const initialState = {
      FirmaID: id,
    };
    this.modalService.show(NewDepartmentModalComponent, { initialState,ignoreBackdropClick:true });
    // console.log(this.modalRef);
    this.modalService.onHide.subscribe(
      response => {
        if (response == 'save' )
          this.firmaDepartmentList(id);
      }
    )
  }
  onUpdateDepartment(id){
    const initialState = {
      DepartmentID: id,
    };
    this.modalService.show(NewDepartmentModalComponent, { initialState,ignoreBackdropClick:true });
    // console.log(this.modalRef);
    this.modalService.onHide.subscribe(
      response => {
        if (response == 'save' )
          this.firmaDepartmentList(id);
      }
    )
  }
 
  onDeleteonNewDepartment(id){
    this.departmentViewModel.DepartmentID=id;
this.osgbFirmaServices.DeleteDepartment(this.departmentViewModel).then(o=>{
  console.log(o);
  this.firmaDepartmentList(this.departmentListViewModel.FirmaID);

})
  }
    // -------------- finish departman işlmeler-----------------
  public isCollapseFirma(firmaID) {
    return this.index.includes(firmaID);
  }
}
