export class OsgbViewFilter {
  Id: any;
  OSGBKodu: any;
  OSGBAdi: any;
  OSGBVD: any;
  OSGBVN: any;
  OSGBIl: any;
  OSGBılce: any;
  OSGBTel: any;
  OSGB_Fax: any;
}
