import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { TetkikTanimlamaServices } from '../../services/TetkikTanimlamaService';
import { Subject } from 'rxjs';
import { LabtetkikViewModel } from '../../models/LabtetkikViewModel';
import { LabTetkikSaveModel } from '../../models/LabTetkikSaveModel';
import { LabTetkikFilter } from '../../models/LabTetkikFilter';
import { TetkikModel } from '../../models/TetkikModel';

@Component({
  selector: 'app-save-labtetkik',
  templateUrl: './save-labtetkik.component.html',
  styleUrls: ['./save-labtetkik.component.css']
})
export class SaveLabtetkikComponent implements OnInit {
  public onClose: Subject<string>;
  public tetkikID: any = undefined;
  public labTetkikID: any = undefined;
  modalTitle: any;
  idVisible: boolean;
  labTetkikModel: TetkikModel = new TetkikModel();
  labTetkikViewModel: LabtetkikViewModel = new LabtetkikViewModel();
  //degerDizi: Array<String> = new Array<String>;
  //degerDizi = [];
  //degerDizi: Array<string> =[];
  public degerDizi = new Array();
  public temp = new Array();
  diziItem: string;
  constructor(
    public bsModalRef: BsModalRef,
    private tetkikServices: TetkikTanimlamaServices) {
    
  }

  ngOnInit() {
    this.onClose = new Subject();
    console.log(this.labTetkikID);
    if (this.labTetkikID == undefined) {
      this.modalTitle = "Lab Tetkik Ekleme";
      this.idVisible = false;
      

    }
    else {
      this.modalTitle = "Lab Tetkik Güncelleme";
      this.idVisible = true;
      this.labTetkikModel.ID = this.labTetkikID;

      this.tetkikServices.GetLabTetkikByID(this.labTetkikModel).then(o => {
        console.log(o);
        this.labTetkikViewModel = o;
        console.log(this.labTetkikViewModel);
        this.degerDizi = this.labTetkikViewModel.DegerDiziTemp;
      });
      console.log(this.degerDizi);
      

    }
  }
  addDegerDizi(mode) {
    if (mode == 0) {
      if (this.diziItem != "") {
        if (!this.degerDizi.includes(this.diziItem)) {
          this.degerDizi.push(this.diziItem);
        }
      }
    }
    else if (mode == 1) {
      this.degerDizi.splice(0, 1);
    }
    else {}
    console.log(this.degerDizi);
  }
  onConfirm(st: any) {
    this.labTetkikViewModel.TetkikId = this.tetkikID;
    this.labTetkikViewModel.DegerDizi = this.degerDizi;
    console.log(this.labTetkikViewModel);
    this.tetkikServices.LabTetkikSave(this.labTetkikViewModel).then(o => {
      this.onClose.next("confirm");
      this.bsModalRef.hide();
    });

  }
  onCancel(st: any) {
    this.onClose.next("cancel");
    this.bsModalRef.hide();
  }

}
