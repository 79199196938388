import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tetkikdetails',
  templateUrl: './tetkikdetails.component.html',
  styleUrls: ['./tetkikdetails.component.css']
})
export class TetkikdetailsComponent implements OnInit {

  _TetkikID: any;
  @Input() set clickedTetkikID(value: any) {
    if (value == undefined)
      return;
    this._TetkikID = value;
  }

  constructor() { }

  ngOnInit() {
  }

}
