export class DashBoardViewModel {
  Name: any;
  Count: any;
}
export class DashBoardCountViewModel
{
  StartTime:any;
  Name:any;
  Count:any;
}
export class DashBoardGroupCountViewModel{
  StartTime:any;
  GroupData:DashBoardViewModel[];
}

export class DashBoardNoticationViewModel{
  NotificationTime:any;
  NotificationText:any;
}

export class DashBoardAnnouncementViewModel{
  AnnouncementTime:any;
  AnnouncementText:any;
  ImagePath:any;
}