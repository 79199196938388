import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { DataManagerService } from 'src/app/Services/dataManager/data-manager.service';
import { switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-save-audio',
  templateUrl: './save-audio.component.html',
  styleUrls: ['./save-audio.component.css']
})
export class SaveAudioComponent implements OnInit {

  public saveExcelFile: string = "FileOperation/Upload";
  public getExcelDataUrl: string = "Personel/GetExcelData";
  public saveAudioExcel: string = "Personel/SaveOdioByExcel";

  public ui: any = {
    columns: []
  };
  public model: any = {
    firmaID: undefined,
    odemeSekli: undefined,
    sheet: 1,
    tetkikTuru: undefined,
    dict: {
      adSoyadColumnNumber: undefined,
      TCColumnNumber: undefined,
      personelSicilNoNumber: undefined,
      dogumTarihiNumber: undefined,
      cinsiyetNumber: undefined,
      AdresNumber:undefined,
      CepNoNumber :undefined,
      sol1000: undefined,
      sag1000: undefined,
      sol2000: undefined,
      sag2000: undefined,
      sol4000: undefined,
      sag4000: undefined,
      sol8000: undefined,
      sag8000: undefined,
      sol250: undefined,
      sag250: undefined,
      sol500: undefined,
      sag500: undefined
    },
    excelFileID: undefined
  };

  public selectedFile: any = {
    name: "Dosya seçin"
  };

  public personelList: any[] = [];
  public showAlert: boolean = false;

  public disableUpload: boolean = true;

  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService, private datamanager: DataManagerService, private modalService: BsModalService) { }

  ngOnInit() {
    this.model.firmaID = this.datamanager.getFirmaID();

  }

  public handleFileChoose(event) {
    // console.log(event.target.files)
    this.selectedFile = event.target.files[0];
    this.disableUpload = false;
  }



  onUploadFile() {
    this.apiservice.callFormDataService(this.saveExcelFile, [this.selectedFile]).pipe(
      switchMap(
        (resp: any) => {
          this.model.excelFileID = resp.Data[0];
          return this.apiservice.callPostService(this.getExcelDataUrl, { excelFileID: this.model.excelFileID })
        }
      ),
      map(
        (resp: any) => {
          // console.log(resp);
          this.ui.columns = [];
          this.personelList = resp.Data;
          this.ui.columns = this.getExcelColumns(this.personelList);
        }
      )
    ).subscribe();
  }

  getExcelColumns = (excelData) => {
    debugger;
    let arr = []
    if (!excelData || !excelData[0]) {
      return arr;
    }
    let item = excelData[0];
    excelData.splice(0, 1);

    for (var k in item) {
      var colName = item[k];
      if (!colName) continue;
      arr.push(colName);
    }
    return arr;
  }

  public saveAudio() {
   
    if (!this.model.tetkikTuru) {
      this.showAlert = true;
    } else {
      // console.log(this.model);
      this.showAlert = false;
      this.apiservice.callPostService(this.saveAudioExcel, this.model).subscribe(
        (resp) => {
          this.modalService.setDismissReason('save-audio');
          this.bsModalRef.hide();
        }
      );
    }
  }
}
