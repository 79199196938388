import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
import { TetkikViewFilter } from 'src/app/features/private/definitions/models/TetkikViewFilter';
import { TetkikViewModel } from 'src/app/features/private/definitions/models/TetkikViewModel';
import { TetkikSaveModel } from 'src/app/features/private/definitions/models/TetkikSaveModel';
import { LabtetkikViewModel } from 'src/app/features/private/definitions/models/LabtetkikViewModel';
import { TetkikModel } from '../models/TetkikModel';
import { LabTetkikFilter } from '../models/LabTetkikFilter';
import { LabTetkikSaveModel } from '../models/LabTetkikSaveModel';
import { TetkikGrubuViewModel } from '../models/TetkikGurubuViewModel';

@Injectable({
  providedIn: 'root'
})
export class TetkikTanimlamaServices {

  constructor(private api: ApiService) { }

  public GetTetkikList2(model: Grid.GridInputModel<TetkikViewFilter>): Promise<Grid.GridOutputModel<TetkikViewModel>> {
    return this.api.callService("Tetkik/GetTetkikList2", model).then(o => {
      var res = new Grid.GridOutputModel<TetkikViewModel>();
      res.list = o["List"]; 
      res.totalCount = o["TotalCount"];
      return res;
    });
  };
  public Save(model: TetkikSaveModel): Promise<any> {
    return this.api.callService("Tetkik/Save", model).then(o => {
      return o;
    });
  };
  public GetTetkikById(model: TetkikModel): Promise<TetkikSaveModel> {
    return this.api.callService("Tetkik/GetTetkikById", model).then(o => {
      var res = new TetkikSaveModel();
      res = o as TetkikSaveModel;
      return res;
    });
  };
  public GetLabTetkikList(model: Grid.GridInputModel<LabTetkikFilter>): Promise<Grid.GridOutputModel<LabtetkikViewModel>> {
    return this.api.callService("Tetkik/GetLabTetkikList", model).then(o => {
      var res = new Grid.GridOutputModel<LabtetkikViewModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
    });
  };
  public LabTetkikSave(model: LabTetkikSaveModel): Promise<any> {
    return this.api.callService("Tetkik/LabTetkikSave", model).then(o => {
      return o;
    });
  };
  public LabTetkikDelete(model: TetkikModel): Promise<any> {
    return this.api.callService("Tetkik/LabTetkikDelete", model).then(o => {
      return o;
    });
  };
  public GetLabTetkikByID(model: TetkikModel): Promise<LabtetkikViewModel> {
    console.log(model);
    return this.api.callService("Tetkik/GetLabTetkikByID", model).then(o => {
      var res = new LabtetkikViewModel();
      res = o as LabtetkikViewModel;
      return res;
    });
  };
  public GetTetkikGrubuList(): Promise<TetkikGrubuViewModel[]> {
    return this.api.callService("Tetkik/GetTetkikGrubuList",null).then(o => {
      return o as TetkikGrubuViewModel[];
    });
  };
}
