import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { Grid } from 'src/app/shared/models/UIControls/grid-control';
import { IDViewModel } from '../../../definitions/models/IDViewModel';
import { TetkikGrubuViewModel } from '../../../definitions/models/TetkikGurubuViewModel';
import { OsgbServices } from '../../../definitions/services/OsgbService';
import { ddlSettings } from '../../../transactions/modals/personeltetkikmodal/ddlSettings';
import { NameViewModel } from '../../../transactions/models/NameViewModel';
import { OsgbViewModel } from '../../../transactions/models/OsgbViewModel';
import { OsgbFirmaServices } from '../../../transactions/services/OsgbFirmaService';
import { PersonelTetkikIslemServices } from '../../../transactions/services/PersonelTetkikIslemService';
import { DownloadModel, UploadModel } from '../../models/DownloadModel';
import { TetkikRaporSablonViewModel } from '../../models/TetkikRaporSablonViewModel';
import { LaboratuvarServices } from '../../services/LaboratuvarServices';
import { ReportTemplateServices } from '../../services/ReportTemplateServices';

@Component({
  selector: 'app-frx',
  templateUrl: './frx.component.html',
  styleUrls: ['./frx.component.css']
})
export class FrxComponent implements OnInit {
  downloadModel: DownloadModel = new DownloadModel();
  uploadModel: UploadModel = new UploadModel()
  idViewModel: IDViewModel = new IDViewModel();
  public ddlLabList: Array<any>
  public ddlLabSelectedItems: any[]
  public ddlLabSettings;
  public islem;
  public ddlOSGBList: Array<NameViewModel>;
  public ddlOSGBSelectedItems = [];
  public ddlDizaynSelectedItems = [];
  public ddlOSGBSettings;
  public ddlDizaynSettings
  public ddlSettings: ddlSettings = new ddlSettings();
  public ddlDizaynList: Array<NameViewModel>;
  grid: FrxComponent_Models.GridFrx = new FrxComponent_Models.GridFrx(this._service);

  constructor(private _service: ReportTemplateServices,  private osgbService: OsgbServices, private apiService: ApiService, private tetkikService: PersonelTetkikIslemServices,public labServices: LaboratuvarServices) { }

  ngOnInit() {
    this.ddlSetting();
    this.getLabList();
    this.getDizaynList();
    this.grid.onRefresh();
  }
  onDownload() {

    if (this.downloadModel.IsExcel == true) {
      this.downloadModel.IsTumFrx = false;
    } else {
      this.downloadModel.IsTumFrx = true;
    }
    this.downloadModel.LabID=this.ddlLabSelectedItems[0].ID
    this.downloadModel.OsgbID = this.ddlOSGBSelectedItems[0].ID;
    console.log(this.downloadModel)
    this._service.DownloadFrxAndExcel(this.downloadModel).then(o => {

    })
  }
  onUpload() {
    debugger;
    this.uploadModel.LabID=this.ddlLabSelectedItems[0].ID
    this.uploadModel.IsTumFrx = null;
    this.uploadModel.IsImzali=this.downloadModel.IsImzali;
    this.uploadModel.IsExcel=this.downloadModel.IsExcel;
    this.uploadModel.OsgbID = this.ddlOSGBSelectedItems[0].ID;
   
    if(this.ddlDizaynSelectedItems.length>0){
    this.uploadModel.FrxID=this.ddlDizaynSelectedItems[0].ID;
  }
    console.log(this.uploadModel)
    this._service.UploadFrxAndExcel(this.uploadModel).then(o => {
      this.grid.onRefresh();
    })
  }
  ddlSetting() {
    this.ddlOSGBSettings = this.ddlSettings.ddlDizaynSettings;
    this.ddlLabSettings = this.ddlSettings.ddlDizaynSettings;
    this.ddlDizaynSettings= this.ddlSettings.ddlDizaynSettings
  }
  onFileUploadComplete(event) {

    var res = this.apiService.solveResponseJSON(event);
    this.uploadModel.FileID = res.Data[0];
    console.log(this.uploadModel.FileID )
  }

  getDizaynList(){
    this.tetkikService.GetDizaynList().then(o=>{
      this.ddlDizaynList=o;
    })
  }
  getLabList() {
    this.labServices.GetKeyList().then(o => {
      this.ddlLabList = o;
      console.log(this.ddlLabList)
    })
  }
  changeLabID(){
    this.idViewModel.ID = this.ddlLabSelectedItems[0].ID;
    this.getOSGBListOptions();
  }
  changeFirma(){
   if( this.ddlOSGBSelectedItems.length<1)
   return
   this.grid.filter.filter.LabID = this.ddlLabSelectedItems[0].ID;
    this.grid.filter.filter.OsgbID = this.ddlOSGBSelectedItems[0].ID;
    this.grid.onRefresh();
  }
  getOSGBListOptions() {
    -
    this.osgbService.GetKeyList(this.idViewModel).then(o => {
      this.ddlOSGBList = [];
      this.ddlOSGBList = o;
      console.log(this.ddlOSGBList);
    })
  }
}
namespace FrxComponent_Models {

  export class GridFrx extends Grid.GridControl<any> {

    public direction: number = 1;
    public gridModel:TetkikRaporSablonViewModel=new TetkikRaporSablonViewModel();
    constructor(private _service: ReportTemplateServices) {
      super();
    }

    filter = new Grid.GridInputModel(new DownloadModel());
    getFilter() {

      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;
      // this.filter.filter.OsgbID
      let item = this.filter.filter;
   
      // var o = this.kullaniciFilter;


      return this.filter;
    };
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    onRefresh() {
      var item = this.getFilter()
      var filter = item.filter;
      this._service.GetReportTemplateList(item).then(o => {
        this.data = o;
      })
    }
  }

}
