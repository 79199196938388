import { Component, OnInit, ViewChild, ViewChildren, ElementRef, Renderer2, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, BsLocaleService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { authService } from 'src/app/Services/authService';
import { ToastrService } from 'ngx-toastr';
import { of, EmptyError, Subject } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ModalManagerService } from 'src/app/Services/modal-manager.service';
import { KimlikConfirmationComponent } from '../../../../../shared/modals/kimlik-confirmation/kimlik-confirmation.component';
import { defineLocale, trLocale } from 'ngx-bootstrap/chronos';
import { PermissionViewModel } from 'src/app/shared/models/PermissionViewModel';
import { isNgTemplate } from '@angular/compiler';
import { DepartmentListViewModel } from '../../models/DepartmentListViewModel';
import { OsgbFirmaServices } from '../../services/OsgbFirmaService';
import { NameViewModel } from '../../models/NameViewModel';
defineLocale('tr', trLocale);

@Component({
  selector: 'app-newpersonel',
  templateUrl: './newpersonel.component.html',
  styleUrls: ['./newpersonel.component.css']
})

export class NewpersonelComponent implements OnInit {

  @ViewChild('adSoyad', { static: false }) adSoyad: ElementRef;
  @ViewChild('kaydetVeDevamEt', { static: false }) kaydetVeDevamEt: ElementRef;
  public onClose: Subject<any> = new Subject<any>();
  public savePersonelUrl: string = "Personel/Save";
  public getDoctorsListUrl: string = "Personel/GetDoctorList";
  public getPersonelDetailsUrl: string = "Personel/GetPersonelDetail";
  public GetPersonelDetailByKimlikNo: string  = "Personel/GetPersonelDetailByKimlikNo"
  public checkTckUrl: string = "Personel/KimlikNoCheck";
  public doctorsList: any = undefined;
  public modalTitle: string;
  public modalSubTitle: string;
  public OsgbId: any;
  public FirmaId: any;
  public personelID: any;
  public updateControl: any;
  public PersonelKimlikNo:any;
  public model: FrmPersonel = new FrmPersonel();
  public TCVatandas;
  public yabanci;
  permission: PermissionViewModel = new PermissionViewModel();
  departmentListViewModel:DepartmentListViewModel= new DepartmentListViewModel();
  public nameViewModel: Array<NameViewModel>;

  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService,
    private authService: authService, private modalService: BsModalService,
     private modalNoService: ModalManagerService, private localeService: BsLocaleService,private osgbFirmaServices:OsgbFirmaServices) {
    this.authService.Ready.then(o => {
      this.permission = o;
    });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      console.log(this.adSoyad.nativeElement.focus());
    }, 100);
  }
  ngOnInit() {
    this.yabanci=false;
    this.TCVatandas=true;
    this.localeService.use('tr');

    this.modalTitle = (this.personelID == undefined) ? "Yeni Personel Ekle" : "Personel Bilgilerini Güncelle";
    this.modalSubTitle = (this.personelID == undefined) ? "Personel Bilgileri" : "Seçili Personel Bilgileri";

    this.model.FirmaId = this.FirmaId;
    this.model.OsgbId = this.OsgbId;
    if (this.personelID) {
      this.model.ID = this.personelID;
    }
    // let detailsObs$:any;
    // if(this.updateControl==1){
    //  detailsObs$ = (this.personelID == undefined) ? of(null) : this.apiservice.callPostService(this.GetPersonelDetailByKimlikNo, { ID:this.PersonelKimlikNo });

    // }else{
   let  detailsObs$ = (this.personelID == undefined) ? of(null) : this.apiservice.callPostService(this.getPersonelDetailsUrl, { ID:this.personelID });
  //  if(this.updateControl==1){
  //   detailsObs$ = (this.personelID == undefined) ? of(null) : this.apiservice.callPostService(this.GetPersonelDetailByKimlikNo, { ID:this.PersonelKimlikNo });
  //  }
  // }
    this.apiservice.callPostService(this.getDoctorsListUrl, {}).pipe(
      switchMap(
        (resp: any) => {
          
          if (resp !== undefined && 'Data' in resp) {
            this.doctorsList = resp.Data;
          }
          return detailsObs$;
        }
      ),
      map(
        resp => {
          if (resp !== null && 'Data' in resp) {            
            this.model = this.Service2Model(resp.Data);
          }
        }
      ),
      catchError((err:any) => {
        console.log(err);
        return err;
      })
    ).subscribe();
    this.firmaDepartmentList();
  }
   onNationality(){
  this.TCVatandas=!this.TCVatandas;
  this.yabanci=!this.yabanci;
  }
  
  public savePersonel(par?: string) {
    debugger;
    console.log(this.model.DogumTarihi);
    
    this.error.length = 0;
    if (this.model.AdSoyad.trim().split(" ").length < 2) {
      this.error.push("Ad Soyad en az 2 Kelime olmalıdır");
      return;
    }

    var serModel = this.Model2Service(this.model);
    if (!serModel) {
      this.error.push("Model dönüştürme problemi.");
      return;
    }
    var pr: Promise<any>;

    if (serModel.ID) {
      
      pr = new Promise(o => { o('update'); });
    }
    else {
      
      pr = this.apiservice.callService(this.checkTckUrl, { tc: this.model.TC });
    }

    pr.then(o => {
    
  
      // if (o != 'update') {
      //   if (o != 0) {
      //     var id = o;
      //     this.modalNoService.openConfirmation("Zaten Kayıtlı bir Tc var olanı güncellemek ister misiniz?").subscribe(o => {
      //       if (o != 'confirm') {
      //         console.log(o);
      //         return;
      //       }
      //       setTimeout(() => {
      //         this.close({ alreadyExist: id , updateControl: 1 });
      //       }, 500);
            
      //     });
          
      //     return;
      //   }
      // }
      

      serModel.FirmaId = this.FirmaId;
      if (this.updateControl == 1) {
        serModel.ID = undefined;
      }
      // serModel.DogumTarihi=serModel.DogumTarihi.toLocaleString();

      return this.apiservice.callService(this.savePersonelUrl, serModel).then(o => {
        this.close(par ? par : 'save-personel');
      });

    });
   
  }


  Model2Service(frm: FrmPersonel) {
    let frm2 = { ...frm } as FrmPersonel;
    var arr = frm2.AdSoyad.trim().split(" ");
    if (arr.length == 0)
      return undefined;
    if (arr.length == 1)
      frm2.Ad = arr[0];
    else {
      frm2.Soyad = arr[arr.length - 1];
      frm2.Ad = arr.slice(0, arr.length - 1).join(" ");
    }

    return frm2;
  }
  Service2Model(response: any): FrmPersonel {
    let item = new FrmPersonel();
    item.ID = response.ID;
    item.TC = response.TCKNo;
    item.Ad = response.PersonelAdi,
      item.Soyad = response.PersonelSoyadi,
      item.AdSoyad = response.PersonelAdi ,
      item.PersonelSicilNo = response.PersonelSicilNo,
      item.DogumTarihi = new Date(response.DogumTarihi),
      item.Cinsiyet = response.Cinsiyet,
      item.CepTelefonu = response.CepTel,
      item.Doktor = response.Doktor;
    item.OdemeSekli = response.FatNak;
    item.FirmaId = response.FirmaID;
    item.Aciklama=response.Aciklama;
    item.Adres=response.Adres;
    item.PasaportNo=response.PasaportNo;
    item.GidisTarihi=response.GidisTarihi;
    item.GidilecekUlke=response.GidilecekUlke;
    item.UylukBilgisi=response.UylukBilgisi;
    item.DepartmentID=response.DepartmentID;

    return item;
  }

  error = [];
  public savePersonelAndContinue() {
    this.savePersonel('seri');
  }
  public firmaDepartmentList(){
     this.departmentListViewModel.FirmaID=this.FirmaId;
     this.osgbFirmaServices.GetDepartmentByFirmaID(this.departmentListViewModel).then(o=>{
      this.nameViewModel=o;
     })
  }
  onKeyPress(event: any) {
    if (event.key === "Enter") {
      debugger;
      setTimeout(() => {
        debugger;
        console.log(this.adSoyad.nativeElement.focus());
        this.savePersonel('seri');
      }, 100);
      
    }
  }

  public stopPropagation() {
  
    this.apiservice.callPostService(this.checkTckUrl, { tc: this.model.TC }).pipe(
      switchMap(
        (resp: any) => {
          if (resp !== undefined && 'Data' in resp) {
            if (resp.Data == 0) {
              return this.apiservice.callPostService(this.savePersonelUrl, this.model);
            } else {
              return of({ message: 'update', personelID: resp.Data });
            }
          } else {
            return of(null);
          }
        }
      ),
      switchMap(
        (response: any) => {
          if (response == null) {
            return of(null)
          }
          else if ('message' in response && response.message == 'update') {
            let initialState = {
              personelID: this.personelID,
              FirmaId: this.FirmaId,
              OsgbId: this.OsgbId,
              adiSoyadi: this.model.Ad + ' ' + this.model.Soyad,
              sicilno: this.model.PersonelSicilNo,
              cinsyet: this.model.Cinsiyet,
              dogumtarihi: this.model.DogumTarihi,
              ceptel:this.model.CepTelefonu,
              aciklama:this.model.Aciklama,
              Adres:this.model.Adres,
              PasaportNo:this.model.PasaportNo,
              GidisTarihi:this.model.GidisTarihi,
              UylukBilgisi:this.model.UylukBilgisi,
              GidilecekUlke:this.model.GidilecekUlke,
              DepartmentID:this.model.DepartmentID,
            
            };
          
            this.modalService.show(KimlikConfirmationComponent, { initialState,ignoreBackdropClick: true });
            return of(null)
          }
          else {
            this.modalService.setDismissReason('save-personel');
            return of(null)
          }
        }
      )
    ).subscribe();
  }


  onCancel() {
    this.close("cancel");
  }
  close(arg: any) {
    this.onClose.next(arg);
    this.bsModalRef.hide();

  }
  onTCControl(){

    var serModel = this.Model2Service(this.model);

    if (!serModel) {
      this.error.push("Model dönüştürme problemi.");
      return;
    }
   
    var pr: Promise<any>;

    if (serModel.ID) {
      pr = new Promise(o => { o('update'); });
    }
    else {
      pr = this.apiservice.callService(this.checkTckUrl, { tc: this.model.TC });
    }
    pr.then(o => {
      if (o != 'update') {
        if (o != 0) {
          var id = o;
          this.modalNoService.openConfirmation("Zaten Kayıtlı bir Tc var olanı güncellemek ister misiniz?").subscribe(o => {
            
            if (o != 'confirm') {
              return;
            }
                setTimeout(() => {
                  this.close({ alreadyExist: id, updateControl: 1 });
            }, 500);
            
          });
          return;
        }
      }
      serModel.FirmaId=this.FirmaId;
      serModel.ID = undefined;
    });
  }
}

export class FrmPersonel {
  ID: number;
  OsgbId: undefined;
  FirmaId: undefined;
  TC: string = "";
  Ad: string = "";
  Soyad: string = "";
  AdSoyad: string = "";
  PersonelSicilNo: any;
  DogumTarihi: any = new Date();
  Cinsiyet: undefined;
  CepTelefonu: string = "+90";
  Doktor: undefined;
  OdemeSekli: undefined;
  Aciklama:string="";
  Adres:string="";
  PasaportNo:string="";
  GidisTarihi:string="";
  UylukBilgisi:string="";
  GidilecekUlke:string="";
  DepartmentID:undefined;
  
};
