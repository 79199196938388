export class FTPDosyaFilter {
  ID: any;
  OsgbID: any;
  OsgbAdi: any;
  KullaniciNo: any;
  KullaniciAdi: any;
  DosyaAdi: any;
  DosyaBoyutu: any;
  YuklemeTarihi: any;
  Indiren: any;
  IndirmeTarihi: any;
  Goren: any;
  GormeTarihi: any;
  Onaylayan: any;
  OnayTarihi: any;
  OnayNotu: any;
  YuklemeNotu: any;
  IndirmeChecbox: any;
  OnayChecbox: any;
  GorenChecbox: any;
}
