export class UserUIModel {
  ID: any;
  KullaniciAdi: any;
  AdSoyad: any;
  Email: any;
  Sifre: any;
  OsgbAdi: any;
  OsgbId: any;
  firmaID:any;
  PersonelID:any;
  LabID:any;
  RoleID :any;
}
