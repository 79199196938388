import { Component, OnInit } from '@angular/core';
import { TahsilatViewFilter } from '../../models/TahsilatViewFilter';
import { SaveOdemeAlModel } from '../../models/SaveOdemeAlModel';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';
import { PersonelTetkikServices } from '../../services/PersonelTetkikService';
import { SaveIadeEtModel } from '../../models/SaveIadeEtModel';

@Component({
  selector: 'app-returnpayment',
  templateUrl: './returnpayment.component.html',
  styleUrls: ['./returnpayment.component.css']
})
export class ReturnpaymentComponent implements OnInit {

  public tahsilatViewModel: any[] = [];
  public tahsilatViewFilter: TahsilatViewFilter = new TahsilatViewFilter();
  public saveIadeEt: SaveIadeEtModel = new SaveIadeEtModel();
  public onClose: Subject<string>;
  public islemIdList: any;
  public selectedIslemID: any[] = [];

  public secilenToplam: number = 0;
  public genelToplam: number = 0;
  public nakit: number = 0;
  public kredi: number = 0;
  public kalanTutar: number = 0;
  public indirimTutar: number;


  public yeniUcret: any;
  public tutar: any;
  constructor(public bsModalRef: BsModalRef, private personelService: PersonelTetkikServices) { }

  ngOnInit() {
    this.onClose = new Subject();

    this.tahsilatViewFilter.IslemIDList = this.islemIdList;
    this.personelService.GetTahsilatList(this.tahsilatViewFilter).then(resp => {
      this.tahsilatViewModel = resp;
      this.onHesaplama();
    });
  }

  onConfirm(st: any) {
    this.saveIadeEt.Kredi = this.kredi;
    this.saveIadeEt.Nakit = this.nakit;
    this.saveIadeEt.GenelToplam = this.genelToplam;
    this.saveIadeEt.gridList = this.tahsilatViewModel
    this.saveIadeEt.PersonelID = this.tahsilatViewModel[0].PersonelID;
    this.saveIadeEt.FirmaID = this.tahsilatViewModel[0].FirmaID;
    this.saveIadeEt.OsgbID = this.tahsilatViewModel[0].OsgbID;

    this.personelService.SaveIadeEt(this.saveIadeEt).then(resp => {
      this.onClose.next("confirm");
      this.bsModalRef.hide();
    });
  }
  onCancel(st: any) {
    this.onClose.next("cancel");
    this.bsModalRef.hide();
  }

  onHesaplama() {
    this.secilenToplam = 0
    this.genelToplam = 0;

    this.tahsilatViewModel.forEach(item => {
      item.IndirimTutar = Number(item.IndirimTutar);
      item.YeniUcret = Number(item.YeniUcret);
      item.Tutar = Number(item.Tutar);

      this.secilenToplam = this.secilenToplam + item.YeniUcret;
      this.genelToplam = this.genelToplam + item.Tutar;
    });

    this.kalanTutar = this.genelToplam - (Number(this.kredi) + Number(this.nakit));

  }

}
