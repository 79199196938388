import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { Grid } from 'src/app/shared/models/UIControls/grid-control';
import { BsModalService } from 'ngx-bootstrap';
import { TetkikFiyatService } from '../services/TetkikFiyatService';
import { TetkikFiyatlandirmaFilter } from '../models/TetkikFiyatlandirmaFilter';
import { TetkikFiyatlandirmaViewModel } from '../models/TetkikFiyatlandirmaViewModel';
import * as moment from 'moment';
import { AutocompleteModel, AutoCompleteSettings } from 'src/app/Shared/component/input-autocomlete/input-autocomlete.component';
import { PersonelServices } from '../../transactions/services/PersonelService';
import { PersonelViewFilter } from '../../transactions/models/PersonelViewFilter';
import { PersonelViewModel } from '../../transactions/models/PersonelViewModel';
moment.locale('tr');

@Component({
  selector: 'pricings-grid',
  templateUrl: './pricings-grid.component.html',
  styleUrls: ['./pricings-grid.component.css']
})
export class PricingsGridComponent implements OnInit {
  filter: TetkikFiyatlandirmaFilter;
  pricingsFilter: pricingsFilter = new pricingsFilter();
  public grid: PricingsGridComponent_Models.GridPrice;
  tarih: { start: moment.Moment, end: moment.Moment };
  totalTetkikSayi: number;
  totalToplamTetkikFiyat: number;
  totalTetkikFiyat: number;
  // ****************************
  selected:any;
  settingsPerson: AutoCompleteSettings;
  @Output() onSelectPersonInfo: EventEmitter<any> = new EventEmitter();
  personelViewFilter: PersonelViewFilter = new PersonelViewFilter();
  ///********** */
  constructor(private apiService: ApiService, private tetkikFiyatService: TetkikFiyatService,
    private modalService: BsModalService, private personelServices: PersonelServices) {

    this.filter = new TetkikFiyatlandirmaFilter();
    this.grid = new PricingsGridComponent_Models.GridPrice(this.apiService, this.tetkikFiyatService, this.modalService, this.pricingsFilter);

  }



  ngOnInit() {
    //***
    // this.settingsPerson.single=true;
    debugger;
    this.personAutoComplete();
    //***** */

  }
  personAutoComplete() {
    this.settingsPerson = new AutoCompleteSettings();
    this.settingsPerson.onDataService = o => {
      this.personelViewFilter.PersonelAdi = o;
      return this.personelServices.GetAutoComplete(this.personelViewFilter).then(o => {

        var array = o as Array<PersonelViewModel>
        var res = [];
        for (let index = 0; index < array.length; index++) {
          debugger;
          const element = array[index];
          var item = new AutocompleteModel();
          item.Name = element.PersonelAdi
          item.id = element.ID
          res.push(item);
        }
        return res;
      });
    };
  }
  getTotalTetkikSayi() {
    let totalTetkikSayi = 0;
    this.grid.data.list.forEach(element => {
      totalTetkikSayi += element.TetkikSayi;
    });
    return totalTetkikSayi;
  }
  getTotalToplamTetkikFiyat() {
    let totalToplamTetkikFiyat = 0;
    this.grid.data.list.forEach(element => {
      totalToplamTetkikFiyat += element.ToplamTetkikFiyat;
    });
    return totalToplamTetkikFiyat;
  }
  getTotalTetkikFiyat() {
    let totalTetkikFiyat = 0;
    this.grid.data.list.forEach(element => {
      totalTetkikFiyat += element.TetkikFiyat;
    });
    return totalTetkikFiyat;
  }
  public onChangePerson(item: any) {  
    debugger;  
    this.selected = item
   }
}
export class pricingsFilter {
  tarihRange: { start: moment.Moment, end: moment.Moment };
  osgbAdi: any;
  firmaAdi: any;

}



namespace PricingsGridComponent_Models {
  export class GridPrice extends Grid.GridControl<TetkikFiyatlandirmaViewModel> {
    constructor(private _apiService: ApiService, private _tetkikFiyatService: TetkikFiyatService, private modalService: BsModalService, private pricingsFilter: pricingsFilter) {
      super();
    }
    filter = new Grid.GridInputModel(new TetkikFiyatlandirmaFilter());
    public onChangeOsgb(item: any) {
      this.pricingsFilter.osgbAdi = item.KurumAd;
      //item.OsgbID
      //item.FirmaID;
    }
    public onChangeFirma(item: any) {
      this.pricingsFilter.firmaAdi = item.KurumAd;
      //item.OsgbID
      //item.FirmaID;
    }

    getFilter() {


      // this.filter.paging.pageNumber = this.model.paging.pageNumber;
      // this.filter.paging.count = this.model.paging.count;
      this.filter.paging.count = 200;
      let item = this.filter.filter;

      if (this.pricingsFilter.tarihRange) {
        item.BasTarih = this.pricingsFilter.tarihRange.start.toJSON();
        item.BitTarih = this.pricingsFilter.tarihRange.end.toJSON();

      }
      item.OsgbAdi = this.pricingsFilter.osgbAdi
      item.FirmaAdi = this.pricingsFilter.firmaAdi;

      return this.filter;
    };

    onRefresh() {

      var item = this.getFilter()
      var filter = item.filter;
      this._tetkikFiyatService.GetTetkikFiyatList(item).then(o => {
        this.data = o
      })
      console.log(item)
    };




  }
}
