import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { PaketSaveModel } from '../../models/PaketSaveModel';
import { Subject } from 'rxjs';
import { PaketTanimlamaServices } from '../../services/PaketTanimlamaService';
import { PaketDetayModel } from '../../models/PaketDetayModel';

@Component({
  selector: 'app-save-paket',
  templateUrl: './save-paket.component.html',
  styleUrls: ['./save-paket.component.css']
})
export class SavePaketComponent implements OnInit {

  public onClose: Subject<string>;
  public paket: any = undefined;
  modalTitle: any;
  idVisible: boolean;

  paketSaveModel: PaketSaveModel = new PaketSaveModel();
  paketDetayModel: PaketDetayModel = new PaketDetayModel();
  ddlOsgb: any[];
  ddlFirma: any[];
  constructor(public bsModalRef: BsModalRef, public paketServices: PaketTanimlamaServices) { }

  ngOnInit() {
    this.onClose = new Subject();
    this.getOsgb();
  
    if (this.paket == undefined) {
      this.modalTitle = "Paket Ekleme";
      this.idVisible = false;
    }
    else {
      this.modalTitle = "Paket Güncelleme";
      this.idVisible = true;
      this.paketSaveModel.Id = this.paket.PaketID;
      this.paketSaveModel.PaketAdi = this.paket.PaketAdi
      this.paketSaveModel.PaketUcreti = this.paket.PaketUcreti
      this.paketSaveModel.OSGBAdi = this.paket.OsgbAdi
      this.paketSaveModel.FirmaAdi = this.paket.FirmaAdi
     
    
    }   
  }
  getOsgb() {
    this.paketServices.getOsgb().then(o => {
      this.ddlOsgb = o;
      this.ddlOsgb.sort((a, b) => a.OSGBAdi < b.OSGBAdi ? -1 : a.OSGBAdi > b.OSGBAdi ? 1 : 0)
    });
  }
  getFirmaByOsgbId(item) {
    let itemID = item.substr(0, item.indexOf(':'));
   // this.paketSaveModel.OSGBAdi = this.ddlOsgb[itemID].OSGBAdi;
    let osgbID = item.substr(item.indexOf(' ') + 1);
    this.paketSaveModel.OSGBId= osgbID;
    let paketDetayModel: PaketDetayModel = new PaketDetayModel();
    paketDetayModel.ID = osgbID;
    this.paketServices.GetFirmaList(paketDetayModel).then(o => {
      this.ddlFirma = o;
      this.ddlFirma.sort((a, b) => a.FirmaAdi < b.FirmaAdi ? -1 : a.FirmaAdi > b.FirmaAdi ? 1 : 0)
    });
  }
  onFirmaChange(item){
    let FirmaID = item.substr(item.indexOf(' ') + 1);
    this.paketSaveModel.FirmaId = FirmaID;
  }
  onConfirm(st: any) {
    debugger;
    console.log(this.paketSaveModel);
    this.paketServices.Save(this.paketSaveModel).then(o => {
      this.onClose.next("confirm");
      this.bsModalRef.hide();
    });
  }
  onCancel(st: any) {
    this.onClose.next("cancel");
    this.bsModalRef.hide();
  }

}
