import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DepartmentViewModel } from '../../models/DepartmentViewModel';
import { SaveDepartmentViewModel } from '../../models/SaveDepartmentViewModel';
import { OsgbFirmaServices } from '../../services/OsgbFirmaService';

@Component({
  selector: 'app-new-department-modal',
  templateUrl: './new-department-modal.component.html',
  styleUrls: ['./new-department-modal.component.css']
})
export class NewDepartmentModalComponent implements OnInit {
  public FirmaID;
  public DepartmentID;
  public departmanName:any;
  saveDepartmentViewModel:SaveDepartmentViewModel=new SaveDepartmentViewModel();
  departmentViewModel:DepartmentViewModel=new DepartmentViewModel();
  
  constructor(public bsModalRef: BsModalRef,private modalService: BsModalService,private osgbFirmaServices:OsgbFirmaServices) { }

  ngOnInit() {
    this.departmentViewModel.DepartmentID=this.DepartmentID;
    if(this.DepartmentID!=null){
      this.osgbFirmaServices.GetByDepartmentID(this.departmentViewModel).then(o=>{
        this.saveDepartmentViewModel=o;
        this.departmanName=this.saveDepartmentViewModel.DepartmentName;
        this.FirmaID=this.saveDepartmentViewModel.FirmaID;
      })
    }
  }

  public onSave(){
    this.saveDepartmentViewModel.FirmaID=this.FirmaID;
    this.saveDepartmentViewModel.DepartmentName=this.departmanName;
    this.osgbFirmaServices.SaveDepartment(this.saveDepartmentViewModel).then(o=>{
      this.modalService.setDismissReason('save');
      this.bsModalRef.hide();
    })
  }
}

  // public onSilFirma() {
  //   this.apiservice.callPostService(this.deleteCompanyUrl,{ FirmaID : this.FirmaID,Aktif:false }).subscribe(
  //     resp =>{
  //       console.log(resp);
  //       this.modalService.setDismissReason('delete-Firma');
  //       this.bsModalRef.hide();
  //     }
  //   );
  // }

