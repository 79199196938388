import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { resolve } from 'url';
import { cookieService } from './cookieService';
import { Router } from '@angular/router';




@Injectable({
  providedIn: 'root'
})
export class authenticationService {

  token: string
  authCookieName: string = 'medlife_auth'

  constructor(private cookieService: cookieService, private routing: Router) {
    this.token = this.getToken();
    console.log("Token:" + this.token);
  }
  public authenticate(access_token) {
    this.token = access_token;
    this.cookieService.setCookie(this.authCookieName, access_token, 2)
  }

  public getToken() {
    return this.cookieService.getCookie(this.authCookieName);
  }

  public logout() {
    this.token = undefined;
    this.cookieService.eraseCookie(this.authCookieName)
  }
  public redirectToLogin() {
    this.routing.navigate(["login"]);
  }
  public logoutAndRedirect() {
    this.logout();
    this.redirectToLogin();
  }
  public isAuthenticated(data: any) {
    if (data.TypeVal == 2)
      return false;
    return true;
  }

}
