import { Component, OnInit } from '@angular/core';
import { DashBoardAnnouncementViewModel, DashBoardCountViewModel, DashBoardGroupCountViewModel, DashBoardNoticationViewModel } from '../../models/DashBoardViewModels';
import { DashBoardService } from './../../services/DashBoardService';


@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.css']
})
export class DashboardMainComponent implements OnInit {
  announcementViewModel:DashBoardAnnouncementViewModel=new DashBoardAnnouncementViewModel();
  noticationViewModel:DashBoardNoticationViewModel=new DashBoardNoticationViewModel();
  constructor(private service: DashBoardService) { }
  countViewModel:Array<DashBoardCountViewModel>
  groupCountViewModel:Array<DashBoardGroupCountViewModel>
  ngOnInit() {
    this.getCountList();
     this.getGroupCountList();
     this.getAnnouncementList();
     this.getNotificationList();
  }
  getCountList() {
    this.service.GetCounterList().then(o => {
      this.countViewModel = o
    })
  }
  getGroupCountList() {
    this.service.GetGroupCounterList().then(o => {
    
      this.groupCountViewModel=o;
      console.log(this.groupCountViewModel)
    });
  }
  getAnnouncementList(){
    this.service.GetAnnouncementList().then(o=>{
     
      this.announcementViewModel=o;
      console.log(o)
    })
  }
  getNotificationList(){
    this.service.GetNotificationList().then(o=>{
      this.noticationViewModel=o;
      console.log(o)
    })
  }
}
