import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
import { UserViewFilter } from '../models/UserViewFilter';
import { UserViewModel } from '../models/UserViewModel';
import { UserDetayModel } from '../models/UserDetayModel';
import { UserUIModel } from '../models/UserUIModel';
import { RoleModel } from '../models/RoleModel';
import { RoleAddModel } from '../models/RoleAddModel';

@Injectable({
  providedIn: 'root'
})
export class KullaniciTanımlamaService {

  constructor(private api: ApiService) { }
  
  public GetUserList(model: Grid.GridInputModel<UserViewFilter>): Promise<Grid.GridOutputModel<UserViewModel>> {
    return this.api.callService("User/GetUserList", model).then(o => {
      var res = new Grid.GridOutputModel<UserViewModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
    });
  }
  public GetById(model: UserDetayModel): Promise<any> {
    return this.api.callService("User/GetById ", model).then(o => {
      return o;
    });
  }
  public Save(model: UserUIModel): Promise<any> {
    return this.api.callService("User/Save", model).then(o => {
      return o;
    });
  }
  public Delete(model: UserDetayModel): Promise<any> {
    return this.api.callService("User/Delete", model).then(o => {
      return o;
    });
  }
  public GetRoleList(): Promise<RoleModel[]> {
    return this.api.callService("User/GetRoleList", null).then(o => {
      return o as RoleModel[];
    });
  }
  public SaveRol(model: RoleAddModel): Promise<any> {
    return this.api.callService("User/SaveRol", model).then(o => {
      return o;
    });
  }
  public GetRolById(model: UserDetayModel): Promise<any> {
    return this.api.callService("User/GetRolById", model).then(o => {
      return o;
    });
  }
  public GetKeyList(model: UserViewFilter): Promise<UserViewModel[]> {
    return this.api.callService("User/GetKeyList", model).then(o => {
      return o as UserViewModel[];
    });
  }
}
