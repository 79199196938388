import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { PersonelTetkikServices } from '../../services/PersonelTetkikService';
import { Subject } from 'rxjs';
import { ApiService } from '../../../../../Services/api.service';
import { BarkodModel } from '../../models/BarkodModel';
import { PersonelServices } from '../../services/PersonelService';

@Component({
  selector: 'app-personeltetkik',
  templateUrl: './personeltetkik.component.html',
  styleUrls: ['./personeltetkik.component.css']
})
export class PersoneltetkikComponent implements OnInit {
  public onClose: Subject<any>;
  public tetkikList;
  public personelIDList;
  public selectedItem;
  public selectedItems: any[] = [];
  constructor(public bsModalRef: BsModalRef, private tetkikService: PersonelServices, private apiservice: ApiService, ) {
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

  public saveExaminationList() {


    this.onClose.next(this.selectedItems);
    this.bsModalRef.hide();
    // TODO: modal ekranında seçim yapılmama durumu kontrol edilmeli
    // var model = new BarkodModel;
    // model.PersonelIDList = this.personelIDList;
    //model.TetkikGrubuList = this.selectedItems;

    // this.tetkikService.GetBarkodByPersonelID().then(o => {
    //   this.apiservice.fileOperation.download(o);
    //   this.onClose.next('close');
    //   this.bsModalRef.hide();
    // });
  }

  public onCheckChange(event, tetkik) {
    if (event.target.checked) {
      this.selectedItems.push(tetkik);
    }
    else {
      const index: number = this.selectedItems.indexOf(tetkik);
      if (index !== -1) {
        this.selectedItems.splice(index, 1);
      }
    }
    console.log(this.selectedItems);
  }


}
