import { Component, OnInit, Input } from '@angular/core';
import { KullaniciTanımlamaService } from '../../../../services/KullaniciTanımlamaService';
import { RoleModel } from '../../../../models/RoleModel';
import { RoleAddModel } from '../../../../models/RoleAddModel';
import { UserDetayModel } from '../../../../models/UserDetayModel';

@Component({
  selector: 'app-kullaniciyetki',
  templateUrl: './kullaniciyetki.component.html',
  styleUrls: ['./kullaniciyetki.component.css']
})
export class KullaniciyetkiComponent implements OnInit {

  @Input() set kullaniciId(value: any) {
    if (value == undefined)
      return;
    this.userCurrentModel.ID = value;
    this.roleAddModel.UserID = value;
    this.getYetki();
  }
  ddlYetki: RoleModel[] = [];
  roleAddModel: RoleAddModel = new RoleAddModel();
  userDetayModel: UserDetayModel = new UserDetayModel();
  userCurrentModel: UserDetayModel = new UserDetayModel();
  constructor(private userService: KullaniciTanımlamaService) { }

  ngOnInit() {
    this.getRoleList();
  }
  ddlYetkiChange(item) {
    let itemID = item.substr(item.indexOf(' ')+1);
    this.roleAddModel.RoleID = itemID;
  }
  onSave() {
    console.log(this.roleAddModel);
    this.userService.SaveRol(this.roleAddModel).then(o => {
    });
  }
  getYetki() {
    this.userService.GetRolById(this.userCurrentModel).then(o => {
      this.roleAddModel.RoleID = o;
    });
  }

  getRoleList() {
    this.userService.GetRoleList().then(o => {
      this.ddlYetki = o;
    });
  }

}
