import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataManagerService {

  public FirmaId: any;
  public OsgbId: any;
  public GonderiPersonelID: any;
  public selectedPersonelList: any;
  public BasTarih: any;
  public BitTarih: any;
  constructor() { }

  public setFirmaID(id) {
    this.FirmaId = id;
  }

  public getFirmaID() {
    return this.FirmaId;
  }

  public setOsgbID(id) {
    this.OsgbId = id;
  }

  public getOsgbID() {
    return this.OsgbId;
  }

  public setPersonelID(id) {
    this.GonderiPersonelID = id;
  }

  public getPersonelID() {
    return this.GonderiPersonelID;
  }

  public setPersonelIDList(list: any[]) {
    this.selectedPersonelList = list;
  }

  public getPersonelIDList() {
    return this.selectedPersonelList;
  }
  public setBasTarih(BasTarih: any) {
    this.BasTarih = BasTarih;
  }
  public getBasTarih() {
    return this.BasTarih;
  }
  public setBitTarih(BitTarih: any) {
    this.BitTarih = BitTarih;
  }
 
}
