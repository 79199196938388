import { Component, OnInit, ViewChild } from '@angular/core';
import { OsgblistComponent } from './osgblist/osgblist.component';

@Component({
  selector: 'app-osgbs',
  templateUrl: './osgbs.component.html',
  styleUrls: ['./osgbs.component.css']
})
export class OsgbsComponent implements OnInit {

  @ViewChild(OsgblistComponent, { static: false })
  public osgbListComponent: OsgblistComponent;

  constructor() { }

  ngOnInit() {
  }
}
