import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
import { Grid } from '../../../../../shared/models/UIControls/grid-control';
import { IslemRaporServices } from '../../services/IslemRaporService';
import { IslemListResult } from '../../models/IslemListResult';
import { IslemListInput } from '../../models/IslemListInput';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ddlSettings } from '../../modals/personeltetkikmodal/ddlSettings';
import { LaboratuvarServices } from '../../../Settings/services/LaboratuvarServices';
moment.locale('tr');
@Component({
  selector: 'app-rapor',
  templateUrl: './rapor.component.html',
  styleUrls: ['./rapor.component.css']
})
export class RaporComponent implements OnInit {
  public ddlSettings: ddlSettings = new ddlSettings();
  public collapseSidebar: boolean = false;
  public sidebarClass: string = 'col-md-2 h-100';
  public maindivClass: string = 'col-md-10 h-100';
  public ddlLabList: Array<any>
  public ddlLabSelectedItems: any[]=[]
  public ddlLabSettings;
  
  public multiSelectData = [];
  public multiSelectedItems = [];
  public multiSelectSettings: IDropdownSettings = {};
  ranges: any = {
    'Bugün': [moment(), moment()],
    'Dün': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Son 7 gün': [moment().subtract(6, 'days'), moment()],
    'Son 30 gün': [moment().subtract(29, 'days'), moment()],
    'Bu Ay': [moment().startOf('month'), moment().endOf('month')],
    'Önceki Ay': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  constructor(private dataRapor: IslemRaporServices,public labServices: LaboratuvarServices) { }
  ngOnInit() {
    this.ddlLabSettings = this.ddlSettings.ddlDizaynSettings;
    this.multiSelectSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Tümünü Seç',
      unSelectAllText: 'Tümünü Kaldır',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
    this.multiSelectData = [
      { id: 1, text: 'OSGB’ye Göre Grupla' },
      { id: 2, text: 'Firmaya Göre Grupla' },
      { id: 3, text: 'Personele Göre Grupla' },
      { id: 4, text: 'Tetkik Grubunu Grupla' },
      { id: 5, text: 'Tetkiğe Göre Grupla' },
      { id: 6, text: 'Ödeme Türüne Göre Grupla' },
      { id: 7, text: 'Sadece Onaylananlar Görünsün' },
      { id: 8, text: 'Tarihe  Göre Grupla' },
      { id: 9, text: 'Sadece Onaylayanlar Gorunsun' }
    ];
    this.getLabList();
    this.ddlLabSelectedItems.push({ID: 1, Name: "Medlife"})
  }
  frmFilter = new FrmFilter();
  gridRaporTetkik = new RaporComponent_Models.GridRapor(this.dataRapor, this.frmFilter);

  onSearch() {
    console.log(this.multiSelectedItems);
    this.frmFilter.ddlData = this.multiSelectedItems;
    this.frmFilter.ddlLab=this.ddlLabSelectedItems;
    this.gridRaporTetkik.onRefresh();
    // this.multiSelectedItems=[];
    this.gridRaporTetkik.filter.filter.osgbyeGore = false;
    this.gridRaporTetkik.filter.filter.firmayaGore = false;
    this.gridRaporTetkik.filter.filter.personeleGore = false;
    this.gridRaporTetkik.filter.filter.tetkikGurubunaGore = false;
    this.gridRaporTetkik.filter.filter.tetkikAdinaGore = false;
    this.gridRaporTetkik.filter.filter.odemeTuruneGore = false;
    this.gridRaporTetkik.filter.filter.onaylananlar = false;
    this.gridRaporTetkik.filter.filter.TariheGore = false;
    this.gridRaporTetkik.filter.filter.OnaylayanlarGorunsun = false;
    
  }
  onExportExel() {
    debugger;
    this.gridRaporTetkik.onExport_Excel();
  }
  getLabList(){
    this.labServices.GetKeyList().then(o => {
      this.ddlLabList = o;
      console.log(this.ddlLabList)
    })
  }
 }

export class FrmFilter {

  tarihRange: { start: moment.Moment, end: moment.Moment };
  osgbAdi: any;
  firmaAdi: any;
  personelAdi: any;
  tetkikTuru: any;
  odemeTuruneGore: any;
  cmbGrup: any;
  tarihegore:any;
  tetkikadi:any;
  onaylananlar:any;
  ddlData: any[];
  ddlLab:any[];
  constructor() {
    this.cmbGrup = { data: [] };
    var dt = this.cmbGrup.data;
    for (var i = 0; i < 10; i++) {
      dt.push(false);
    }
  }
}
namespace RaporComponent_Models {

  export class GridRapor extends Grid.GridControl<IslemListResult> {
    public direction: number = 1;
    
    constructor(private dataRapor: IslemRaporServices, private frmFilter: FrmFilter) {
      super();
    }
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    filter = new Grid.GridInputModel<IslemListInput>(new IslemListInput());
    getFilter() {
     
      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;
      let item = this.filter.filter;
      let frm = this.frmFilter;
      let f = this.frmFilter.cmbGrup;

      this.frmFilter.ddlData.forEach(item => {
        if (item.id == 1) {
          this.filter.filter.osgbyeGore = true;
        }
        else if (item.id == 2) {
          this.filter.filter.firmayaGore = true;
        }
        else if (item.id == 3) {
          this.filter.filter.personeleGore = true;
        }
        else if (item.id == 4) {
          this.filter.filter.tetkikGurubunaGore = true;
        }
        else if (item.id == 5) {
          this.filter.filter.tetkikAdinaGore = true;
        }
        else if (item.id == 6) {
          this.filter.filter.odemeTuruneGore = true;
        }
        else if (item.id == 7) {
          this.filter.filter.onaylananlar = true;
        }
        else if (item.id == 8) {
          this.filter.filter.TariheGore = true;
        }
        else if (item.id == 9) {
          this.filter.filter.OnaylayanlarGorunsun = true;
        }
        else {}
      })
      var f2 = this.filter.filter;
      f2.LabID=Number(frm.ddlLab.map(o=>o.ID))
      f2.baslangicTarihi = frm.tarihRange.start;
      f2.bitisTarihi = frm.tarihRange.end;
      f2.osgbadi = frm.osgbAdi;

      f2.personeladi = frm.personelAdi;
      f2.tetkikturu = frm.tetkikTuru;
      f2.firmaadi=frm.firmaAdi;
      f2.tetkikadi=frm.tetkikadi;

      return this.filter;
      
    };
    onExport_Excel(grupData?) {
      let item = this.getFilter();
      debugger;
      this.dataRapor.exportIslemRaporExcel(item).then(o => {        
      })
    };
    onRefresh() {
      var item = this.getFilter();
      return this.dataRapor.getIslemList(item).then(o => {
        this.data = o;
        
        return o;
      });
      
    };
   
  }

}
