export class OsgbFirmaModel {
  OsgbId: any;
  FirmaId: any;
  FirmaKodu: any;
  FirmaAdi: any;
  FirmaVD: any;
  FirmaVN: any;
  FirmaAdres: any;
  FirmaIl: any;
  FirmaIlce: any;
  FirmaTel: any;
  FirmaFax: any;
}
