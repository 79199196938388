import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { fromEvent } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-packets',
  templateUrl: './packets.component.html',
  styleUrls: ['./packets.component.css']
})
export class PacketsComponent implements OnInit {
  public getPacketListUrl: string = "PersonelTetkik/GetPaketList";
  public SavePaket: string = "PersonelTetkik/SavePaket";
  @ViewChild('filterSection', {static: false}) input: ElementRef;
  public direction: number = 1;

  public model: any = {
    personelIDList: undefined,
    firmaID: undefined,
    paketID: undefined,
    medlife: true,
    yapildi: true,
    paketteYapildi: false,
    tetkikTuru: "",
    basTarih:"",
    bitTarih:""
  };

  public personelIDList: any;
  public firmaID: any;
  public osgbID: any;
  public errorStatus: boolean;
  public BasTarih:""
  public BitTarih:""
  public selectedItem: any;

  packetsList: any = {
    selectedItem: undefined,
    totalCount: 30,
    data: [],
    model: {
      Filter: {},
      Paging: {
        PageNumber: 1,
        Count: 10
      },
      Sorting: {}
    }
  };




  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService, private modalService: BsModalService) { }

  ngOnInit() {
    this.errorStatus = false;
    //console.log(this)
    //console.log(this.firmaID)
    //packetsList.model.Filter.FirmaAdi
    //packetsList.model.Filter.OsgbAdi
    this.packetsList.model.Filter['PaketFirmaID'] = this.firmaID;
    this.packetsList.model.Filter['PaketOSGBID'] = this.osgbID;
    this.getPacketsList();
    this.onSorting('PaketAdi')
    this.direction=1;

  }
  ngAfterViewInit() {
    
    let tableDom = this.input.nativeElement;
    let items = tableDom.querySelectorAll('.filterInput');
  
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      fromEvent(item, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(2000),
          distinctUntilChanged(),
          tap((text) => {
            if(item.value.length>2)
            this.getPacketsList();
            else if(item.value.length==0)
            this.getPacketsList();
          })
        )
        .subscribe();
    }
    
  }
  public getPacketsList() {
    this.apiservice.callPostService(this.getPacketListUrl, this.packetsList.model).subscribe(
      (resp: any) => {
        if (resp !== null && 'Data' in resp) {
        //   resp.Data.List.sort(function(a, b) {
        //     return ('' + a.PaketAdi).localeCompare(b.PaketAdi);          
        // });
          this.packetsList.data = resp.Data.List;
          this.packetsList.totalCount = resp.Data.TotalCount;
       
       
        }
      }, err => {
        console.log(err);
      }
    );
  }

  public onSorting(colName) {
    if (this.direction == 1) {
      this.direction = 0;
    } else {
      this.direction = 1;
    }
    this.packetsList.model.Sorting = { colName: colName, direction: this.direction };
    this.getPacketsList();
  }
  public onSelectItem(item: any) {
    if (this.selectedItem === item.PaketID) {
      this.selectedItem = undefined;
    } else {
      this.selectedItem = item.PaketID;
    }
  }

  public getRowClass(item) {
    return this.selectedItem === item.PaketID ? 'table-info' : '';
  }

  onPageChanged(event) {
    this.packetsList.model.Paging.PageNumber = event.page;
    this.getPacketsList();
  }

  public onsavePacket() {
    this.errorStatus = false;
    let model = Object.assign({}, this.model);
    model['paketID'] = this.selectedItem;
    model['personelIDList'] = this.personelIDList;
    debugger;
    if (model.tetkikTuru != "") {
      this.apiservice.callPostService(this.SavePaket, model).subscribe(
        resp => {
          this.modalService.setDismissReason('save-packet');
          this.bsModalRef.hide();
        }, err => {

        }
      )
    } else {
      this.errorStatus = true;
    }
  }

  public onsaveAllPacket() {
    this.errorStatus = false;
    let model = Object.assign({}, this.model);
    model['paketID'] = this.selectedItem;
    model['firmaID'] = this.firmaID;
    model.basTarih=this.BasTarih;
    model.bitTarih=this.BitTarih;
    debugger;
    if (model.tetkikTuru != "") {
      this.apiservice.callPostService(this.SavePaket, model).subscribe(
        resp => {
          this.modalService.setDismissReason('save-packet');
          this.bsModalRef.hide();
        }, err => {

        }
      )
    } else {
      this.errorStatus = true;
    }
  }

}
