import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
import { TetkikSaveModel } from '../../definitions/models/TetkikSaveModel';
import { DeleteModel } from '../models/DeleteModel';
import { DeleteByFirmaIDModel } from '../models/DeleteByFirmaIDModel';
import { DeleteByPersonelListModel } from '../models/DeleteByTetkikListModel';
import { TetkikViewFilter } from '../models/TetkikViewFilter';
import { PersonelTetkikViewModel } from '../models/PersonelTetkikViewModel';
import { TetkikResultModel } from '../models/TetkikResultModel';
import { PaketSaveModel } from '../models/PaketSaveModel';
import { SaveOdemeAlModel } from '../models/SaveOdemeAlModel';
import { SaveIadeEtModel } from '../models/SaveIadeEtModel';
import { TahsilatViewFilter } from '../models/TahsilatViewFilter';
import { TahsilatViewModel } from '../models/TahsilatViewModel';
import { DeleteByPersonelIDListAndTetkikID } from '../models/DeleteByPersonelIDListAndTetkikID';
import { LaboratuvarViewModel } from '../models/LaboratuvarViewModel';
import { TetkikIDListModel } from '../models/IslemIDListModel';
import { SaveTopluLaboratuvarSonucModel } from '../models/SaveTopluLaboratuvarSonucModel';
import { HbysBarkodModel } from '../models/HbysBarkodModel';
import { BarkodViewFilter } from '../models/BarkodViewFilter';
import { PersonelViewFilter } from '../models/PersonelViewFilter';
import { BarkodViewModel } from '../models/BarkodViewModel';
@Injectable({
  providedIn: 'root'
})
export class PersonelTetkikServices {

  constructor(private api: ApiService) { }
 

  public RaporResult(model: Grid.GridInputModel<TetkikViewFilter>): Promise<any>{
    return this.api.callService("PersonelTetkik/RaporResult", model).then(o => {
      this.api.fileOperation.download(o);
    });
  };
  public saveTetkik(model: TetkikSaveModel): Promise<any> {
    return this.api.callService("Personeltetkik/saveTetkik", model).then(o => {
      return o;
    });
  }
  public savePaket(model: PaketSaveModel): Promise<any> {
    return this.api.callService("Personeltetkik/SavePaket", model).then(o => {
      return o;
    });
  }
  public DeleteByTetkikList(model: TetkikIDListModel): Promise<any> {
    return this.api.callService("Personeltetkik/DeleteByTetkikList", model).then(o => {
      return o;
    });
  }
  public Delete(model: DeleteModel): Promise<any> {
    return this.api.callService("Personeltetkik/Delete", model).then(o => {
      return o;
    });
  }
  public DeleteByFirmaID(model: DeleteByFirmaIDModel): Promise<any> {
    return this.api.callService("Personeltetkik/DeleteByFirmaID", model).then(o => {
      return o;
    });
  }
  public DeleteBypersonelIDListAndTetkikID(model: DeleteByPersonelIDListAndTetkikID): Promise<any> {
    return this.api.callService("Personeltetkik/DeleteBypersonelIDListAndTetkikID", model).then(o => {
      return o;
    });
  }
  public DeleteByPersonelList(model: DeleteByPersonelListModel): Promise<any> {
    return this.api.callService("Personeltetkik/DeleteByPersonelList", model).then(o => {
      return o;
    });
  }
  public GetTetkikGruplariList(model: DeleteByPersonelListModel): Promise<string[]> {
    return this.api.callService("Personeltetkik/GetTetkikGruplariList", model).then(o => {
      return o as string[];
    });
  }
  public GetBarkodByIslemID(model: Grid.GridInputModel<TetkikViewFilter>): Promise<string> {
    return this.api.callService("Personeltetkik/GetBarkodByIslemID", model).then(o => {
      return o as string;
    });
  }
  public getTetkikList(model: Grid.GridInputModel<TetkikViewFilter>): Promise<Grid.GridOutputModel<PersonelTetkikViewModel>> {
    return this.api.callService("PersonelTetkik/GetTetkikListByPersonelId", model).then(o => {
      var res = new Grid.GridOutputModel<PersonelTetkikViewModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
    });
  };
  public GetTekrarOnayIste(model: TetkikResultModel): Promise<string> {
    return this.api.callService("Personeltetkik/GetTekrarOnayIste", model).then(o => {
      return o as string;
    });
  }
  public GetLabSonucByID(model: TetkikResultModel): Promise<LaboratuvarViewModel[]> {
    return this.api.callService("Personeltetkik/GetLabSonucByID", model).then(o => {
      return o as LaboratuvarViewModel[];
    });
  }
  public GetTahsilatList(model: TahsilatViewFilter): Promise<TahsilatViewModel[]> {
    return this.api.callService("PersonelTetkik/GetTahsilatList", model).then(o => {
      return o as TahsilatViewModel[];
    });
  };
  public SaveOdemeAl(model: SaveOdemeAlModel): Promise<any> {
    return this.api.callService("Personeltetkik/SaveOdemeAl", model).then(o => {
      return o;
    });
  }
  public SaveIadeEt(model: SaveIadeEtModel): Promise<any> {
    return this.api.callService("Personeltetkik/SaveIadeEt", model).then(o => {
      return o;
    });
  }
  // işlem id hbys barkod
  public SaveHbysBarkod(model: HbysBarkodModel): Promise<any> {
    return this.api.callService("Personeltetkik/SaveHbysBarkod", model).then(o => {
      return o;
    });
  }
// işlem id
  public GetHbysBarkodByIslemID(model: HbysBarkodModel): Promise<string> {
    return this.api.callService("Personeltetkik/GetHbysBarkodByIslemID", model).then(o => {
      return o as string;
    });
  }

  
  public SaveLaboratuvaraGiris(model: BarkodViewFilter): Promise<any> {
    return this.api.callService("Personeltetkik/SaveLaboratuvaraGiris", model).then(o => {
      return o;
    });
  }
  public GetPersonelTetkikModalList(model: Grid.GridInputModel<PersonelViewFilter>): Promise<Grid.GridOutputModel<BarkodViewModel>> {
    return this.api.callService("PersonelTetkik/GetPersonelTetkikModalList", model).then(o => {
      var res = new Grid.GridOutputModel<BarkodViewModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
    });
  };
  public SaveTransfer(model: Grid.GridInputModel<PersonelViewFilter>): Promise<any> {
    return this.api.callService("Personeltetkik/SaveTransfer", model).then(o => {
      return o;
    });
  }
  public SaveSevk(model: Grid.GridInputModel<PersonelViewFilter>): Promise<any> {
    return this.api.callService("Personeltetkik/SaveSevk", model).then(o => {
      return o;
    });
  }
  public SevkOnay(model: Grid.GridInputModel<PersonelViewFilter>): Promise<any> {
    return this.api.callService("Personeltetkik/SevkOnay", model).then(o => {
      return o;
    });
  }
  public TransferOnay(model: Grid.GridInputModel<PersonelViewFilter>): Promise<any> {
    return this.api.callService("Personeltetkik/TransferOnay", model).then(o => {
      return o;
    });
  }
  public TransferRed(model: Grid.GridInputModel<PersonelViewFilter>): Promise<any> {
    return this.api.callService("Personeltetkik/TransferRed", model).then(o => {
      return o;
    });
  }
}
