import { Component, OnInit, ViewChild } from '@angular/core';
import { TetkiklistComponent } from './tetkiklist/tetkiklist.component';

@Component({
  selector: 'app-tetkik',
  templateUrl: './tetkik.component.html',
  styleUrls: ['./tetkik.component.css']
})
export class TetkikComponent implements OnInit {


  @ViewChild(TetkiklistComponent, { static: false })
  public tetkikListComponent: TetkiklistComponent;


  constructor() { }

  ngOnInit() {
  }

}
