import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-exam-result-file-dialog',
  templateUrl: './exam-result-file-dialog.component.html',
  styleUrls: ['./exam-result-file-dialog.component.css']
})
export class ExamResultFileDialogComponent implements OnInit {

  public dialogParams: any = {
    tetkikID: 0
  }
  public eventClose: Subject<any> = new Subject()

  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService, private modalService: BsModalService) {

  }
  ngOnInit() {
    this.frmFileResult.tetkikID = this.dialogParams.tetkikID
  }
  data: any = {
    uploadFile: (file: any) => {
      return this.apiservice.callFormDataService("FileOperation/Upload", [file]);
    },
    save: (dt: any) => {
      debugger;
      return this.apiservice.callPostService("PersonelTetkik/SavePdf", dt);

    }
  }
  errors: any[] = [];

  selectedFiles: any[] = [];

  frmFileResult: ExamResultFile.FileResult = new ExamResultFile.FileResult();

  uploadedFiles: any[] = [];
  handleFileChoose = (event) => {

    this.frmFileResult.fileIDList = [];
    this.selectedFiles = event.target.files;
  }
  onUploadFile() {
    for (var i = 0; i < this.selectedFiles.length; i++) {
      var item = this.selectedFiles[i];
      this.data.uploadFile(item).subscribe(
        (resp: any) => {
          if (!resp || !resp.Data)
            return;
          let dt = resp.Data;
          this.frmFileResult.fileIDList.push(dt[0]);
        }
      );
    }
  }
  onSave() {

    this.data.save(this.frmFileResult).subscribe(o => {
      if (!o || !o.Data)
        return;
      this.eventClose.next("success");
      this.bsModalRef.hide();
    });

  }
}
export namespace ExamResultFile {

  export class FileResult {

    fileIDList: string[] = [];
    oncekilerSilinsinMi: boolean = false;
    tetkikID: number = 0

  };
}
