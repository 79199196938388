import { Component, OnInit, Input } from '@angular/core';
import { Grid } from '../../../../../../../shared/models/UIControls/grid-control';
import { PaketTanimlamaServices } from '../../../../services/PaketTanimlamaService';
import { PaketTetkikFilter } from '../../../../models/PaketTetkikFilter';
import { BsModalService } from 'ngx-bootstrap';
import { AddTetkiktopaketComponent } from '../../../../modals/add-tetkiktopaket/add-tetkiktopaket.component';
import { ConfirmationDialogComponent } from '../../../../../../../shared/modals/confirmation-dialog/confirmation-dialog.component';
import { PaketDetayModel } from '../../../../models/PaketDetayModel';

@Component({
  selector: 'app-pakettetkiklist',
  templateUrl: './pakettetkiklist.component.html',
  styleUrls: ['./pakettetkiklist.component.css']
})
export class PakettetkiklistComponent implements OnInit {
  @Input() set paketId(value: any) {
    if (value == undefined)
      return;
    this.paketTetkikFilter.PaketID = value;
    this.paketID = value;
    this.gridPaketTetkik.onRefresh();
  }
  public paketID: any;
  paketDetayModel: PaketDetayModel = new PaketDetayModel();
  constructor(private paketServices: PaketTanimlamaServices, private modalService : BsModalService) { }

  ngOnInit() {
  }

  paketTetkikFilter: TetkikPaketFilter = new TetkikPaketFilter();

  gridPaketTetkik: PaketTetkikListComponent_Models.GridPaketTetkik = new PaketTetkikListComponent_Models.GridPaketTetkik(this.paketServices, this.paketTetkikFilter);

  public onAddTetkik() {
    const initialState = {
      paketID: this.paketID,
    };
    if(this.paketID==null)
    return
    let modal = this.modalService.show(AddTetkiktopaketComponent, { initialState,class:"modal-xl" ,ignoreBackdropClick:true});
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          this.gridPaketTetkik.onRefresh();
        }
      }, err => {
        console.log(err);
      }
    );
  }

  public onRemoveTekik() {
    const initialState = {
      message: "Tetkiği paketten çıkartmak istediğinize emin misiniz",
    };
    let modal = this.modalService.show(ConfirmationDialogComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          let item = this.gridPaketTetkik.clickedItem;
          this.paketDetayModel.ID = item.Id;
          this.paketServices.DeletePaketTetkik(this.paketDetayModel).then(o => {
            this.gridPaketTetkik.onRefresh();
          })
        }
      }, err => {
        console.log(err);
      }
    );
  }

}
export class TetkikPaketFilter {
  Id: any;
  TetkikId: any;
  TetkikAdi: any;
  OzelUcret: any;
  PaketID: any;
}

namespace PaketTetkikListComponent_Models {

  export class GridPaketTetkik extends Grid.GridControl<any> {
    public direction: number = 1;
    constructor(private paketServices: PaketTanimlamaServices, private paketTetkikFilter: TetkikPaketFilter) {
      super();
    }
    filter = new Grid.GridInputModel(new PaketTetkikFilter());
    getFilter() {

      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;

      let item = this.filter.filter;
      var f = this.paketTetkikFilter;


      item.Id = f.Id;
      item.PaketID = f.PaketID;
      item.TetkikId = f.TetkikId;
      item.TetkikAdi = f.TetkikAdi;
      item.OzelUcret = f.OzelUcret;

      return this.filter;
    };
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    onRefresh() {
      var item = this.getFilter()
      console.log(item);
      var filter = item.filter;
      this.paketServices.GetPaketTetkikListById(item).then(o => {
        console.log(o);
        this.data = o;
      });
    }
  }

}
