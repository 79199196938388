import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { NotificationModel } from '../models/NotificationModel';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
@Injectable({
  providedIn: 'root'
})
export class NotificationServices {

  constructor(private api: ApiService) { }
//bildirim kaydet
  public SaveNotification(model: NotificationModel): Promise<any> {
    return this.api.callService("Notification/SaveNotification", model).then(o => {
      return o;
    });
  }
  public GetNotificationList(): Promise<Grid.GridOutputModel<NotificationModel>> {
    return this.api.callService("Notification/GetNotificationList", undefined).then(o => {
      var res = new Grid.GridOutputModel<NotificationModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"]; 
      return res;
    });
  };
  public GetNotificationCount(): Promise<any> {
    return this.api.callService("Notification/GetNotificationCount", undefined, true).then(o => {
      return o;
    });
  }
}
