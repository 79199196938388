import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Grid } from '../../../../../../shared/models/UIControls/grid-control';
import { PaketTanimlamaServices } from '../../../services/PaketTanimlamaService';
import { PaketViewFilter } from '../../../models/PaketViewFilter';
import { SavePaketComponent } from '../../../modals/save-paket/save-paket.component';
import { ConfirmationDialogComponent } from '../../../../../../shared/modals/confirmation-dialog/confirmation-dialog.component';
import { BsModalService, BsLocaleService } from 'ngx-bootstrap';
import { defineLocale, trLocale } from 'ngx-bootstrap/chronos';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
defineLocale('tr', trLocale);
    
@Component({
  selector: 'app-paketlist',
  templateUrl: './paketlist.component.html',
  styleUrls: ['./paketlist.component.css']
})
export class PaketlistComponent implements OnInit {
  @ViewChild('filterSection', {static: false}) input: ElementRef;
  constructor(private paketServices: PaketTanimlamaServices, private modalService: BsModalService, private localeService: BsLocaleService) { }

  ngOnInit() {
    this.localeService.use('tr');
    this.gridPaket.onRefresh();
  }
  paketFilter: PaketFilter = new PaketFilter();

  gridPaket: PaketListComponent_Models.GridPaket = new PaketListComponent_Models.GridPaket(this.paketServices, this.paketFilter);

  public onPaketSave() {
    const initialState = {
      paket: undefined,
    };
    let modal = this.modalService.show(SavePaketComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          this.gridPaket.onRefresh();
        }
      }, err => {
        console.log(err);
      }
    );
  }
  ngAfterViewInit() {
    
    let tableDom = this.input.nativeElement;
    let items = tableDom.querySelectorAll('.filterInput');
    
    for (var i = 0; i < items.length; i++) {
      let item = items[i];
      fromEvent(item, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(1000),
          distinctUntilChanged(),
        
            tap((text) => {
              if(item.value.length>2)
              this.gridPaket.onRefresh();
            else if(item.value.length==0)
            this.gridPaket.onRefresh();
          })
        )
        .subscribe();
    }
    
  }
  onFilterInputChange(event) {

    this.gridPaket.onRefresh();
  }
  public onPaketUpdate() {
    const initialState = {
      paket: this.gridPaket.clickedItem,
    };
    let modal = this.modalService.show(SavePaketComponent, { initialState,ignoreBackdropClick: true });
    modal.content.onClose.subscribe(
      response => {
        if (response !== null && response == "confirm") {
          this.gridPaket.onRefresh();
        }
      }, err => {
        console.log(err);
      }
    );
  }
  
}

export class PaketFilter {
  PaketId: any;
  PaketAdi: any;
  PaketUcreti: any;
  PaketAktif: any;
  PaketKaydeden: any;
  PaketKayitTarihi: any;
  PaketDegistiren: any;
  PaketDegTarihi: any;
  OsgbAdi: any;
  PaketFirmaID: any;
  PaketOsgbID: any;
}

namespace PaketListComponent_Models {

  export class GridPaket extends Grid.GridControl<any> {
    public direction: number = 1;
    constructor(private paketService: PaketTanimlamaServices, private paketFilter: PaketFilter) {
      super();
    }

    filter = new Grid.GridInputModel(new PaketViewFilter());
    getFilter() {

      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;

      let item = this.filter.filter;
      var o = this.paketFilter;

      item.OsgbAdi = o.OsgbAdi;
      item.PaketAdi = o.PaketAdi;
      item.PaketAktif = o.PaketAktif;
      item.PaketDegistiren = o.PaketDegistiren;
      item.PaketDegTarihi = o.PaketDegTarihi;
      item.PaketFirmaID = o.PaketFirmaID;
      item.PaketId = o.PaketId;
      item.PaketKaydeden = o.PaketKaydeden;
      item.PaketKayitTarihi = o.PaketKayitTarihi;
      item.PaketOsgbID = o.PaketOsgbID;
      item.PaketUcreti = o.PaketUcreti;

      return this.filter;
    };
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    onRefresh() {
      var item = this.getFilter()
      var filter = item.filter;

      this.paketService.GetPaketList(item).then(o => {
        this.data = o;
      })
    }
  }

}
