import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { FileService } from './file.service';
import { environment } from '../shared/consts/environment';
import { NotificationToastService } from '../shared/services/notification-toast.service';
import { Router } from '@angular/router';
import { cookieService } from '../shared/services/cookieService';
import { authenticationService } from '../shared/services/authenticationService';
import { CustomHttpParams } from './costumHttpParams';

export class HttpWebRequestModel {
  Data: any
  Exception: any
  ExtraData: any
  HasError: boolean
  Message: string
  Type: string
  TypeVal: number
}


@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private http: HttpClient, private toastr: ToastrService, private cookieService: cookieService, private routing: Router, private notificationToast: NotificationToastService, public fileOperation: FileService, public authentication: authenticationService) {
    
  }

  public solveResponseJSON(response: string): HttpWebRequestModel {
    var model = JSON.parse(response);
    let data = model as HttpWebRequestModel;

    if (data.TypeVal == 2) {
      this.authentication.redirectToLogin();
    }
    if (data.Exception) {
      var message = data.Exception.ClassName + ":" + data.Exception.Message;
      this.notificationToast.error(message);
    }
    return data;
  }

  public callPostService(serviceName: string, parameters: any, headeroptions: any = undefined) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        "Authorization": 'Bearer ' + this.authentication.token
      }),
    };
    if (headeroptions == undefined) {
      headeroptions = httpOptions;
    }
    return this.http.post(environment.serverAddress + serviceName, parameters, headeroptions).pipe(
      switchMap(res => {
        if (!this.authentication.isAuthenticated(res))
          this.authentication.redirectToLogin()
        //let data: any = res;
        //return of(data.Data);
        return of(res);
      }),
      catchError(err => {
        var erData = err.error
        if (erData) {
          console.log(erData);
          if (erData.TypeVal == 2) {
            this.authentication.redirectToLogin();
            return;
          }
          if (erData.Exception) {
            var message = erData.Exception.ClassName + ":" + erData.Exception.Message;
            this.toastr.error(message);
          }
          return of(undefined);
        }
        else {
          console.log(err);
        }
      })
    );
  }


  public callFormDataService(serviceName: string, files: any[]) {
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let mbSize = 4 * 1024 * 1024
      if (file.length >= mbSize) {
        this.toastr.error("Dosya 4 Mb den büyük olamaz");
        return;
      }
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': "multipart/form-data",
        "Authorization": 'Bearer ' + this.authentication.token
      })
    };

    //let headers = new HttpHeaders();
    ////this is the important step. You need to set content type as null
    //headers.set('Content-Type', null);
    //headers.set('Accept', "multipart/form-data");

    const formData: FormData = new FormData();
    files.forEach(file => {
      formData.append('fileArray', file, file.name);
    })

    return this.http.post(environment.serverAddress + serviceName, formData, httpOptions).pipe(
      switchMap(res => {
        this.toastr.success('Success');
        return of(res);
      }),
      catchError(err => {
        let message = "error";
        if ('error' in err) {
          message += " : " + err.error.error_description;
        }

        this.toastr.error(message);
        return of(undefined);
      })
    );


  }
  public callService(serviceName: string, parameters: any,ignoreLoadingSpinner :boolean = false) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        "Authorization": 'Bearer ' + this.authentication.token
      }),
      params: new CustomHttpParams(ignoreLoadingSpinner)
    };
  
    return new Promise((resolve, reject) => {
      var res = this.http.post(environment.serverAddress + serviceName, parameters, httpOptions).subscribe(o => {
        if (!this.authentication.isAuthenticated(o)) {
          this.toastr.error("Tekrar Giriş Yapınız");
         // this.authentication.redirectToLogin();
          reject();
          return;
        }
        if(!ignoreLoadingSpinner){
          this.toastr.success('Success');
        }
        
        resolve(o["Data"]);
      }, o => {
        var erData = o.error
        if (erData) {
          console.log(erData);
          if (erData.TypeVal == 2) {
            if(!ignoreLoadingSpinner){
              this.toastr.error("Tekrar Giriş Yapınız");
            }
            //this.authentication.redirectToLogin();
            reject();
            return;
          }
          if (erData.Exception) {
            var message = erData.Exception.ClassName + ":" + erData.Exception.Message;
            if(!ignoreLoadingSpinner){
              this.toastr.error(message);
            }
            

          }
          reject();
          return;
        }
        else {
          console.log(o);
        }
      })
    });

  }
  public callPostServiceByUrl(serviceName: string, parameters: any, headeroptions: any = undefined) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    };

    if (headeroptions == undefined) {
      headeroptions = httpOptions;
    }

    var t = this.http.post(environment.serverAddress + serviceName, parameters, headeroptions).toPromise().then(o => {
      this.toastr.success('Success');
      return o;
    }, err => {
      let message = "error";
      if ('error' in err) {
        message += " : " + err.error.error_description;
      }
      this.toastr.error(message);
      throw message;
      return;
    });

    return t;

  }

  public callGetService(serviceName: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept': 'application/json',
        "Authorization": 'Bearer ' + this.authentication.token
      })

    };

    return this.http.get(environment.serverAddress + serviceName, httpOptions).pipe(
      switchMap(res => {
        this.toastr.success('Success');
        return of(res);
      }),
      catchError(err => {
        let message = "error";
        if ('error' in err) {
          message += " : " + err.error.error_description;
        }
        this.toastr.error(message);
        return of(undefined);
      })
    );
  }
}
