export class TetkikSaveModel {
  Id: any;
  TetkikGrubu: any;
  TetkikAdi: any;
  MerkezCalistir: any;
  OsgbCalistir: any;
  LabTesti: any;
  DisLab: any;
  Ucret1: any;
  Ucret2: any;
  Ucret3: any;
  DizaynAdi: any;
  Aktif: any;
  BasTarih: any;
  BitTarih: any;
  TetkikGrupID: any;
  HbysBarkod: any;
}
