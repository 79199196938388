import { IDropdownSettings } from 'ng-multiselect-dropdown';

export class ddlSettings {
  ddlTetkikModalSettings: IDropdownSettings = {};
  ddlModalitySettings: IDropdownSettings = {};
  ddlEnumSettings: IDropdownSettings = {};
  ddlPublicSettings: IDropdownSettings = {};
  ddlOSGBListSettings:IDropdownSettings = {};
  ddlDizaynSettings:IDropdownSettings = {};
  ddlFirmaListSettings:IDropdownSettings = {};
  ddlOsgbListSettings2:IDropdownSettings = {};
  ddlFirmaListSettings2:IDropdownSettings = {};
  ddlTetkikGrubuSettings:IDropdownSettings = {};
  ddlUserListSettings:IDropdownSettings = {};
  ddlUserSettings:IDropdownSettings = {};
  constructor() {
    this.ddlTetkikModalSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'tetkik',
      selectAllText: 'Tümünü Seç',
      unSelectAllText: 'Tümünü Kaldır',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
    this.ddlModalitySettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Tümünü Seç',
      unSelectAllText: 'Tümünü Kaldır',
      itemsShowLimit: 0,
      //allowSearchFilter: true
    };
    this.ddlEnumSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Tümünü Seç',
      unSelectAllText: 'Tümünü Kaldır',
      itemsShowLimit: 0,
      //allowSearchFilter: true
    };
    this.ddlPublicSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'text',
      itemsShowLimit: 1,
    };
    this.ddlUserListSettings = {
      idField: 'Id',
      textField: 'KullaniciAdi',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
    this.ddlDizaynSettings = {
      singleSelection: true,
      idField: 'ID',
      textField: 'Name',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      closeDropDownOnSelection:true
    };
    this.ddlUserSettings = {
      singleSelection:false,
      idField: 'ID',
      textField: 'Name',
      itemsShowLimit: 3,
      selectAllText: 'Tümünü Seç',
      unSelectAllText: 'Tümünü Kaldır',
      allowSearchFilter: true
    };
    this.ddlOSGBListSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'OSGBAdi',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
    this.ddlFirmaListSettings = {
      singleSelection: true,
      idField: 'FirmaId',
      textField: 'FirmaAdi',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.ddlFirmaListSettings2 = {
      singleSelection: true,
      idField: 'ID',
      textField: 'Name',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.ddlOsgbListSettings2 = {
      singleSelection: true,
      idField: 'Id',
      textField: 'OSGBAdi',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
    this.ddlTetkikGrubuSettings = {
      singleSelection: true,
      idField: 'ID',
      textField: 'TetkikGrubuAdi',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }
}
