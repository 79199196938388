import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
import { FTPDosyaFilter } from '../models/FTPDosyaFilter';
import { FTPDosyaViewModel } from '../models/FTPDosyaViewModel';
import { DownloadModel } from '../models/DownloadModel';
import { UploadModel } from '../models/UploadModel';
import { FtpIslemModel } from '../models/FtpIslemModel';
import { FtpIslemNotModel } from '../models/FtpIslemNotModel';

@Injectable({
  providedIn: 'root'
})
export class FtpServices {

  constructor(private api: ApiService) { }

  public GetFtpDosyaList(model: Grid.GridInputModel<FTPDosyaFilter>): Promise<Grid.GridOutputModel<FTPDosyaViewModel>> {


    return this.api.callService("FTP/GetFtpDosyaList", model).then(o => {
      var res = new Grid.GridOutputModel<FTPDosyaViewModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
    });
  };
  public DownloadDosya(model: DownloadModel): Promise<any> {
    return this.api.callService("FTP/DownloadDosya", model).then(o => {
      return o;
    });
  };
  public UploadDosya(model: UploadModel): Promise<any> {
    return this.api.callService("FTP/UploadDosya", model).then(o => {
      return o;
    });
  };
  public FTPDosyaIslemler(model: FtpIslemModel): Promise<any> {
    return this.api.callService("FTP/FTPDosyaIslemler", model).then(o => {
      return o;
    });
  };
  public NotEkle(model: FtpIslemNotModel): Promise<any> {
    return this.api.callService("FTP/NotEkle", model).then(o => {
      return o;
    });
  };

  public SendPacs(ids: number[]): Promise<any> {
    return this.api.callService("FTP/SentDicom", { FtpIdList: ids }).then(o => {
      return o;
    });
  }
}
