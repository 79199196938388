import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { ModalManagerService } from 'src/app/Services/modal-manager.service';

@Component({
  selector: 'app-kimlik-confirmation',
  templateUrl: './kimlik-confirmation.component.html',
  styleUrls: ['./kimlik-confirmation.component.css']
})
export class KimlikConfirmationComponent implements OnInit {

  public personelID : any ;
  public adiSoyadi : any;
  public sicilno : any;
  public cinsyet : any;
  public dogumtarihi : any;
  public OsgbId : any;
  public FirmaId : any ;


  constructor(public bsModalRef: BsModalRef,private apiservice : ApiService,private modalService: BsModalService,private modalmanager : ModalManagerService) { }

  ngOnInit() {
  }

  public onConfirm(){

    let initialState = {
      OsgbId : 1 ,
      FirmaId : 2 ,
      personelID : this.personelID
    };
    this.modalmanager.setModalData(initialState);
    this.modalService.setDismissReason('kimlik-checked');
    this.bsModalRef.hide();
  } 

}
