import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
@Injectable({
  providedIn: 'root'
})
export class LaboratuvarServices {

  constructor(private api: ApiService) { }

  public GetKeyList(): Promise<any> {
    return this.api.callService("Laboratuvar/GetKeyList", undefined).then(o => {
      return o;
    });
  }
  public GetKeyList2(): Promise<any> {
    return this.api.callService("Laboratuvar/GetKeyList2", undefined).then(o => {
      return o;
    });
  }
}
