import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { NotificationServices } from 'src/app/features/private/Settings/services/NotificationServices';
import { ApiService } from '../../../Services/api.service';
import { interval, Subscription } from 'rxjs';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() toggle = new EventEmitter();
  public collapseSidebar:boolean=true;
  ui:any={
    userName:"",
    roles:[],
    notification:[]
    
  };
  public notificationCount:number;
  public data:any={
    getUser:()=>{
      return this.apiservice.callGetService("Test/GetUser")
    }
  }
  timer: Subscription
  constructor(private apiservice : ApiService,private notificationServices:NotificationServices) { 

    var r=this.data.getUser().subscribe(res=>{
      if(!res)
      return;
      this.ui.userName=res.Data.UserName;
    });
    
    this.timer= interval(30000).subscribe((x =>{
       this.getNotificationCount();
  }));
  }

  ngOnInit() {
     this.getNotificationCount();
  }
  public onLoggedOut() {
    this.apiservice.authentication.logoutAndRedirect();
    
  }
  getNotificationList(){
   this.notificationServices.GetNotificationList().then(o=>{
      this.ui.notification=o.list;
      console.log(o)
      this.getNotificationCount();
    })
    
  }
  getNotificationCount(){
    this.notificationServices.GetNotificationCount().then(o=>{
      this.notificationCount=o;
      // console.warn("Notification Service work's without LoadingSpinner")
    })

    
  }
  onToggle(){

    this.collapseSidebar=!this.collapseSidebar;
    this.toggle.emit(this.collapseSidebar);

  }

}
