import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IDViewModel } from 'src/app/features/private/definitions/models/IDViewModel';
import { ddlSettings } from '../../../modals/personeltetkikmodal/ddlSettings';
import { PersonelViewFilter } from '../../../models/PersonelViewFilter';
import { FirmaServices } from '../../../services/FirmaService';
import { OsgbFirmaServices } from '../../../services/OsgbFirmaService';
import { PersonelTetkikIslemServices } from '../../../services/PersonelTetkikIslemService';

@Component({
  selector: 'transfer-sevk-filter',
  templateUrl: './transfer-sevk-filter.component.html',
  styleUrls: ['./transfer-sevk-filter.component.css']
})
export class TransferSevkFilterComponent implements OnInit {

  @Output() filterChanged: EventEmitter<any> = new EventEmitter();
  public ddlSettings: ddlSettings = new ddlSettings();

  public ddlOSGBList: any[] = [];
  public ddlOSGBSettings;
  public ddlOSGBSelectedItems = [];

  public ddlFirmaList: any[] = [];
  public ddlFirmaSettings;
  public ddlFirmaSelectedItems = [];

  public ddlDurumList: any[] = [];
  public ddlDurumSettings;
  public ddlDurumSelectedItems = [];

  public ddlTetkikList: any[] = [];
  public ddlTetkikSettings;
  public ddlTetkikSelectedItems = [];

  public filterDateRange = {
    start: new Date(new Date().setHours(0,0,0,0)),
    end: new Date(new Date().setHours(23,59,59,59))
  }
  constructor(private osgbFirmaServices: OsgbFirmaServices,
    private dataRapor: PersonelTetkikIslemServices,
    private firmaService: FirmaServices
  ) { }

  ngOnInit() {

    this.ddlOSGBSettings = this.ddlSettings.ddlOsgbListSettings2;
    this.ddlFirmaSettings = this.ddlSettings.ddlFirmaListSettings2;
    this.ddlDurumSettings = this.ddlSettings.ddlDizaynSettings;
    this.ddlTetkikSettings = this.ddlSettings.ddlTetkikGrubuSettings;
    this.ddlDurumList = [
      { ID: 1, Name: "Sevk Edilenler" },
      { ID: 2, Name: "Transfer Edilenler" },
      { ID: 3, Name: "Transfer Edilmiş Ancak Onaylanmamış" },
      // { ID: 4, Name: "Sevk Edilmiş Ancak Onaylanmamış" } //v2 de canlıya alınacak
    ];


    this.getOSGBListOptions();
    this.getTetkikGrup();
    this.ddlDurumSelectedItems.push( { ID: 1, Name: "Sevk Edilenler" })
    this.onFilter();
  }
  onOsgbSelect(value) {
    this.getFirmaListOptions(value.Id);
  }
  getFirmaListOptions(id) {
    this.ddlFirmaList = [];
    let idViewModel = new IDViewModel();
    idViewModel.ID = id;
    this.firmaService.GetKeyList(idViewModel).then((resp) => {
      this.ddlFirmaList = resp;
    })
  }
  getOSGBListOptions() {
    this.osgbFirmaServices.GetOsgbList().then(o => {
      this.ddlOSGBList = [];
      this.ddlOSGBList = o;
      console.log(this.ddlOSGBList);
    })
  }
  getTetkikGrup() {
    this.dataRapor.GetTetkikGrubuList().then(resp => {
      this.ddlTetkikList = resp;
    })
  }
  onFilter() {
    debugger;
    let filter = new PersonelViewFilter();
    filter.BitTarih =new Date(this.filterDateRange.end);
    filter.BasTarih = new Date(this.filterDateRange.start);
    filter.OsgbId = this.ddlOSGBSelectedItems.length > 0 ? this.ddlOSGBSelectedItems[0].Id: undefined;
    filter.FirmaId = this.ddlFirmaSelectedItems.length > 0 ? this.ddlFirmaSelectedItems[0].ID: undefined;
    filter.TetkikDurum = this.ddlDurumSelectedItems.length > 0 ? this.ddlDurumSelectedItems[0].ID: undefined;
    filter.TetkikGrubuList = this.ddlTetkikSelectedItems.length > 0 ? this.ddlTetkikSelectedItems[0].ID: undefined;
    this.filterChanged.emit(filter);
  }
}
