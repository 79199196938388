import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { DataManagerService } from 'src/app/Services/dataManager/data-manager.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { switchMap, map } from 'rxjs/operators';
import { PersonelTetkikIslemServices } from '../../../services/PersonelTetkikIslemService';

@Component({
  selector: 'app-save-result-excel',
  templateUrl: './save-result-excel.component.html',
  styleUrls: ['./save-result-excel.component.css']
})
export class SaveResultExcelComponent implements OnInit {
  public selectedFiles: any[] = [];
  public disableUpload : boolean = true;
  public saveExcelFile: string = "FileOperation/Upload";
  public getExcelDataUrl: string = "Personel/GetExcelData";
  public showAlert: boolean = false;
  public selectedFile: any = {
    name: "Dosya seçin"
  };
  public personelList: any[] = [];
  public ui: any = {
    columns: []
  };
  public model: any = {
    firmaID: undefined,
    doktor: undefined,
    odemeSekli: undefined,
    sheet: 1,
    tetkikTuru: undefined,
    dict: {
      adSoyadColumnNumber: undefined,
      TCColumnNumber: undefined,
      personelSicilNoNumber: undefined,
      dogumTarihiNumber: undefined,
      cinsiyetNumber: undefined,
    },
    ExcelFilePath: undefined
  };
  constructor(public bsModalRef: BsModalRef, private apiservice: ApiService, private datamanager: DataManagerService, private modalService: BsModalService, private personeltetkikIslemService: PersonelTetkikIslemServices ) { }

  ngOnInit() {
  }

  public handleFileChoose(event) {
    this.selectedFiles = [];
      // for (let [key, value] of Object.entries(event.target.files)) {
      //     // console.log(key, value);
      //     this.selectedFiles.push(value);
      // }
      this.selectedFile = event.target.files[0];
    this.disableUpload = false;
  }

  onUploadFile() {
    this.apiservice.callFormDataService(this.saveExcelFile, [this.selectedFile]).pipe(
      switchMap(
        (resp: any) => {

          this.model.ExcelFilePath = resp.Data[0];
          return this.apiservice.callPostService(this.getExcelDataUrl, { excelFileID: this.model.ExcelFilePath })
        }
      ),
      map(
        (resp: any) => {
          // console.log(resp);
          this.ui.columns = [];
          this.personelList = resp.Data;
          this.ui.columns = this.getExcelColumns(this.personelList);
        }
      )
    ).subscribe();

  }
  getExcelColumns = (excelData) => {
    debugger;
    let arr = []
    if (!excelData || !excelData[0]) {
      return arr;
    }
    let item = excelData[0];
    excelData.splice(0, 1);

    for (var k in item) {
      var colName = item[k];
      if (!colName) continue;
      arr.push(colName);
    }
    return arr;
  }
  public saveTetkik() {
   debugger;
    // if (!this.model.tetkikTuru) {
    //   this.showAlert = true;
    // } else {
    //   // console.log(this.model);
    //   this.showAlert = false;
      this.personeltetkikIslemService.Import(this.model).then(
      // this.apiservice.callPostService(this.saveAudioExcel, this.model).subscribe(
        (resp) => {
          
          this.bsModalRef.hide();
        }
      );
    }
  }
// }
