export class LabtetkikViewModel {
  Id: any;
  TetkikId: any;
  AltTetkikKodu: any;
  AltTetkikAdi: any;
  AsagiSonuc: any;
  AralikSonuc: any;
  YukariSonuc: any;
  DegerMin: any;
  DegerMax: any;
  DegerDizi: any[];
  Referans: any;
  Birimi: any;
  Aciklama: any;
  Aktif: any;
  Kaydeden: any;
  KayitTarihi: any;
  Degistiren: any;
  DegTarihi: any;
  DegerDiziTemp: any;
}
