import { Injectable } from '@angular/core';
import { ApiService } from '../../../../Services/api.service';
import { Grid } from '../../../../shared/models/UIControls/grid-control';
import { OsgbViewFilter } from '../models/OsgbViewFilter';
import { OsgbViewModel } from '../models/OsgbViewModel';
import { OsgbDetayModel } from '../models/OsgbDetayModel';
import { OsgbFirmaModel } from '../models/OsgbFirmaModel';
import { OSGBUIModel } from '../models/OSGBUIModel';
import { NameViewModel } from '../../transactions/models/NameViewModel';
import { IDViewModel } from '../models/IDViewModel';

@Injectable({
  providedIn: 'root'
})
export class OsgbServices {

  constructor(private api: ApiService) { }
  
  public GetOsgbList(model: Grid.GridInputModel<OsgbViewFilter>): Promise<Grid.GridOutputModel<OsgbViewModel>> {
    return this.api.callService("Osgb/GetOsgbList", model).then(o => {
      var res = new Grid.GridOutputModel<OsgbViewModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
    });
  }
  public GetByOsgbID(model: OsgbDetayModel): Promise<any> {
    return this.api.callService("Osgb/GetByOsgbID", model).then(o => {
      return o;
    });
  }
  public Save(model: OSGBUIModel): Promise<any> {
    console.log(model);
    return this.api.callService("Osgb/Save", model).then(o => {
      return o;
    });
  }
  public Delete(model: OsgbDetayModel): Promise<any> {
    return this.api.callService("Osgb/Delete", model).then(o => {
      return o;
    });
  }
  public GetFirmaListByOsgbID(model: Grid.GridInputModel<OsgbFirmaModel>): Promise<Grid.GridOutputModel<OsgbFirmaModel>> {
    return this.api.callService("Osgb/GetFirmaListByOsgbID", model).then(o => {
      var res = new Grid.GridOutputModel<OsgbFirmaModel>();
      res.list = o["List"];
      res.totalCount = o["TotalCount"];
      return res;
    });
  }
  public GetKeyList(model: IDViewModel): Promise<NameViewModel[]> {
    return this.api.callService("Osgb/GetKeyList", model).then(o => {
      return o as NameViewModel[];
    });
  }
}
