import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UserUIModel } from '../../models/UserUIModel';
import { KullaniciTanımlamaService } from '../../services/KullaniciTanımlamaService';
import { BsModalRef } from 'ngx-bootstrap';
import { error } from '@angular/compiler/src/util';
import { UserDetayModel } from '../../models/UserDetayModel';
import { PaketTanimlamaServices } from '../../services/PaketTanimlamaService';
import { RoleModel } from '../../models/RoleModel';
import { RoleAddModel } from '../../models/RoleAddModel';

@Component({
  selector: 'app-save-kullanici',
  templateUrl: './save-kullanici.component.html',
  styleUrls: ['./save-kullanici.component.css']
})
export class SaveKullaniciComponent implements OnInit {
  public onClose: Subject<string>;
  public kullaniciId: any = undefined;
  modalTitle: any;
  idVisible: boolean;
  userModel: UserUIModel = new UserUIModel();
  userDetayModel: UserDetayModel = new UserDetayModel();
  ddlOsgb: any[];
  ddlYetki: RoleModel[] = [];
  selectedUser:any;
  constructor(private paketServices: PaketTanimlamaServices, public bsModalRef: BsModalRef, private userService : KullaniciTanımlamaService) { }

  ngOnInit() {
    this.onClose = new Subject();
    // this.getOsgb();
    this.getRoleList();
    if (this.kullaniciId == undefined) {
      this.modalTitle = "Kullanıcı Ekleme";
      this.idVisible = false;
    }
    else {
      this.modalTitle = "Kullanıcı Güncelleme";
      this.userDetayModel.ID = this.kullaniciId;
      this.userService.GetById(this.userDetayModel).then(o => {
        this.userModel.ID = o.Id ;
        this.userModel.AdSoyad = o.AdiSoyadi ;
        this.userModel.Email = o.MailAdresi ;
        this.userModel.KullaniciAdi = o.KullaniciAdi;
        this.userModel.Sifre = o.Sifresi;
        this.userModel.OsgbId = o.Osgbid ;
        this.userModel.OsgbAdi = o.OsgbAdi;
        this.userModel.RoleID=o.RoleID;
        this.userModel.LabID=o.LabID;
        this.userModel.firmaID=o.FirmaID;
        this.userModel.PersonelID=o.PersonelID;
        if(o.PersonelID != null){
          this.selectedUser=o.PersonelID;
        }
        else if(o.FirmaID != null){
          this.selectedUser=o.FirmaAdi;
        }
        else if(o.Osgbid != null){
          this.selectedUser=o.OsgbAdi;
        }
        else if(o.LabID != null){
          this.selectedUser=o.LabAdi;
        }
      });
      this.idVisible = true;
    }
  }
  getRoleList() {
    this.userService.GetRoleList().then(o => {
      this.ddlYetki = o;
    });
  }

  // getOsgb() {
  //   this.paketServices.getOsgb().then(o => {
  //     this.ddlOsgb = o;
  //     this.ddlOsgb.sort((a, b) => a.OSGBAdi < b.OSGBAdi ? -1 : a.OSGBAdi > b.OSGBAdi ? 1 : 0)
  //   });
  // }
  // getOsgbChange(value) {
  //   let itemID = value.substr(0, value.indexOf(':'));
  //   this.userModel.OsgbId = this.ddlOsgb[itemID].Id;
  //   this.userModel.OsgbAdi = this.ddlOsgb[itemID].OSGBAdi;
  // }
  onConfirm(st: any) {
    console.log(this.userModel)
    this.userService.Save(this.userModel).then(o => {
      this.onClose.next("confirm");
      this.bsModalRef.hide();
    }).catch(error => {
      this.onClose.next("cancel");
      this.bsModalRef.hide();
    });
  }
  onCancel(st: any) {
    this.onClose.next("cancel");
    this.bsModalRef.hide();
  }
etkiAlaniModel(value){
       this.userModel.OsgbId=value.OsgbId;
       this.userModel.firmaID=value.firmaID;
       this.userModel.LabID=value.LabID;
       this.userModel.PersonelID=value.PersonelID;
       console.log(this.userModel)
       debugger;
}
}
