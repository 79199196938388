import { Component, Input, OnInit } from '@angular/core';
import { LaboratuvarServices } from 'src/app/features/private/Settings/services/LaboratuvarServices';
import { Grid } from 'src/app/shared/models/UIControls/grid-control';
import { PersonelViewFilter } from '../../../models/PersonelViewFilter';
import { PersonelTetkikServices } from '../../../services/PersonelTetkikService';

@Component({
  selector: 'transfer-sevk-grid',
  templateUrl: './transfer-sevk-grid.component.html',
  styleUrls: ['./transfer-sevk-grid.component.css']
})
export class TransferSevkGridComponent implements OnInit {
  @Input() set filter(value: PersonelViewFilter) {
    if (value == undefined)
      return
    this.tetkikFilter.TetkikGrubuList = value.TetkikGrubuList;
    this.tetkikFilter.OsgbId = value.OsgbId;
    this.tetkikFilter.BaslangicTarihi = value.BasTarih;
    this.tetkikFilter.BitisTarihi = value.BitTarih;
    this.tetkikFilter.TetkikDurum = value.TetkikDurum;
    this.tetkikFilter.FirmaId = value.FirmaId;
    this.gridTetkik.onRefresh();
  }
  public labList: any[] = [];
  constructor(private tetkikService: PersonelTetkikServices, private labService: LaboratuvarServices) { }
  ngOnInit() {
    this.getTransferSevkButton();
  }
  getTransferSevkButton() {
    this.labService.GetKeyList2().then((resp) => {
      this.labList = resp;
    });
  }
  tetkikFilter: TetkikFilter = new TetkikFilter();
  gridTetkik: TetkikListComponent_Models.GridTetkik = new TetkikListComponent_Models.GridTetkik(this.tetkikService, this.tetkikFilter);
}
export class TetkikFilter {
  OsgbId: any;
  TetkikGrubuList: any;
  BaslangicTarihi: any;
  BitisTarihi: any;
  TetkikDurum: any;
  FirmaId: any;
}
namespace TetkikListComponent_Models {
  export class GridTetkik extends Grid.GridControl<any> {
    public direction: number = 1;
    constructor(private tetkikService: PersonelTetkikServices, public tetkikFilter: TetkikFilter) {
      super();
    }
    filter = new Grid.GridInputModel(new PersonelViewFilter());
    getFilter() {
      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;

      let item = this.filter.filter;
      var o = this.tetkikFilter; 

      item.TetkikGrubuList = o.TetkikGrubuList;
      item.OsgbId = o.OsgbId;
      item.BasTarih = o.BaslangicTarihi;
      item.BitTarih = o.BitisTarihi;
      item.TetkikDurum = o.TetkikDurum;
      item.FirmaId = o.FirmaId;
      
      return this.filter;
    };
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    onSevk(id) {
      this.filter.filter.LabID = id;
      this.filter.filter.IslemIDList=this.selectedItems.map(o=>o.IslemID);
      this.tetkikService.SaveSevk(this.filter).then((resp) => {
        console.log(resp);
      })
      this.onRefresh();
    }
    onTransfer(id) {
      this.filter.filter.LabID = id
    this.filter.filter.IslemIDList=this.selectedItems.map(o=>o.IslemID);
      this.tetkikService.SaveTransfer(this.filter).then((resp) => {
        console.log(resp);
      })
      this.onRefresh();
    }
    onTransferOnay(){
      this.filter.filter.IslemIDList=this.selectedItems.map(o=>o.IslemID);
    this.tetkikService.TransferOnay(this.filter).then(o=>{
      console.log(o)
    })
    this.onRefresh();
    }
    onSevkOnay(){
      this.filter.filter.IslemIDList=this.selectedItems.map(o=>o.IslemID);
    this.tetkikService.SevkOnay(this.filter).then(o=>{
      console.log(o)
    })
    this.onRefresh();
    }
    onRefresh() {
      var item = this.getFilter();
      this.tetkikService.GetPersonelTetkikModalList(item).then(o => {
        this.data = o;
        this.selectedItems.length=0;
      })
    }
  }

}
