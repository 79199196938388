import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiService } from 'src/app/Services/api.service';
import { HbysBarkodModel } from '../../models/HbysBarkodModel';
import { PersonelTetkikServices } from '../../services/PersonelTetkikService';

@Component({
  selector: 'app-hbys-barkod-modal',
  templateUrl: './hbys-barkod-modal.component.html',
  styleUrls: ['./hbys-barkod-modal.component.css']
})
export class HbysBarkodModalComponent implements OnInit {
  hbysBarkodModel:HbysBarkodModel=new HbysBarkodModel();
  islemIdList
  public hbysBarkod:any;
  constructor(public bsModalRef: BsModalRef,private apiservice : ApiService,private modalService: BsModalService,private personelTetkikServices:PersonelTetkikServices) { }

  ngOnInit() {
    this.hbysBarkodModel.IslemID=this.islemIdList[0]
    this.personelTetkikServices.GetHbysBarkodByIslemID(this.hbysBarkodModel).then(o=>{
     
      this.hbysBarkod=o;
      debugger;
    })
  }
onSave(){
  this.hbysBarkodModel.HbysBarkod=this.hbysBarkod;
 
  this.hbysBarkodModel.IslemID=this.islemIdList[0]
  this.personelTetkikServices.SaveHbysBarkod(this.hbysBarkodModel).then(o=>{
    this.bsModalRef.hide();
  })
}
}
