import { Component, OnInit } from '@angular/core';
import { Grid } from '../../../../../shared/models/UIControls/grid-control';
import { CariRaporServices } from '../../services/CariRaporService';
import { CariRaporViewFilter } from '../../models/CariRaporViewFilter';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
moment.locale('tr');
@Component({
  selector: 'app-carirapor',
  templateUrl: './carirapor.component.html',
  styleUrls: ['./carirapor.component.css']
})
export class CariraporComponent implements OnInit {

  public multiSelectData = [];
  public multiSelectedItems = [];
  public multiSelectSettings: IDropdownSettings = {};

  constructor(private cariService: CariRaporServices) { }

  ngOnInit() {
    this.multiSelectSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      selectAllText: 'Tümünü Seç',
      unSelectAllText: 'Tümünü Kaldır',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };
    this.multiSelectData = [
      { id: 1, text: 'İşlem ücretleri görünsün'},
      { id: 2, text: 'Tahsilatlar görünsün'},
      { id: 3, text: 'Harcama ve iadeler görünsün'},
      { id: 4, text: 'Tarihe göre gruplansın' },
      { id: 5, text: 'Açıklamaya göre gruplansın'},
      { id: 6, text: 'Firmaya göre gruplansın'},
      { id: 7, text: 'Personele göre gruplansın'},
      { id: 8, text: 'Tetkiğe göre gruplansın'}
    ];
  }
  cariFilter: CariFilter = new CariFilter();
  gridCari: CariComponent_Models.GridCari = new CariComponent_Models.GridCari(this.cariService, this.cariFilter);

  onSearch() {
    this.cariFilter.ddlData = this.multiSelectedItems;
    this.gridCari.onRefresh();
  }
  onExportExel() {
    this.gridCari.onExport_Excel();
  }
}
export class CariFilter {
  OsgbAdi: any;
  FirmaAdi: any;
  PersonelAdi: any;
  TetkikAdi: any;
  tarihRange: { start: moment.Moment, end: moment.Moment };
  cmbGrup: any;
  ddlData: any[];
  constructor() {
    this.cmbGrup = { data: [] };
    var dt = this.cmbGrup.data;
    for (var i = 0; i < 10; i++) {
      dt.push(false);
    }
  }
}
namespace CariComponent_Models {

  export class GridCari extends Grid.GridControl<any> {

    public direction: number = 0;

    constructor(private cariService: CariRaporServices, private cariFilter: CariFilter) {
      super();
    }

    filter = new Grid.GridInputModel(new CariRaporViewFilter());
    getFilter() {
      this.filter.paging.pageNumber = this.model.paging.pageNumber;
      this.filter.paging.count = this.model.paging.count;
      this.filter.sorting = this.model.sorting;

      let item = this.filter.filter;
      var o = this.cariFilter;

      this.filter.filter.OsgbAdi = o.OsgbAdi;
      this.filter.filter.FirmaAdi = o.FirmaAdi;
      this.filter.filter.TetkikAdi = o.TetkikAdi;
      this.filter.filter.PersonelAdi = o.PersonelAdi;
      this.filter.filter.BaslangicTarihi = o.tarihRange.start;
      this.filter.filter.BitisTarihi = o.tarihRange.end;

      this.cariFilter.ddlData.forEach(item => {
        if (item.id == 1) {
          this.filter.filter.IslemUcretleriGorunsun = true;
        }
        if (item.id == 2) {
          this.filter.filter.TahsilatlarGorunsun = true;
        }
        if (item.id == 3) {
          this.filter.filter.HarcamalarveIadelerGorunsun = true;
        }
        if (item.id == 4) {
          this.filter.filter.TariheGore = true;
        }
        if (item.id == 5) {
          this.filter.filter.AciklamayaGore = true;
        }
        if (item.id == 6) {
          this.filter.filter.FirmayaGore = true;
        }
        if (item.id == 7) {
          this.filter.filter.PersoneleGore = true
        }
        if (item.id == 8) {
          this.filter.filter.TetkikAdinaGore = true
        }
      })
      return this.filter;
    };
    onSorting(colName) {
      if (this.direction == 0) {
        this.direction = 1;
      } else {
        this.direction = 0;
      }
      this.model.sorting.colName = colName;
      this.model.sorting.direction = this.direction;
      this.onRefresh();
    }
    onRefresh() {
      var item = this.getFilter()
      var filter = item.filter;
      console.log(item);
      this.cariService.GetCariRaporList(item).then(o => {
        this.data = o;
      })
    }
    onExport_Excel() {
      let item = this.getFilter();
      this.cariService.ExportExcelCariIslemList(item).then(o => {
      })
    };
  }

}
