import { Component, OnInit } from '@angular/core';
import { IDViewModel } from '../../../definitions/models/IDViewModel';
import { ddlSettings } from '../../../transactions/modals/personeltetkikmodal/ddlSettings';
import { NotificationModel } from '../../models/NotificationModel';
import { NotificationServices } from '../../services/NotificationServices';
import { UserRolServices } from '../../services/UserRolServices';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  notificationModel:NotificationModel=new NotificationModel();
  idViewModel:IDViewModel=new IDViewModel();
  public ddlSettings: ddlSettings = new ddlSettings();
  public ddlRolListSettings;
  public ddlRolListData = [];
  public ddlRolListSelectedItems = [];
  public ddlUserListSettings;
  public ddlUserListData = [];
  public ddlUserListSelectedItems = [];
  constructor(private notificationServices:NotificationServices,private userRolServices:UserRolServices) { }
  public content;


  ngOnInit() {
    this.ddlRolListSettings=this.ddlSettings.ddlDizaynSettings;
    this.ddlUserListSettings=this.ddlSettings.ddlUserSettings;

    this.userRolServices.GetRolList().then(o=>{
      this.ddlRolListData=o;
      console.log(o)
    })
  }
  changeRolID(){
 
    this.ddlUserListSelectedItems=[];
    this.idViewModel.ID=this.ddlRolListSelectedItems[0].ID
    this.userRolServices.GetUserByRolID(this.idViewModel).then(o=>{
   this.ddlUserListData=o;
      console.log(o)
    })
  }
  onSave(){
    this.notificationModel.KullaniciIDList=this.ddlUserListSelectedItems.map(o=>o.ID)
    this.notificationModel.Content=this.content;
    console.log(this.notificationModel)
    this.notificationServices.SaveNotification(this.notificationModel).then(o=>{
      this.content="" 
    })
   
  }
}
